<template>
  <div class="input-group">
    <editor
      api-key="no-api-key"
      :props="fieldOptions"
      v-model="value"
      :init="{
        height: 500,
        width: '100%',
        menubar: false,
        color_map: [
          '#47776e',
          'Primary Green',
          '#656278',
          'Brown',
          '#F9EEE5',
          'Beige',
          '#ECCAFA',
          'Light Purple',
          '#C2E0F4',
          'Light Blue',

          '#2DC26B',
          'Green',
          '#F1C40F',
          'Yellow',
          '#E03E2D',
          'Red',
          '#B96AD9',
          'Purple',
          '#3598DB',
          'Blue',
          '#169179',

          '#000000',
          'Black',
          '#ffffff',
          'White',
        ],

        plugins: [
          'code',
          'textcolor colorpicker',
          'advlist autolink lists link image charmap preview anchor',
          'media table',
          'image',
        ],
        toolbar:
          'undo redo | formatselect fontsizeselect bold italic underline | forecolor colorpicker backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | image media link | removeformat code',
        ...fieldOptions,
      }"
    />
  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator';
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/link';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/image';

import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/icons/default/';

import Editor from '@tinymce/tinymce-vue';

console.log(Editor);
export default {
  name: 'FieldTinyMce',
  mixins: [VueFormGenerator.abstractField],
  components: { editor: Editor },
  computed: {
    fieldOptions() {
      return this.schema && this.schema.fieldOptions ? this.schema.fieldOptions : {};
    },
  },
  data() {
    return {
      //
    };
  },
};
</script>

<style>
@import '~tinymce/skins/ui/oxide/skin.css';
@import '~tinymce/skins/ui/oxide/content.min.css';

.vue-form-generator .field-wrap .tox-editor-header button,
.vue-form-generator .field-wrap .tox-editor-header input[type='submit'] {
  border-radius: 0;
  border: initial;
  display: inherit;
  padding: initial;
}
</style>
