<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button
        type="button"
        class="navbar-toggler navbar-toggler-left"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <a v-if="$route.path !== '/app/overview'" @click="previous()" class="btn-simple text-danger pl-0"
        ><i class="fa fa-angle-double-left"></i
      ></a>
      <a class="navbar-brand" @click="goToDashboard()" href="#">{{ $t('common.labels.dashboard') }}</a>

      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="dropdown">
              <i class="nc-icon nc-palette"></i>
            </a>
          </li>
          <!--
            <drop-down tag="li">
              <template slot="title">
                <i class="nc-icon nc-planet"></i>
                <b class="caret"></b>
                <span class="notification">0</span>
              </template>
              <a class="dropdown-item" href="#">...</a>
            </drop-down>

            <li class="nav-item">
              <a href="#" class="nav-link">
                <i class="nc-icon nc-zoom-split"></i>
                <span class="d-lg-block">&nbsp;Search</span>
              </a>
            </li>
          -->
          <li class="nav-item" v-if="userHasRole('DEVELOPER') || userIs('ADMIN')">
            <router-link :to="{ path: '/app/devops/overview' }" class="text-dark" v-if="$store.state.user._id">
              <i class="fa fa-server"></i> Devops
            </router-link>
          </li>
          <li class="ml-2 nav-item" v-if="userHasRole('DEVELOPER') || userIs('ADMIN')">
            <router-link :to="{ path: '/app/deployment' }" class="text-dark" v-if="$store.state.user._id">
              <i class="fa fa-cubes"></i>
              {{ $t('app.labels.deployments') }}
            </router-link>
          </li>
          <li class="nav-item ml-5" v-if="!pageIsBookmarked">
            <a href="javascript:void(0);" class="" @click.prevent="saveBookmark()">
              <i class="fa fa-star-o"></i>
              <span style="">{{ $t('common.labels.addBookmark') }}</span>
            </a>
          </li>
          <li class="nav-item ml-5" v-if="pageIsBookmarked">
            <a href="javascript:void(0);" class="text-danger" @click.prevent="deleteBookmark()">
              <i class="fa fa-trash"></i>
              <span style="">{{ $t('common.labels.removeBookmark') }}</span>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="ml-2 nav-item nav-autocomplete" :class="{ 'full-search-bar': searchbarIsFocused }">
            <v-autocomplete
              :items="items"
              v-model="item"
              :auto-select-one-item="false"
              :min-len="1"
              :get-label="getLabel"
              @update-items="updateItems"
              @item-selected="itemSelected"
              :placeholder="$t('common.field.search')"
              :component-item="template"
              @focus="searchbarIsFocused = true"
              @blur="clearFocus"
            >
            </v-autocomplete>
            <button class="btn btn-simple btn-close-search-bar" @click="closeSearch()">
              <i class="fa fa-times"></i>
            </button>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ path: '/app/profile' }"
              class="nav-link text-primary user-details"
              v-if="$store.state.user._id"
              >{{ $store.state.user.firstname + ' ' + $store.state.user.lastname }}</router-link
            >
          </li>
          <li class="nav-item ml-5">
            <a href="javascript:void(0);" id="logout" class="logout nav-link" @click="logout()">
              <i class="nc-icon nc-button-power"></i>
              <span style="margin-left: 5px">{{ $t('common.labels.logout') }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import rolesMixin from '@/mixins/rolesMixin';
import Autocomplete from 'v-autocomplete';
import AutocompleteItem from './AutocompleteItem.vue';

export default {
  components: {
    'v-autocomplete': Autocomplete,
  },
  mixins: [rolesMixin],
  mounted() {
    this.getBookmarks();
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    pageIsBookmarked() {
      return !!(this.$store.state.bookmarks && this.$store.state.bookmarks.find((b) => b.url === this.$route.fullPath));
    },
  },
  data() {
    return {
      activeNotifications: false,
      item: null,
      items: [],
      template: AutocompleteItem,
      searchbarIsFocused: false,
    };
  },
  methods: {
    getLabel(item) {
      return item.name;
    },
    updateItems(text) {
      this.items = [];
      this.searchProjects(text);
      this.searchApps(text);
    },

    searchProjects(text) {
      this.$http
        .get(`/project?search=${text}`)
        .then((response) => {
          const items = response.data.body.map((item) => ({
            _id: item._id,
            id: item._id,
            name: item.code,
            description: item.name,
            type: 'project',
            color: 'red',
          }));
          this.items = this.items.concat(items);
        })
        .catch(console.warn);
    },

    searchApps(text) {
      this.$http
        .get(`/deployment?search=${text}`)
        .then((response) => {
          const items = response.data.body.map((item) => ({
            _id: item._id,
            name: `${item.name}`,
            description: '',
            type: 'deployment',
            color: 'blue',
          }));
          this.items = this.items.concat(items);
        })
        .catch(console.warn);
    },

    itemSelected(item) {
      this.searchbarIsFocused = false;
      switch (item.type) {
        case 'deployment':
          this.item = null;
          this.$router.push(`/app/deployment/${item._id}`);
          break;
        case 'project':
          this.item = null;
          this.$router.push(`/app/project/project/${item._id}`);
          break;
        default:
          break;
      }
    },

    closeSearch() {
      this.item = null;
      this.searchbarIsFocused = false;
    },

    clearFocus() {
      setTimeout(() => {
        this.searchbarIsFocused = false;
      }, 350);
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    getBookmarks() {
      let bookmarks = localStorage.getItem('intranet_bookmarks');
      if (!bookmarks) {
        bookmarks = [];
      } else {
        bookmarks = JSON.parse(bookmarks);
      }
      this.$store.commit('bookmarks', bookmarks);
      return bookmarks;
    },
    saveBookmark() {
      const bookmarks = this.getBookmarks();
      const name = window.prompt(
        'Please select a name for your bookmark',
        (this.$route.meta && this.$route.meta.title) || this.$route.name,
      );
      if (!name) {
        return;
      }
      if (!bookmarks.find((b) => b.url === this.$route.fullPath)) {
        bookmarks.push({
          name,
          url: this.$route.fullPath,
        });
        localStorage.setItem('intranet_bookmarks', JSON.stringify(bookmarks));
      }
    },
    deleteBookmark() {
      const bookmarks = this.getBookmarks();
      const index = bookmarks.findIndex((b) => b.url === this.$route.fullPath);
      if (!index) {
        return;
      }
      bookmarks.splice(index, 1);
      localStorage.setItem('intranet_bookmarks', JSON.stringify(bookmarks));
    },
    goToDashboard() {
      this.$router.push('/app/overview');
    },
    previous() {
      this.$router.go('-1');
    },
  },
};
</script>
<style scoped>
.nav-link.active,
.nav-item.active {
  font-weight: bold;
}

.nav-autocomplete {
  transition: all 50ms linear;
}

.btn-close-search-bar {
  display: none;
}

.full-search-bar .btn-close-search-bar {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.5em;
  margin: 0px 5px;
  padding: 1px 5px;
}
</style>
