<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form>
            <br />
            <card>
              <h4 slot="header" class="card-title">
                {{ $t('common.labels.write_daily_standup') }}
              </h4>
              <div class="row">
                <div class="col-12 col-md-6 daily-standup-date">
                  <div class="form-group">
                    <label
                      class="control-label"
                      >{{ $t('common.labels.date') }}</label
                    >
                    <div class="input-group">
                      <datetime
                        class="form-group"
                        v-model="dailyStandup.date"
                        :input-class="' form-control'"
                        auto
                      ></datetime>
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 text-right"
                  style="padding-top: 25px"
                >
                  <button
                    type="button"
                    class="btn btn-info btn-sm btn-fill ml-1"
                    @click="saveDaily()"
                  >
                    {{ $t('common.buttons.saveDraft') }}
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm btn-fill ml-1"
                    @click.prevent="submit"
                  >
                    {{ $t('common.buttons.submit') }}
                  </button>
                </div>
              </div>
            </card>
            <card style="border-top: none; border-radius: 0 0 5px 5px">
              <div class="tab-content">
                <div class="standup-section">
                  <div class="section-item">
                    <div class="section-title">
                      {{ $t('app.daily_standup.whatIDidToday') }}
                      <div
                        v-if="
                        dailyStandup.todaysTasks &&
                        dailyStandup.todaysTasks.projects &&
                        dailyStandup.todaysTasks.projects.length > 0
                      "
                        class="badge badge-info pull-right"
                        style="margin-left: 10px; margin-top: 2px"
                      >
                        {{ $t('app.daily_standup.total') }}:
                        {{ getTotalTime() }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12"
                      v-for="(project, index) in dailyStandup.todaysTasks.projects"
                      v-bind:key="index"
                    >
                      <table
                        class="table table-sm table-striped table-bordered table-standup"
                      >
                        <caption style="caption-side: top">
                          <div class="row">
                            <div class="col-10 col-md-8">
                              <EnyoSelect
                                :placeholder="$t('app.daily_standup.selectProject')"
                                v-model="project.projectId"
                                :multiple="false"
                                track-by="_id"
                                label="name"
                                :options="projectList"
                                :searchable="true"
                                @input="() => addTomorrowAndPbProjectIfNecessary(project)"
                              ></EnyoSelect>
                            </div>
                            <div class="col-2 col-md-4">
                              <button
                                type="button"
                                class="btn btn-danger btn-sm pull-right btn-remove"
                                @click="dailyStandup.todaysTasks.projects.splice(index, 1)"
                              >
                                <i
                                  class="fa fa-trash fa-lg"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">
                              {{ $t('app.daily_standup.task') }}
                            </th>
                            <th scope="col">
                              {{ $t('app.daily_standup.time') }}
                            </th>
                            <th scope="col" class="text-center button-row">
                              <i
                                class="fa fa-plus-circle fa-lg text-info"
                                aria-hidden="true"
                                @click="addProjectTask(index, 'today')"
                              ></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(task, taskindex) in project.tasks"
                            v-bind:key="taskindex"
                          >
                            <td>
                              <fg-input
                                type="text"
                                placeholder="Task"
                                v-model="task.task"
                              ></fg-input>
                            </td>
                            <td class="task-time">
                              <fg-input
                                type="number"
                                min="0"
                                class="number"
                                :placeholder="$t('app.daily_standup.time')"
                                v-model="task.time"
                              ></fg-input>
                            </td>
                            <td class="text-center button-row">
                              <i
                                class="fa fa-trash text-danger"
                                aria-hidden="true"
                                @click="project.tasks.splice(taskindex, 1)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>{{ $t('app.daily_standup.total') }}</th>
                            <th colspan="2">{{ getTotalTime(index) }}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div class="col-md-12 text-center mb-3">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-info"
                        @click="addProject('today')"
                      >
                        <i
                          class="fa fa-plus-circle fa-lg text-info"
                          aria-hidden="true"
                        ></i>
                        {{ $t('app.labels.project') }}
                      </button>
                    </div>
                    <div
                      class="col-md-12"
                      v-if="
                        dailyStandup.todaysTasks &&
                          dailyStandup.todaysTasks.projects &&
                          dailyStandup.todaysTasks.projects.length > 0
                      "
                    >
                      {{ $t('app.daily_standup.totalHoursWorked') }}:
                      {{ getTotalTime() }}
                    </div>
                  </div>
                </div>
                <div class="standup-section">
                  <div class="section-item">
                    <div class="section-title">{{
                    $t('app.daily_standup.whatIHavePlannedForTomorrow')
                    }}</div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12"
                      v-for="(project, index) in dailyStandup.planForTomorrow.projects"
                      v-bind:key="index"
                    >
                      <table
                        class="table table-sm table-striped table-bordered table-standup"
                      >
                        <caption style="caption-side: top">
                          <div class="row">
                            <div class="col-10 col-md-8">
                              <EnyoSelect
                                :placeholder="$t('app.daily_standup.selectProject')"
                                v-model="project.projectId"
                                :multiple="false"
                                track-by="_id"
                                label="name"
                                :options="projectList"
                                :searchable="true"
                              ></EnyoSelect>
                            </div>
                            <div class="col-2 col-md-4">
                              <button
                                type="button"
                                class="btn btn-danger btn-sm pull-right btn-remove"
                                @click="dailyStandup.planForTomorrow.projects.splice(index, 1)"
                              >
                                <i
                                  class="fa fa-trash fa-lg"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">
                              {{ $t('app.daily_standup.task') }}
                            </th>
                            <th scope="col">
                              {{ $t('app.daily_standup.comment') }}
                            </th>
                            <th scope="col" class="text-center button-row">
                              <i
                                class="fa fa-plus-circle fa-lg text-info"
                                aria-hidden="true"
                                @click="addProjectTask(index, 'tomorrow')"
                              ></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(task, taskindex) in project.tasks"
                            v-bind:key="taskindex"
                          >
                            <td>
                              <fg-input
                                type="text"
                                placeholder="Task"
                                v-model="task.task"
                              ></fg-input>
                            </td>
                            <td>
                              <fg-input
                                type="text"
                                :placeholder="$t('app.daily_standup.comment')"
                                v-model="task.comment"
                              ></fg-input>
                            </td>
                            <td class="text-center button-row">
                              <i
                                class="fa fa-trash text-danger"
                                aria-hidden="true"
                                @click="project.tasks.splice(taskindex, 1)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-info"
                        @click="addProject('tomorrow')"
                      >
                        <i
                          class="fa fa-plus-circle fa-lg text-info"
                          aria-hidden="true"
                        ></i>
                        {{ $t('app.labels.project') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="standup-section">
                  <div class="section-item">
                    <div class="section-title">{{
                        $t('app.daily_standup.myProblemsAndBlockingPoints')
                    }}</div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12"
                      v-for="(project, index) in dailyStandup.problemsOrBlocking.projects"
                      v-bind:key="index"
                    >
                      <div class="row mb-3">
                        <div class="col-10 col-md-8">
                          <EnyoSelect
                            :placeholder="$t('app.daily_standup.selectProject')"
                            v-model="project.projectId"
                            :multiple="false"
                            track-by="_id"
                            label="name"
                            :options="projectList"
                            :searchable="true"
                          ></EnyoSelect>
                        </div>
                        <div class="col-2 col-md-4">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm pull-right btn-remove"
                            @click="dailyStandup.problemsOrBlocking.projects.splice(index, 1)"
                          >
                            <i
                              class="fa fa-trash fa-lg"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                      <textarea
                        class="form-control mb-3"
                        :placeholder="$t('app.daily_standup.myProblemsAndBlockingPoints')"
                        style="height: 200px"
                        v-model="project.problemsOrBlocking[0]"
                      ></textarea>
                    </div>
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-info"
                        @click="addProject('pb')"
                      >
                        <i
                          class="fa fa-plus-circle fa-lg text-info"
                          aria-hidden="true"
                        ></i>
                        {{ $t('app.labels.project') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="standup-section">
                  <div class="section-item">
                    <div class="section-title">{{
                      $t('app.daily_standup.otherInfos')
                    }}</div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          class="control-label"
                          >{{ $t('app.daily_standup.otherInfos') }}</label
                        >
                        <textarea
                          class="form-control"
                          :placeholder="$t('app.daily_standup.notes')"
                          style="height: 200px"
                          v-model="dailyStandup.other"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '@/components/UIComponents/Cards/Card.vue';
import apiErrors from '@/mixins/api-errors';
import _ from 'lodash';

export default {
  components: {
    Card,
  },
  data() {
    return {
      id: '',
      dailyStandupOriginal: {},
      dailyStandup: {
        userId: '',
        date: '',
        todaysTasks: {
          notes: '',
          projects: [{
            projectId: '',
            tasks: [
              {
                task: '',
                time: '',
                comment: '',
              },
            ],
          }],
        },
        planForTomorrow: {
          notes: '',
          projects: [{
            projectId: '',
            tasks: [
              {
                task: '',
                comment: '',
              },
            ],
          }],
        },
        problemsOrBlocking: {
          notes: '',
          projects: [],
        },
        other: '',
      },
      projects: [],
    };
  },
  mixins: [apiErrors],
  created() {
    this.dailyStandup.userId = this.$store.state.user._id;
  },
  computed: {
    projectList() {
      const p = [];
      this.$store.state.data.projects.forEach((element) => {
        if (!element.completed) {
          element.name = `${element.code} - ${element.name}`;
          p.push(element);
        }
      });
      return _.sortBy(p, 'name');
    },
  },
  mounted() {
    this.getProjects();
    if (localStorage.getItem('dailyStandup')) {
      try {
        this.dailyStandup = JSON.parse(localStorage.getItem('dailyStandup'));
      } catch (e) {
        console.warn(e);
      }
    }
    this.dailyStandup.date = new Date().toISOString();
    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getDailyStandup();
    }
  },
  methods: {
    submit() {
      if (!this.dailyStandup.userId) {
        this.$notify({
          title: this.$t('app.daily_standup.messages.userIsRequired'),
          type: 'warning',
        });
        return;
      }
      if (!this.dailyStandup.date) {
        this.$notify({
          title: this.$t('app.daily_standup.messages.dateIsRequired'),
          type: 'warning',
        });
        return;
      }
      if (
        !this.dailyStandup.todaysTasks.notes
        && this.dailyStandup.todaysTasks.projects.length === 0
      ) {
        this.$notify({
          title: this.$t('app.daily_standup.messages.dailyStatusIsEmpty'),
          type: 'warning',
        });
        return;
      }
      if (this.dailyStandup.todaysTasks.projects.length > 0) {
        let exists = false;
        if (this.dailyStandup.todaysTasks.projects.filter((e) => e.projectId === '').length > 0) {
          this.$notify({
            title: this.$t('app.daily_standup.messages.projectMissingInDailyTasks'),
            type: 'warning',
          });
          return;
        }
        this.dailyStandup.todaysTasks.projects.forEach((project) => {
          if (project.tasks.length === 0) {
            exists = true;
          } else if (project.tasks.filter((e) => e.task === '').length > 0) {
            exists = true;
          }
        });
        if (exists) {
          this.$notify({
            title: this.$t('app.daily_standup.messages.someTasksInDailyStatusIsNotFilledProperly'),
            type: 'warning',
          });
          return;
        }
      }
      if (
        !this.dailyStandup.planForTomorrow.notes
        && this.dailyStandup.planForTomorrow.projects.length === 0
      ) {
        this.$notify({
          title: this.$t('app.daily_standup.messages.planForTomorrowIsEmpty'),
          type: 'warning',
        });
        return;
      }
      if (this.dailyStandup.planForTomorrow.projects.length > 0) {
        let exists = false;
        if (this.dailyStandup.planForTomorrow.projects.filter((e) => e.projectId === '').length > 0) {
          this.$notify({
            title: this.$t('app.daily_standup.messages.projectMissingInTomorrowsTasks'),
            type: 'warning',
          });
          return;
        }
        this.dailyStandup.planForTomorrow.projects.forEach((project) => {
          if (project.tasks.length === 0) {
            exists = true;
          } else if (project.tasks.filter((e) => e.task === '').length > 0) {
            exists = true;
          }
        });
        if (exists) {
          this.$notify({
            title: this.$t('app.daily_standup.messages.someTasksInTomorrowsTasksIsNotFilledProperly'),
            type: 'warning',
          });
          return;
        }
      }
      if (this.dailyStandup.problemsOrBlocking.projects.length > 0) {
        let exists = false;
        if (this.dailyStandup.problemsOrBlocking.projects.filter((e) => e.projectId === '').length > 0) {
          this.$notify({
            title: this.$t('app.daily_standup.messages.projectMissingInProblemsOrBlockingTasks'),
            type: 'warning',
          });
          return;
        }
        this.dailyStandup.problemsOrBlocking.projects.forEach((project) => {
          if (project.problemsOrBlocking.length === 0) {
            exists = true;
          } else if (project.problemsOrBlocking.filter((e) => e === '').length > 0) {
            exists = true;
          }
        });
        if (exists) {
          this.$notify({
            title: this.$t('app.daily_standup.messages.someProblemsOrBlockingIsNotFilledProperly'),
            type: 'warning',
          });
          return;
        }
      }
      localStorage.setItem('dailyStandup', JSON.stringify(this.dailyStandup));
      if (this.id) {
        this.$http
          .put(`daily_standup/${this.id}`, this.dailyStandup)
          .then((res) => {
            if (res) {
              localStorage.removeItem('dailyStandup');
              this.$notify({
                title: this.$t('common.messages.successfullyUpdated'),
                type: 'success',
              });
              // this.resetForm();
              window.location = '/app/daily_standup/list';
            }
          })
          .catch(this.apiErrorCallback);
      } else {
        this.$http
          .post('daily_standup', this.dailyStandup)
          .then((res) => {
            if (res) {
              localStorage.removeItem('dailyStandup');
              this.$notify({
                title: this.$t('common.messages.successfullyUpdated'),
                type: 'success',
              });
              this.resetForm();
            }
          })
          .catch(this.apiErrorCallback);
      }
    },
    saveDaily() {
      localStorage.setItem('dailyStandup', JSON.stringify(this.dailyStandup));
      this.$notify({
        title: this.$t('app.daily_standup.messages.dailyDraftSaved'),
        type: 'success',
      });
    },
    addProject(type) {
      switch (type) {
        case 'today':
          this.dailyStandup.todaysTasks.projects.push({
            projectId: '',
            tasks: [
              {
                task: '',
                time: '',
                comment: '',
              },
            ],
          });
          break;
        case 'tomorrow':
          this.dailyStandup.planForTomorrow.projects.push({
            projectId: '',
            tasks: [
              {
                task: '',
                comment: '',
              },
            ],
          });
          break;

        case 'pb':
          this.dailyStandup.problemsOrBlocking.projects.push({
            projectId: '',
            problemsOrBlocking: [''],
          });
          break;

        default:
          break;
      }
    },
    addTomorrowAndPbProjectIfNecessary(projectItem) {
      if (!projectItem || !projectItem.projectId) {
        return;
      }

      const tomorrowProjects = this.dailyStandup.planForTomorrow.projects || [];
      const pbProjects = this.dailyStandup.problemsOrBlocking.projects || [];

      [tomorrowProjects, pbProjects].forEach((projectList) => {
        const targetProject = projectList.find((p) => p && p.projectId === projectItem.projectId);

        if (targetProject) {
          return;
        }

        const emptyProject = projectList.find((p) => p && !p.projectId);

        if (emptyProject) {
          emptyProject.projectId = projectItem.projectId;
        } else if (tomorrowProjects === projectList) {
          projectList.push({
            projectId: projectItem.projectId,
            tasks: [
              {
                task: '',
                comment: '',
              },
            ],
          });
        } else if (pbProjects === projectList) {
          projectList.push({
            projectId: projectItem.projectId,
            problemsOrBlocking: [''],
          });
        }
      });
    },
    addProjectTask(index, type) {
      switch (type) {
        case 'today':
          this.dailyStandup.todaysTasks.projects[index].tasks.push({
            task: '',
            time: '',
            comment: '',
          });
          break;
        case 'tomorrow':
          this.dailyStandup.planForTomorrow.projects[index].tasks.push({
            task: '',
            comment: '',
          });
          break;
        case 'pb':
          this.dailyStandup.problemsOrBlocking.projects[index].problemsOrBlocking.push('');
          break;

        default:
          break;
      }
    },
    resetForm() {
      if (!this.id) {
        this.dailyStandup = {
          userId: this.$store.state.user._id,
          date: '',
          todaysTasks: {
            notes: '',
            projects: [],
          },
          planForTomorrow: {
            notes: '',
            projects: [],
          },
          problemsOrBlocking: {
            notes: '',
            projects: [],
          },
          other: '',
        };
      } else {
        this.dailyStandup = this.dailyStandupOriginal;
      }
    },
    getProjects() {
      this.$http
        .get('/project')
        .then((res) => {
          this.$store.commit('projects', res.data.body);
        })
        .catch((err) => console.error(err));
    },
    getDailyStandup() {
      this.$http
        .get(`daily_standup/${this.id}`)
        .then((res) => {
          if (res.data.body.user) {
            delete res.data.body.user;
          }
          this.dailyStandupOriginal = res.data.body;
          this.dailyStandup = res.data.body;
        })
        .catch(this.apiErrorCallback);
    },

    getTotalTime(projectIndex = null) {
      let total = 0;
      if (
        this.dailyStandup.todaysTasks
        && this.dailyStandup.todaysTasks.projects
        && this.dailyStandup.todaysTasks.projects.length > 0
      ) {
        if (projectIndex !== null && this.dailyStandup.todaysTasks.projects[projectIndex]) {
          this.dailyStandup.todaysTasks.projects[projectIndex].tasks.forEach((task) => {
            if (task.time) {
              total += parseFloat(task.time);
            }
          });
        } else {
          this.dailyStandup.todaysTasks.projects.forEach((project) => {
            project.tasks.forEach((task) => {
              if (task.time) {
                total += parseFloat(task.time);
              }
            });
          });
        }
      }
      return total.toFixed(1);
    },
  },
};
</script>
<style>
.daily-standup-date .vdatetime {
  width: 100%;
}
.task-time {
  max-width: 60px;
}

.task-time input {
  width: 100%;
}

.vdatetime.form-group {
  margin-bottom: 0;
}

.section-title {
  padding: 0.5rem;
  font-weight: bold;
  font-size: 15px;
}

.section-item {
  margin-bottom: 10px;
  text-align: center;
  cursor: default;
}

.standup-section {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 25px;
}
</style>
