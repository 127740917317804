<template>
  <crud-component model-name="gmail_alert" primaryKey="_id" v-bind="$props" :crudNeedsRefresh="crudNeedsRefresh">
    <template slot="create-form" slot-scope="{ selectedItem }">
      <VueFormGenerator
        ref="form"
        :schema.sync="formSchema"
        :model="selectedItem"
        :options="formOptions"
      ></VueFormGenerator>
    </template>
    <template slot="edit-form" slot-scope="{ selectedItem }">
      <VueFormGenerator
        ref="form"
        :schema.sync="formSchema"
        :model="selectedItem"
        :options="formOptions"
      ></VueFormGenerator>
    </template>
  </crud-component>
</template>
<script>
import apiErrors from '@/mixins/api-errors';

export default {
  props: {
    name: String,
    modelName: String,
    model: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    options: {
      default: () => ({
        mode: 'local',
        actions: {
          create: true,
          edit: true,
          view: true,
          delete: true,
          import: false,
          export: false,
        },
        columns: [],
      }),
    },
  },
  components: {
    // 'VueFormGenerator': VueFormGenerator.component
  },
  data() {
    return {
      crudNeedsRefresh: false,
      absence: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
      },

      formSchema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Slack Account',
            hint: '@paul or #random',
            model: 'slackAccount',
            styleClasses: 'col-md-12',
          },
          {
            type: 'vueMultiSelect',
            inputType: 'text',
            label: 'Search Criterias',
            fieldOptions: {
              multiple: true,
              taggable: true,
              enum: ['Enyosolutions'],
              onNewTag: this.addTag,
            },
            values: ['Enyosolutions'],
            model: 'searchCriterias',
            multi: true,
            styleClasses: 'col-md-12',
          },
          {
            type: 'input',
            inputType: 'text',
            label: '',
            hint: '',
            model: 'frequency',
            styleClasses: 'col-md-6',
          },
          {
            type: 'vueMultiSelect',
            inputType: 'text',
            label: 'Frequency',
            fieldOptions: {
              multiple: false,
              enum: ['min', 'hour', 'day'],
            },
            values: ['min', 'hour', 'day'],
            model: 'frequencyUnit',
            multi: false,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Created by',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            hint: '',
            model: 'createdBy',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Last modified by',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            hint: '',
            model: 'lastModifiedBy',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'datetime',
            label: 'Created On',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            model: 'createdOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'datetime',
            label: 'Last Modified On',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            model: 'lastModifiedOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
        ],
      },
    };
  },
  mixins: [apiErrors],
  created() {},
  mounted() {
    this.$modal = $('#goodieModal');
    const matched = this.$route.matched[this.$route.matched.length - 1];
    if (this.$route.params.id) {
      this.parentPath = matched.path.replace('/edit', '').replace('/:id', '');
    } else {
      this.parentPath = matched.path;
    }
  },
  methods: {
    addTag(newTag, id, options, value) {
      options.push(newTag);
      value.push(newTag);
    },
  },
};
</script>
<style></style>
