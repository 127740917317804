<template>
  <div class="content" style="height: 100vh; background: #eee">
    <div class="container-fluid">
      <div class="sidebox row">
        <card
          class="card-user card-user col-xl-2 offset-xl-5 col-md-4 offset-md-4 col-lg-4 offset-lg-4 col-xs-10 offset-xs-1"
          style="margin-top: 20% !important"
        >
          <div class="author">
            <a href="#">
              <h4 class="title text-primary text-bold">
                {{ $t('common.labels.reset') }}<br />
                <small></small>
              </h4>
            </a>
          </div>
          <div class="description text-center">
            <form @submit.prevent="submitForm()">
              <div class="form-group">
                <label for="password">{{ $t('common.field.new.password') }}</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  :placeholder="$t('common.field.new.password')"
                  autocomplete="off"
                  required
                />
              </div>
              <div class="form-group">
                <label for="confirmPassword">{{ $t('common.field.confirmPassword') }}</label>
                <input
                  v-model="confirmPassword"
                  type="password"
                  class="form-control"
                  :placeholder="$t('common.field.confirmPassword')"
                  autocomplete="off"
                  required
                />
              </div>
              <button type="submit" class="btn btn-primary btn-block">{{ $t('common.buttons.submit') }}</button>
            </form>
          </div>
          <div slot="footer" class="d-block">
            <router-link to="/login" class="btn btn-simple mr-auto text-info">{{
              $t('common.labels.login')
            }}</router-link>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/UIComponents/Cards/Card.vue';
import apiErrors from '@/mixins/api-errors';

export default {
  components: {
    Card,
  },
  props: {
    token: String,
  },
  mixins: [apiErrors],
  data() {
    return {
      password: null,
      confirmPassword: null,
    };
  },
  methods: {
    submitForm() {
      if (this.password !== this.confirmPassword) {
        this.$notify({
          title: this.$t('common.messages.passwordsDoNotMatch'),
          type: 'warning',
        });
        return;
      }
      this.$http
        .post(`/user/reset/${this.token}`, {
          password: this.password,
        })
        .then((res) => {
          this.$notify({
            title: this.$t('common.messages.successfullyReset'),
            type: 'success',
          });
          this.$router.push('/');
        })
        .catch(this.apiErrorCallback);
    },
  },
};
</script>
