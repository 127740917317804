<template>
  <div class="content" id="BusinessReportPage">
    <div class="container-fluid">
      <div class="row">
        <div class="offset-md-9 col-xs-12 col-md-3 text-right">
          <date-range-picker
            class="form-group vgt-date-range"
            :placeholder="$t('common.field.start')"
            @update="getClosedCrmProjects()"
            :locale-data="datePicker.locale"
            v-model="dateRange"
            :opens="'left'"
            :auto-apply="true"
          >
          </date-range-picker>
        </div>
      </div>
      <div class="row" v-if="userHasRole('INTERNAL')">
        <div class="col-xl-3 col-md-6 counter-widget" v-if="generalStats">
          <stats-card v-if="generalStats">
            <div slot="header" class="icon-warning">
              <i class="fa fa-euro-sign text-warning"></i>
            </div>
            <div slot="content">
              <p class="card-category">{{ $t('app.labels.sales_income') }} Total</p>
              <h3 class="card-title">{{ formatNumber(generalStats.incomeTotal) }} €</h3>
            </div>
            <div slot="footer"></div>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6 counter-widget" v-if="generalStats">
          <stats-card v-if="generalStats">
            <div slot="header" class="icon-warning">
              <i class="fa fa-euro-sign text-warning"></i>
            </div>
            <div slot="content">
              <p class="card-category">{{ $t('app.labels.sales_income') }} {{ currentYear }}</p>
              <h3 class="card-title">{{ formatNumber(generalStats.incomeCurrentYear) }} €</h3>
            </div>
            <div slot="footer" class="text-right">Objectif <b class="text-success">360.000€</b></div>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6 counter-widget">
          <stats-card v-if="generalStats">
            <div slot="header" class="icon-warning">
              <i class="fa fa-euro-sign text-warning"></i>
            </div>
            <div slot="content">
              <p class="card-category">{{ $t('app.labels.sales_income') }} {{ currentMonth }}</p>
              <h3 class="card-title">{{ generalStats.incomeCurrentMonth }} €</h3>
            </div>
            <div slot="footer">Objectif <b class="text-success">30.000€</b></div>
          </stats-card>
        </div>
      </div>
      <div class="row" v-if="userHasRole('INTERNAL')">
        <div class="col-md-12">
          <h4 class="card-title">{{ $t('app.labels.bizdev') }}</h4>
          <hr />
        </div>
      </div>
      <div class="row" v-if="userHasRole('INTERNAL')">
        <div class="col-md-6 widget">
          <table-and-charts-card
            initialState="line"
            :title="$t('app.labels.sales_income')"
            :tableColumns="statsTable.columns"
            :dataSource="statsTable.data"
            chartColKey="item"
            chartRowKey="value"
            :needsRefresh="graphNeedsRefresh"
            :table-options="{
              headerStyle: 'warning',
              actions: { noActions: true, view: false, filter: false, refresh: false },
            }"
          >
          </table-and-charts-card>
        </div>
        <div class="col-md-6 widget">
          <table-and-charts-card
            initialState="line"
            :title="$t('app.labels.sales_count')"
            :tableColumns="statsTable.columns"
            :dataSource="statsTable.data"
            chartColKey="item"
            chartRowKey="count"
            :needsRefresh="graphNeedsRefresh"
          ></table-and-charts-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 widget">
          <AjaxTable
            :columns="[
              'title',
              {
                type: 'list-of-value',
                listName: 'projects',
                label: 'Project',
                field: 'projectId',
              },
              {
                type: 'date',
                label: 'Date',
                field: 'date',
              },
              'completed',
            ]"
            entity="milestone"
            url="/crud/milestone"
            @view="
              (item) => {
                $router.push({ name: 'milestone-view', params: { id: item._id } });
              }
            "
            :options="{ actions: { delete: false, search: false, filter: false }, pagination: false }"
            :params="{ filters: {}, sort: { date: 'DESC' }, perPage: 10 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StatsCard from '@/components/UIComponents/Cards/StatsCard.vue';
import rolesMixin from '@/mixins/rolesMixin';
import DateRangePicker from 'vue2-daterange-picker';
import { TableAndChartsCard } from 'vue-enyo-components';
import moment from 'moment';

export default {
  mixins: [rolesMixin],
  components: {
    StatsCard,
    TableAndChartsCard,
    DateRangePicker,
  },
  data() {
    return {
      generalStats: {},
      dateRange: {
        startDate: moment()
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      graphNeedsRefresh: false,
      currentMonth: moment().format('MM-YYYY'),
      currentYear: moment().format('YYYY'),
      datePicker: {
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
          monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
          firstDay: 1, // ISO first day of week - see moment documenations for details
        },
      },

      lineChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          low: 0,
          high: 500,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },

      statsTable: {
        // columns: ['item', 'count', 'value'],
        columns: [
          {
            label: this.$t('app.projects.months'),
            field: 'item',
          },
          {
            label: this.$t('app.projects.contracts'),
            field: 'count',
          },
          {
            label: this.$t('app.projects.projects'),
            field: 'projects',
            type: 'object',
          },
          {
            label: this.$t('app.projects.value'),
            field: 'valueInEuros',
          },
        ],
        data: [],
      },
    };
  },
  async mounted() {
    this.getClosedCrmProjects();
    this.getMissingDailyStandupMembers();

    try {
      const savedStats = JSON.parse(localStorage.getItem('businessReportPage_generalStats'));
      if (savedStats.columns && savedStats.data) {
        // this.statsTable = savedStats;
        // this.graphNeedsRefresh = true;
      }
    } catch (e) {
      console.warn(e);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatNumber(number) {
      if (!number) {
        return;
      }
      return number.toLocaleString(
        'de-DE',
        // leave undefined to use the browser's locale,
        // or use a string like 'en-US' to override it.
        { minimumFractionDigits: 0 },
      );
    },

    getClosedCrmProjects() {
      this.generalStats.incomeTotal = 0;
      this.generalStats.incomeCurrentMonth = 0;
      this.generalStats.incomeCurrentYear = 0;
      this.$http
        .get('/airtable/crm/closed_projects', { params: { ...this.dateRange } })
        .then((res) => {
          const { stats } = res.data;
          const days = [];
          const daysCount = [];
          const daysNumber = [];
          let max = 0;

          const newData = [];
          stats.forEach((stat) => {
            days.push(stat.month);
            daysCount.push(stat.value);
            daysNumber.push(stat.count);
            this.generalStats.incomeTotal += stat.value;
            if (stat.month === this.currentMonth) {
              this.generalStats.incomeCurrentMonth += stat.value;
            }
            if (stat.month.endsWith(this.currentYear)) {
              this.generalStats.incomeCurrentYear += stat.value;
            }
            newData.unshift({
              item: stat.month,
              count: stat.count,
              projects: stat.projects,
              value: `${stat.value}`,
              valueInEuros: `${stat.value}€`,
            });
            if (max < stat.value) {
              max = stat.value;
            }
          });
          this.statsTable.data = newData;
          this.lineChart.data.labels = [...days];
          this.lineChart.data.series = [[...daysCount], daysNumber];
          this.lineChart.options.high = max;

          this.$forceUpdate();
          this.graphNeedsRefresh = true;
          localStorage.setItem('businessReportPage_generalStats', JSON.stringify(this.statsTable));
        })
        .catch((err) => console.warn(err));
    },

    getStats() {
      this.$http
        .get('/stats')
        .then((res) => {
          this.generalStats = res.data.body;
          // For Chart
          const days = [];
          const daysCount = [];
          let max = 0;
          this.generalStats.user.perMonth.forEach((user) => {
            days.push(`${user.day}/${user.month}`);
            daysCount.push(user.count);
            if (max < user.count) {
              max = user.count;
            }
          });
          this.lineChart.data.labels = [...days];
          this.lineChart.data.series = [[...daysCount]];
          this.lineChart.options.high = max;

          // For Table
          const tableData = [];
          Object.keys(this.generalStats).forEach((item) => {
            tableData.push({
              item: this.$t(`stats.table.${item}`),
              count: this.generalStats[item].totalCount,
            });
          });
          this.statsTable.data = tableData;

          this.$forceUpdate();
          this.graphNeedsRefresh = true;
        })
        .catch((err) => console.warn(err));
    },
    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },
  },
};
</script>
<style lang="scss">
.contracts-table {
  .card-body {
    height: 343px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
