<template>
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      aria-valuenow="50"
      aria-valuemin="0"
      aria-valuemax="100"
      v-bind:class="['bg-' + type]"
      v-bind:style="{ width: progress + '%' }"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'progress-notification',
  props: {
    progress: { type: [String, Number], default: 50 },
    type: { type: [String], default: 'info' },
  },
  components: {},
};
</script>
<style>
</style>
