<template>
  <div class="content trello-content">
    <div class="container-fluid">
      <h2>
        {{ $t('app.labels.myTrelloCards') }}
        <span v-if="cards" class="badge badge-primary">{{ cards.length }}</span>
      </h2>
      <div class="row text-right">
        <div class="col-md-6 pull-right">
          <button @click="createCardModal()" class="btn btn-info">{{ $t('trello.label.createCard') }}</button>
        </div>
        <div class="col-md-6 pull-right">
          <fg-input
            type="text"
            :placeholder="$t('common.field.search')"
            v-model="search"
            :addonRightButton="true"
            addonRightButtonIcon="fa fa-search"
            addonRightButtonClass="btn btn-fill btn-primary"
            @rightClicked="loadCards"
          ></fg-input>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <div
            class="ui mb-5"
            v-for="board of boards"
            v-bind:key="board.id"
            v-if="checkIfCardExists(board.id, 'board')"
          >
            <br />
            <h5 class="text-secondary">
              <b>{{ board.name }}</b>
              |
              <a :href="board.shortUrl" target="_blank">{{ $t('trello.label.goToBoard') }}</a>
            </h5>
            <div class="lists" v-if="board.lists && board.lists.length > 0">
              <div class="row">
                <div class="col-md-3" style="padding: 5px" v-for="list of board.lists" v-bind:key="list.id">
                  <div v-if="checkIfCardExists(list.id, 'list')" class="trello-list">
                    <header>{{ list.name }}</header>
                    <div class="t-list list-wrapper">
                      <div class="list t-list-content" v-for="card of cards" v-bind:key="card.id" :title="card.desc">
                        <template v-if="card.idBoard === board.id && card.idList === list.id">
                          <div class="list-cards u-fancy-scrollbar u-clearfix t-list-cards tr-sortable ui-sortable">
                            <a class="list-card tr-member-droppable ui-droppable">
                              <div class="list-card-details tr-card-details">
                                <span class="list-card-title tr-card-name" @click="openCard(card)">{{
                                  card.name
                                }}</span>
                                <div class="list-card-labels js-card-labels" @click="openCard(card)">
                                  <span
                                    class="card-label"
                                    v-for="label of card.idLabels"
                                    v-bind:key="label"
                                    v-bind:class="'card-label-' + getLabelDetails(board.id, label, 'color')"
                                    :title="getLabelDetails(board.id, label, 'name')"
                                    >&nbsp;{{ getLabelDetails(board.id, label, 'name') }}</span
                                  >
                                </div>
                                <div class="badges">
                                  <span class="tr-badges">
                                    <div
                                      class="trelloLink"
                                      v-bind:title="$t('trello.label.viewInTrello')"
                                      @click="openCardInTrello(card)"
                                    >
                                      <i class="fa fa-trello text-info"></i>
                                    </div>
                                    <div
                                      class="badge tr-due-date-badge is-due-soon"
                                      title="This card is due in less than twenty-four hours."
                                      v-if="card.due"
                                    >
                                      <span class="badge-icon fa fa-clock"></span>
                                      <span class="badge-text tr-due-date-text">{{ formatDate(card.due) }}</span>
                                    </div>
                                  </span>
                                </div>
                                <div class="list-card-members t-list-card-members">
                                  <div
                                    class="member tr-member-on-card-menu"
                                    v-for="member of card.idMembers"
                                    v-bind:key="member"
                                  >
                                    <img
                                      class="left"
                                      height="30"
                                      width="30"
                                      v-if="getMemberDetails(member, 'avatarUrl')"
                                      v-bind:src="getMemberDetails(member, 'avatarUrl') + '/30.png'"
                                      v-bind:alt="
                                        getMemberDetails(member, 'fullName') +
                                          ' (' +
                                          getMemberDetails(member, 'username') +
                                          ')'
                                      "
                                      v-bind:title="
                                        getMemberDetails(member, 'fullName') +
                                          ' (' +
                                          getMemberDetails(member, 'username') +
                                          ')'
                                      "
                                    />
                                    <span
                                      class="member-initials"
                                      v-if="!getMemberDetails(member, 'avatarUrl')"
                                      v-bind:title="
                                        getMemberDetails(member, 'fullName') +
                                          ' (' +
                                          getMemberDetails(member, 'username') +
                                          ')'
                                      "
                                      >{{ getMemberDetails(member, 'initials') }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </template>
                      </div>
                    </div>
                    <footer></footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- // cards list -->
      </div>
      <div class="modal" tabindex="-1" role="dialog" id="addCardModal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <form @submit.prevent="addCard()">
              <div class="modal-header bg-primary text-white">
                <h3 class="modal-title mt-0">{{ $t('trello.label.addCard') }}</h3>
                <button type="button" class="close" aria-label="Close" @click="closeModal()">
                  <span aria-hidden="true" class="text-white">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label">{{ $t('trello.label.board') }}</label>
                    <multiselect
                      v-bind:placeholder="$t('trello.label.selectBoards')"
                      v-model="newCard.board"
                      :value="newCard.board"
                      :multiple="false"
                      :options="boards || []"
                      track-by="id"
                      label="name"
                      @select="onBoardSelect"
                      :required="true"
                    ></multiselect>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">{{ $t('trello.label.list') }}</label>
                    <multiselect
                      v-bind:placeholder="$t('trello.label.selectLists')"
                      v-model="newCard.list"
                      :value="newCard.list"
                      :multiple="false"
                      :options="lists"
                      :disabled="!newCard.board"
                      label="name"
                      @select="onListSelect"
                      :required="true"
                    ></multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <fg-input
                      type="text"
                      v-bind:label="$t('trello.label.cardTitle')"
                      v-bind:placeholder="$t('trello.label.cardTitle')"
                      v-model="newCard.title"
                      :required="true"
                    ></fg-input>
                  </div>
                  <div class="col-md-12">
                    <label class="control-label">{{ $t('trello.label.cardDescription') }}</label>
                    <textarea
                      v-model="newCard.descriptions"
                      placeholder="$t('trello.label.cardDescription')"
                      class="form-control"
                      style="height: 100px"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <slot name="edit-modal-footer">
                  <button type="button" class="btn btn-default btn-simple mr-auto" @click="closeModal()">
                    {{ $t('common.buttons.cancel') }}
                  </button>
                  <button type="submit" class="btn btn-primary ml-auto">{{ $t('common.buttons.save') }}</button>
                </slot>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- View Modal -->
      <div class="modal" tabindex="-1" role="dialog" id="viewCardModal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header bg-info text-white">
              <h3 class="modal-title mt-0">{{ selectedCard.name }}</h3>
              <button type="button" class="close" aria-label="Close" @click="closeModal()">
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-8 pre-content">
                  <div class="clearfix">
                    <h6>{{ $t('trello.label.labels') }}</h6>
                    <div class="clearfix">
                      <div class="list-card-labels js-card-labels">
                        <span
                          class="card-label card-label-lg"
                          v-for="label of getBoardLables(selectedCard.idBoard)"
                          v-bind:key="label.id"
                          v-bind:class="'card-label-' + label.color"
                          :title="label.name"
                          @click="updateCardLabel(selectedCard, label.id)"
                        >
                          &nbsp;
                          <span>{{ label.name }}</span>
                          <span
                            class="fa fa-check pull-right"
                            style="margin-top: 3px"
                            v-if="selectedCard.idLabels.includes(label.id)"
                          ></span>
                        </span>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div class="clearfix">
                    <h6>{{ $t('trello.label.description') }}</h6>
                    <div class="trello-description-container">{{ selectedCard.desc || 'No Description' }}</div>
                  </div>
                </div>
                <div class="col-4 pre-content">
                  <div class="clearfix">
                    <h6>{{ $t('trello.label.list') }}</h6>
                    <div class="clearfix" style="cursor: pointer">
                      <multiselect
                        v-bind:placeholder="$t('trello.label.selectLists')"
                        v-model="selectedCardList"
                        :value="selectedCardList"
                        :allow-empty="false"
                        :multiple="false"
                        :options="getBoardLists(selectedCard.idBoard)"
                        label="name"
                        @select="onCardListSelect"
                      ></multiselect>
                      <br />
                    </div>
                  </div>
                  <div class="pop-over-section tr-board-members">
                    <h6>{{ $t('trello.label.members') }}</h6>
                    <ul class="pop-over-member-list checkable u-clearfix tr-mem-list">
                      <div v-for="member of getBoardMembers(selectedCard.idBoard)" v-bind:key="member.idMember">
                        <li
                          class="item tr-member-item"
                          v-bind:class="{ active: isCardMember(member.idMember, selectedCard.idMembers) }"
                          @click="updateCardMember(selectedCard, member.idMember)"
                        >
                          <a class="name tr-select-member" v-bind:title="getMemberDetails(member.idMember, 'fullName')">
                            <span class="member tr-member">
                              <img
                                class="member-avatar"
                                v-if="getMemberDetails(member.idMember, 'avatarUrl')"
                                height="30"
                                width="30"
                                v-bind:src="getMemberDetails(member.idMember, 'avatarUrl') + '/30.png'"
                                v-bind:alt="
                                  getMemberDetails(member.idMember, 'fullName') +
                                    ' (' +
                                    getMemberDetails(member.idMember, 'username') +
                                    ')'
                                "
                                v-bind:title="
                                  getMemberDetails(member.idMember, 'fullName') +
                                    ' (' +
                                    getMemberDetails(member.idMember, 'username') +
                                    ')'
                                "
                              />
                              <span
                                class="member-initials"
                                v-if="!getMemberDetails(member.idMember, 'avatarUrl')"
                                v-bind:title="
                                  getMemberDetails(member.idMember, 'fullName') +
                                    ' (' +
                                    getMemberDetails(member.idMember, 'username') +
                                    ')'
                                "
                                >{{ getMemberDetails(member.idMember, 'initials') }}</span
                              >
                            </span>
                            <span
                              class="full-name"
                              v-bind:name="
                                getMemberDetails(member.idMember, 'fullName') +
                                  ' (' +
                                  getMemberDetails(member.idMember, 'username') +
                                  ')'
                              "
                              >{{ getMemberDetails(member.idMember, 'fullName') }}</span
                            >
                            <!-- <span class="fa fa-check pull-right"></span> -->
                          </a>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" style="border-top: 1px solid lightgrey; padding-top: 5px">
              <slot name="edit-modal-footer">
                <button type="button" class="btn btn-default btn-simple mr-auto" @click="closeModal()">
                  {{ $t('common.buttons.cancel') }}
                </button>
                <button type="button" class="btn btn-info ml-auto" @click="openCardInTrello(selectedCard)">
                  {{ $t('trello.label.viewInTrello') }}
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiErrors from '@/mixins/api-errors';
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {},
  data() {
    return {
      cards: [],
      search: '',
      $addModal: null,
      $viewModal: null,
      boards: [],
      lists: [],
      orgMembers: [],
      selectedCard: {},
      selectedCardList: {},
      newCard: {
        board: '',
        list: '',
        title: '',
        description: '',
      },
    };
  },
  watch: {
    // search: "loadCards"
  },
  mixins: [apiErrors],
  created() {
    this.getOrgMembers();
    this.loadBoards();
    this.loadCards();
  },
  mounted() {
    this.$addModal = $('#addCardModal');
    this.$viewModal = $('#viewCardModal');
  },
  methods: {
    formatDate(date) {
      return moment(date).format('Do MMM - h:mm A');
    },
    filteredCards() {
      const that = this;
      if (this.cards && this.cards.length > 0) {
        // console.log("CARDS", this.cards);
        return this.cards.filter(
          (element) => element.name && element.name.toLowerCase().indexOf(that.search.toLowerCase()) > -1,
        );
      }
      return [];
    },
    getOrgMembers() {
      this.orgMembers = this.$store.state.trelloOrgMembers || [];
      this.$http
        .get('/trello/org/member')
        .then((res) => {
          if (res && res.data && res.data.body) {
            if (typeof res.data.body === 'object') {
              // this.cards = res.data.body;
              // console.log("mmbers", res.data.body);
              this.orgMembers = res.data.body;
              this.$store.commit('trelloOrgMembers', this.orgMembers);
            }
          }
        })
        .catch(this.apiErrorCallback);
    },

    getLabelDetails(boardId, labelId, field) {
      let value = '';
      this.getBoardLables(boardId).forEach((label) => {
        if (labelId === label.id && label[field]) {
          value = label[field];
        }
      });
      return value;
    },

    getBoardLables(boardId) {
      let labels = [];
      this.boards.forEach((board) => {
        if (board.id === boardId) {
          labels = board.labels;
        }
      });
      return labels;
    },

    getBoardLists(boardId) {
      let lists = [];
      this.boards.forEach((board) => {
        if (board.id === boardId) {
          lists = board.lists;
        }
      });
      return lists;
    },

    isCardMember(memberId, cardMembers) {
      if (cardMembers && cardMembers.length > 0) {
        return cardMembers.includes(memberId);
      }
      return false;
    },

    getMemberDetails(memberId, field) {
      let result;
      this.orgMembers.forEach((member) => {
        if (member.id === memberId) {
          result = member[field];
        }
      });
      return result;
    },

    getBoardMembers(boardId) {
      let members = [];
      this.boards.forEach((board) => {
        if (board.id === boardId) {
          members = board.memberships;
        }
      });
      return members;
    },

    updateCardLabel(card, labelId) {
      if (card.idLabels.includes(labelId)) {
        this.$http
          .delete(`/trello/card/${card.id}/label/${labelId}`)
          .then((res) => {
            if (res && res.data && res.data.body) {
              this.loadCards();
              // this.selectedCard.idMembers.push(memberId);
              this.selectedCard.idLabels = _.remove(this.selectedCard.idLabels, (n) => n !== labelId);
              this.$notify({
                title: this.$t('trello.messages.successfullyRemoved'),
                type: 'success',
              });
            }
          })
          .catch(this.apiErrorCallback);
      } else {
        this.$http
          .post(`/trello/card/${card.id}/label/${labelId}`)
          .then((res) => {
            if (res && res.data && res.data.body) {
              this.loadCards();
              this.selectedCard.idLabels.push(labelId);
              this.$notify({
                title: this.$t('trello.messages.successfullyAdded'),
                type: 'success',
              });
            }
          })
          .catch(this.apiErrorCallback);
      }
    },

    updateCardMember(card, memberId) {
      if (!this.isCardMember(memberId, card.idMembers)) {
        this.$http
          .post(`/trello/card/${card.id}/member/${memberId}`)
          .then((res) => {
            if (res && res.data && res.data.body) {
              this.loadCards();
              this.selectedCard.idMembers.push(memberId);
              this.$notify({
                title: this.$t('trello.messages.successfullyAdded'),
                type: 'success',
              });
            }
          })
          .catch(this.apiErrorCallback);
      } else {
        this.$http
          .delete(`/trello/card/${card.id}/member/${memberId}`)
          .then((res) => {
            if (res && res.data && res.data.body) {
              this.loadCards();
              // this.selectedCard.idMembers.push(memberId);
              this.selectedCard.idMembers = _.remove(this.selectedCard.idMembers, (n) => n !== memberId);
              this.$notify({
                title: this.$t('trello.messages.successfullyRemoved'),
                type: 'success',
              });
            }
          })
          .catch(this.apiErrorCallback);
      }
    },

    onCardListSelect(list) {
      this.$http
        .put(`/trello/card/${this.selectedCard.id}/list/${list.id}`)
        .then((res) => {
          if (res && res.data && res.data.body) {
            this.loadCards();
            this.selectedCard.idList = list.id;
            this.$notify({
              title: this.$t('trello.messages.successfullyAdded'),
              type: 'success',
            });
          }
        })
        .catch(this.apiErrorCallback);
    },

    loadCards() {
      this.cards = this.$store.state.trelloMemberCards || [];
      this.$http
        .get('/trello/member-card', { params: { search: this.search } })
        .then((res) => {
          if (res && res.data && res.data.body) {
            if (typeof res.data.body === 'object') {
              this.cards = res.data.body;
              // this.cards.forEach(element => {
              //   element.idMembers.push("5bf2ab952ce04312f2534dc0");
              // });
              this.$store.commit('trelloMemberCards', this.cards);
              this.$store.commit('trelloCardCount', this.cards.length);
            }
          }
        })
        .catch(this.apiErrorCallback);
    },

    loadBoards() {
      this.boards = this.$store.state.trelloMemberBoards || [];
      this.$http
        .get('/trello/member-board')
        .then((res) => {
          if (res && res.data && res.data.body) {
            if (typeof res.data.body === 'object') {
              this.boards = res.data.body;
              // console.log("Boards", this.boards);
              this.$store.commit('trelloMemberBoards', this.boards);
            }
          }
        })
        .catch(this.apiErrorCallback);
    },

    onBoardSelect(e) {
      this.newCard.board = e.id;
      this.boards.forEach((board) => {
        if (board.id === this.newCard.board) {
          this.lists = board.lists;
        }
      });
    },

    onListSelect(e) {
      this.newCard.list = e.id;
    },

    openCard(card) {
      this.selectedCard = card;

      this.getBoardLists(this.selectedCard.idBoard).forEach((list) => {
        if (list.id === this.selectedCard.idList) {
          this.selectedCardList = list;
        }
      });

      this.$viewModal.modal('show');
    },
    openCardInTrello(card) {
      this.closeModal();
      window.open(card.shortUrl, '_blank');
    },
    createCardModal() {
      this.$addModal.modal('show');
    },
    closeModal() {
      this.$addModal.modal('hide');
      this.$viewModal.modal('hide');
    },
    addCard() {
      if (!this.newCard.board) {
        this.$notify({
          title: 'Board is required',
          type: 'success',
        });
        return;
      }

      if (!this.newCard.list) {
        this.$notify({
          title: 'List is required',
          type: 'success',
        });
        return;
      }

      if (!this.newCard.title) {
        this.$notify({
          title: 'Title is required',
          type: 'success',
        });
        return;
      }

      this.$http
        .post(`/trello/board/${this.newCard.board.id}/list/${this.newCard.list.id}/card`, this.newCard)
        .then((res) => {
          this.$notify({
            title: 'Successfully Created',
            type: 'success',
          });
          this.closeModal();
        })
        .catch(this.apiErrorCallback);
    },
    checkIfCardExists(id, entity) {
      let exists = false;
      if (this.boards.length === 0 || this.cards.length === 0) {
        return exists;
      }
      switch (entity) {
        case 'board':
          this.filteredCards().forEach((card) => {
            if (card.idBoard === id) {
              exists = true;
            }
          });
          break;
        case 'list':
          this.filteredCards().forEach((card) => {
            if (card.idList === id) {
              exists = true;
            }
          });
          break;

        default:
          break;
      }
      return exists;
    },
  },
};
</script>
<style>
.trello-content .multiselect__tags {
  padding: 7px 20px 0 8px !important;
}
.trello-content .multiselect__select {
  width: auto;
}
</style>
