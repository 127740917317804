<template>
  <div class="content" style="padding-top: 10px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="clearfix">
            <div class="col-md-12">
              <h4 class="card-title">
                {{ $t('common.labels.daily_standup') }}:
                <span class="text-info" v-if="dailyStandup.date">{{ dailyStandup.date | formatDate }}</span>
                <br />
                <small
                  class="text-warning"
                  v-if="dailyStandup.user && (dailyStandup.user.firstname || dailyStandup.user.lastname)"
                >
                  <b>{{ dailyStandup.user.firstname }} {{ dailyStandup.user.lastname }}</b>
                </small>
              </h4>
            </div>
            <div class="card-body" v-if="next || previous" style="padding: 1.25rem 1.25rem 0 1.25rem">
              <div class="card" style="margin-bottom: 0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <router-link v-if="previous" :to="{ name: 'dailyStandupView', params: { id: previous._id } }">
                        <button class="btn btn-primary btn-sm" title="Previous">
                          <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                        </button>
                      </router-link>
                    </div>
                    <div class="col-md-6 text-right">
                      <router-link v-if="next" :to="{ name: 'dailyStandupView', params: { id: next._id } }">
                        <button class="btn btn-primary btn-sm" title="Next">
                          <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title text-primary">
                    {{ $t('app.daily_standup.whatIDidToday') }}:
                    <span class="badge badge-info float-right"
                      >{{ $t('app.daily_standup.total') }}: {{ getTotalTime() }}h</span
                    >
                  </h4>
                </div>
                <div class="card-body">
                  <div
                    class="row"
                    v-if="
                      dailyStandup.todaysTasks &&
                        dailyStandup.todaysTasks.projects &&
                        dailyStandup.todaysTasks.projects.length > 0
                    "
                  >
                    <div class="col-4" v-for="(project, index) in dailyStandup.todaysTasks.projects" v-bind:key="index">
                      <div class="card">
                        <ul class="list-group">
                          <li class="list-group-item disabled">
                            <span>{{ getProjectDetail(project.projectId, 'label') }}</span>
                          </li>
                          <li class="list-group-item" v-for="(task, taskIndex) in project.tasks" v-bind:key="taskIndex">
                            {{ task.task }}
                            <span class="badge badge-primary float-right" v-if="task.time"
                              >{{ round(task.time, 1) }}h</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div v-if="dailyStandup.todaysTasks && dailyStandup.todaysTasks.notes">
                    {{ $t('app.daily_standup.other') }}
                  </div>
                  <div class="card" v-if="dailyStandup.todaysTasks && dailyStandup.todaysTasks.notes">
                    <div class="card-body pre-content">{{ dailyStandup.todaysTasks.notes }}</div>
                  </div>
                </div>
              </div>
              <!-- Plan for Tomorrow -->
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title text-primary">{{ $t('app.daily_standup.whatIHavePlannedForTomorrow') }}:</h4>
                </div>
                <div class="card-body">
                  <div
                    class="row"
                    v-if="
                      dailyStandup.planForTomorrow &&
                        dailyStandup.planForTomorrow.projects &&
                        dailyStandup.planForTomorrow.projects.length > 0
                    "
                  >
                    <div
                      class="col-4"
                      v-for="(project, index) in dailyStandup.planForTomorrow.projects"
                      v-bind:key="index"
                    >
                      <div class="card">
                        <ul class="list-group">
                          <li class="list-group-item disabled">
                            <span>{{ getProjectDetail(project.projectId, 'label') }}</span>
                          </li>
                          <li class="list-group-item" v-for="(task, taskIndex) in project.tasks" v-bind:key="taskIndex">
                            {{ task.task }}
                            <br />
                            <small>{{ task.comment }}</small>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div v-if="dailyStandup.planForTomorrow && dailyStandup.planForTomorrow.notes">
                    {{ $t('app.daily_standup.other') }}
                  </div>
                  <div class="card" v-if="dailyStandup.planForTomorrow && dailyStandup.planForTomorrow.notes">
                    <div class="card-body pre-content">{{ dailyStandup.planForTomorrow.notes }}</div>
                  </div>
                </div>
              </div>
              <!-- Blocking and Problems -->
              <div
                class="card"
                v-if="
                  (dailyStandup.problemsOrBlocking &&
                    dailyStandup.problemsOrBlocking.projects &&
                    dailyStandup.problemsOrBlocking.projects.length > 0) ||
                    (dailyStandup.problemsOrBlocking && dailyStandup.problemsOrBlocking.notes)
                "
              >
                <div class="card-header">
                  <h4 class="card-title text-primary">{{ $t('app.daily_standup.myProblemsAndBlockingPoints') }}</h4>
                </div>
                <div class="card-body">
                  <div
                    class="row"
                    v-if="
                      dailyStandup.problemsOrBlocking &&
                        dailyStandup.problemsOrBlocking.projects &&
                        dailyStandup.problemsOrBlocking.projects.length > 0
                    "
                  >
                    <div
                      class="col-4"
                      v-for="(project, index) in dailyStandup.problemsOrBlocking.projects"
                      v-bind:key="index"
                    >
                      <span>{{ getProjectDetail(project.projectId, 'label') }}</span>
                      <div class="card">
                        <ul>
                          <li v-for="(problem, problemIndex) in project.problemsOrBlocking" v-bind:key="problemIndex">
                            {{ problem }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div v-if="dailyStandup.problemsOrBlocking && dailyStandup.problemsOrBlocking.notes">
                    {{ $t('app.daily_standup.other') }}
                  </div>
                  <div class="card" v-if="dailyStandup.problemsOrBlocking && dailyStandup.problemsOrBlocking.notes">
                    <div class="card-body pre-content">{{ dailyStandup.problemsOrBlocking.notes }}</div>
                  </div>
                </div>
              </div>
              <!-- Other infos -->
              <div class="card" v-if="dailyStandup.other">
                <div class="card-header">
                  <h4 class="card-title text-primary">{{ $t('app.daily_standup.otherInfos') }}</h4>
                </div>
                <div class="card-body">
                  <div class="card">
                    <div class="card-body pre-content">{{ dailyStandup.other }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import apiErrors from '@/mixins/api-errors';

export default {
  components: {},
  data() {
    return {
      activeTab: 'today',
      id: '',
      projects: [],
      dailyStandup: {
        userId: '',
        date: '',
        todaysTasks: {
          notes: '',
          projects: [
            {
              projectId: '',
              tasks: [
                {
                  task: '',
                  time: '',
                },
              ],
            },
          ],
        },
        planForTomorrow: {
          notes: '',
          projects: [
            {
              projectId: '',
              tasks: [
                {
                  task: '',
                  comment: '',
                },
              ],
            },
          ],
        },
        problemsOrBlocking: {
          notes: '',
          projects: [],
        },
        other: '',
      },
      next: null,
      previous: null,
    };
  },
  mixins: [apiErrors],
  created() {},
  mounted() {
    this.getProjects();
    if (this.$route.params && this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getData();
    }
  },
  methods: {
    round: _.round,
    getData() {
      this.$http
        .get(`daily_standup/${this.id}`)
        .then((res) => {
          this.dailyStandup = res.data.body;
          const savedDatas = this.$store.state.filteredDailyStandups;
          if (savedDatas.length > 0) {
            if (savedDatas.indexOf(this.id) + 1 < savedDatas.length) {
              this.previous = {
                _id: savedDatas[savedDatas.indexOf(this.id) + 1],
              };
            }
            if (savedDatas.indexOf(this.id) - 1 >= 0) {
              this.next = { _id: savedDatas[savedDatas.indexOf(this.id) - 1] };
            }
          } else {
            this.next = res.data.next;
            this.previous = res.data.previous;
          }
          // this.dailyStandup.todaysTasks.notes = this.dailyStandup.todaysTasks.notes.replace(
          //   /\\r\\n/g,
          //   "<br />"
          // );
          // this.dailyStandup.planForTomorrow.notes = this.dailyStandup.planForTomorrow.notes.replace(
          //   /\\r\\n/g,
          //   "<br />"
          // );
        })
        .catch(this.apiErrorCallback);
    },
    getProjects() {
      if (!this.$store.state.listOfValues.projects) {
        this.$store
          .dispatch('refreshListOfValues')
          .then((res) => {
            this.projects = this.$store.state.listOfValues.projects;
          })
          .catch((err) => console.error(err));
      } else {
        this.projects = this.$store.state.listOfValues.projects;
      }
    },
    getProjectDetail(projectId, fields) {
      const fieldsArray = fields.split(',');
      // console.log
      let value = projectId;
      this.projects.forEach((project) => {
        if (project._id === projectId) {
          value = '';
          fieldsArray.forEach((field) => {
            value += `${project[field]} : `;
          });
          value = value.slice(0, value.length - 3);
        }
      });
      return value;
    },
    getTotalTime(projectIndex = null) {
      let total = 0;
      if (
        this.dailyStandup.todaysTasks
        && this.dailyStandup.todaysTasks.projects
        && this.dailyStandup.todaysTasks.projects.length > 0
      ) {
        if (projectIndex && this.dailyStandup.todaysTasks.projects[projectIndex]) {
          this.dailyStandup.todaysTasks.projects[projectIndex].tasks.forEach((task) => {
            if (task.time) {
              total += parseFloat(task.time);
            }
          });
        } else {
          this.dailyStandup.todaysTasks.projects.forEach((project) => {
            project.tasks.forEach((task) => {
              if (task.time) {
                total += parseFloat(task.time);
              }
            });
          });
        }
      }
      return total.toFixed(1);
    },
  },
};
</script>
<style>
.card .card-body-slim {
  padding: 0;
}
.card-content {
  padding: 5px;
}
</style>
