<template>
  <crud-component v-bind="$props" primaryKey="_id" mode="local" :crudNeedsRefresh="crudNeedsRefresh">
    <template slot="create-form" slot-scope="{ selectedItem }">
      <div class="form-group valid required col-md-12 field-EnyoSelect">
        <label for>
          <span>{{ $t('app.labels.project') }}</span>
        </label>
        <EnyoSelect
          style="width: 100%"
          v-model="selectedItem.projectId"
          url="/project"
          :options="[]"
          label="label"
          track-by="_id"
          :multiple="false"
          :searchable="true"
          :disabled="isViewMode()"
        ></EnyoSelect>
      </div>
      <VueFormGenerator
        ref="form"
        :schema.sync="formSchema"
        :model="selectedItem"
        :options="formOptions"
        :disabled="isViewMode"
      ></VueFormGenerator>
    </template>
    <template slot="edit-form" slot-scope="{ selectedItem }">
      <div class="row">
        <div class="form-group valid required col-md-12 field-EnyoSelect">
          <label for>
            <span>{{ $t('app.labels.project') }}</span>
            {{ isViewMode() }}
          </label>
          <EnyoSelect
            v-model="selectedItem.projectId"
            url="/project"
            :options="[]"
            label="label"
            track-by="_id"
            :searchable="true"
            :disabled="isViewMode()"
          ></EnyoSelect>
        </div>
        <template v-if="isViewMode()">
          <div class="form-group col-md-12">
            <h2>
              {{ selectedItem.title }}
            </h2>
          </div>
          <div class="form-group col-md-12">
            <hr />
            <div v-html="selectedItem.content"></div>
            <hr />
          </div>

          <div class="form-group col-md-6">
            <div>{{ formatDate(selectedItem.date) }}</div>
          </div>
          <div class="form-group col-md-6">
            <div>{{ selectedItem.createdBy }}</div>
          </div>
        </template>
        <VueFormGenerator
          ref="form"
          :schema.sync="formSchema"
          :model="selectedItem"
          :options="formOptions"
          v-if="!isViewMode()"
        ></VueFormGenerator>
      </div>
    </template>
  </crud-component>
</template>
<script>
import apiErrors from '@/mixins/api-errors';
import moment from 'moment';

export default {
  name: 'ReportComponent',
  props: {
    name: String,
    modelName: String,
    model: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    options: {
      default: () => ({
        mode: 'local',
        actions: {
          create: true,
          edit: true,
          view: true,
          filter: true,
          delete: true,
          import: false,
          export: false,
        },
        modalMode: 'fullpage',
        columns: [],
      }),
    },
  },
  components: {
    // 'VueFormGenerator': VueFormGenerator.component
  },
  computed: {},
  data() {
    return {
      crudNeedsRefresh: false,
      absence: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
      },
      formSchema: {
        fields: [
          {
            type: 'vueMultiSelect',
            inputType: 'text',
            label: 'Report type',
            fieldOptions: {
              multiple: false,
              enum: ['daily-report', 'client', 'team', 'meeting', 'other'],
            },
            values: ['daily-report', 'client', 'team', 'meeting', 'other'],
            model: 'type',
            multi: false,
            styleClasses: 'col-md-12',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Title',
            hint: '',
            model: 'title',
            multi: false,
            styleClasses: 'col-md-12',
          },

          {
            type: 'TinyMce',
            inputType: 'text',
            label: 'Content',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            model: 'content',
            multi: false,
            styleClasses: 'col-md-12',
          },
          {
            type: 'dateTime',
            inputType: 'datetime',
            label: 'Date',
            fieldOptions: {
              allowInputToggle: true,
              type: 'datetime',
              minuteStep: 5,
              auto: true,
              icons: {
                time: 'fa fa-clock',
                date: 'fa fa-calendar',
                up: 'fa fa-arrow-up',
                down: 'fa fa-arrow-down',
              },
            },
            required: true,
            model: 'date',
            multi: false,
            styleClasses: 'col-md-12',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'User',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            hint: '',
            model: 'userId',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Created by',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            hint: '',
            model: 'createdBy',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Created by',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            hint: '',
            model: 'lastModifiedBy',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'datetime',
            label: 'Created On',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            model: 'createdOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'datetime',
            label: 'Last Modified On',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            model: 'lastModifiedOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
        ],
      },
    };
  },
  mixins: [apiErrors],
  created() {},
  mounted() {
    this.$modal = null;
    console.log(this.$route);
    const matched = this.$route.matched[this.$route.matched.length - 1];
    if (this.$route.params.id) {
      this.parentPath = matched.path.replace('/edit', '').replace('/:id', '');
    } else {
      this.parentPath = matched.path;
    }
  },
  methods: {
    isViewMode() {
      console.log('isViewMode', this.$route.params.id && document.location.pathname.indexOf('/edit') === -1);
      return (
        this.$route.params.id && document.location.pathname.indexOf('/edit') === -1
        // this.$route.path.indexOf('/edit') === -1 &&
      );
    },

    formatDate(date) {
      return moment(date).format('LLL');
    },
  },
};
</script>
<style></style>
