<template>
  <div>
    <div v-if="intersection($store.state.user.roles, ['TEAMLEAD', 'ADMIN', 'SUPERADMIN']).length > 0" class="btn-wrapper">
      <button type="button" class="btn btn-primary btn-xl btn-fill ml-1" @click="sendDailyDigest()">
        {{ $t('common.buttons.resendDigest') }}
      </button>
    </div>
    <crud-component primaryKey="_id" :identity="identity" v-bind="$props" @list-updated="saveDatas"> </crud-component>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  props: {
    name: String,
    modelName: String,
    identity: String,
    model: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    options: {
      default: () => ({
        mode: 'local',
        actions: {
          create: true,
          edit: true,
          view: true,
          delete: true,
          import: false,
          export: false,
        },
        columns: [],
      }),
    },
  },
  methods: {
    intersection: _.intersection,
    saveDatas(data) {
      this.$store.commit('filteredDailyStandups', data && data.map((d) => d._id));
    },
    sendDailyDigest() {
      this.$http
        .post('/daily_standup/report')
        .then((res) => {
          this.$notify({
            title: this.$t('common.messages.digest_sent'),
            type: 'success',
          });
        })
        .catch(() => {
          this.$notify({
            title: this.$t('common.messages.error_send_digest'),
            type: 'warning',
          });
        });
    },
  },
};
</script>
<style>
  .btn-wrapper {
    margin: 20px 12px;
  }
</style>
