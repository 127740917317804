var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",attrs:{"id":"overviewPage"}},[_c('div',{staticClass:"container-fluid"},[(_vm.pm2Stats && _vm.pm2Stats.system_info)?_c('h3',[_vm._v(_vm._s(_vm.pm2Stats.system_info.hostname))]):_vm._e(),_c('div',{staticClass:"row"},[(_vm.pm2Stats && _vm.pm2Stats.monit && _vm.pm2Stats.monit.total_mem)?_c('div',{staticClass:"col-xl-6 col-md-6 counter-widget pointer"},[_c('stats-card',[_c('div',{staticClass:"icon-info",attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"fa fa-disk text-primary"})]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"card-category"},[_vm._v("Memory usage")]),_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.round((_vm.pm2Stats.monit.total_mem - _vm.pm2Stats.monit.free_mem) / 1024 / 1024))+"Mo / "+_vm._s(_vm.round(_vm.pm2Stats.monit.total_mem / 1024 / 1024))+"Mo ")])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"})])],1):_vm._e()]),(_vm.userHasRole('INTERNAL'))?_c('div',{staticClass:"row"},[(false)?_c('div',{staticClass:"col-md-9"},[_c('chart-card',{attrs:{"chart-data":{ series: [_vm.pm2Stats.monit.loadavg], labels: [1, 2, 3] },"chart-type":"Line","chart-options":_vm.lineChart.options,"graphNeedsRefresh":_vm.graphNeedsRefresh,"responsive-options":_vm.lineChart.responsiveOptions}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Average load")])])],2)],1):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('AjaxTable',{staticClass:"table-hover table-striped",attrs:{"title":"Deployment Jobs","columns":[
            {
              type: 'string',
              field: 'name',
            },
            {
              type: 'html',
              field: 'lastBuild.description',
            },
            {
              type: 'html',
              field: 'color',
              formatFn: this.jenkinsFormatFunction,
              filterOptions: { enabled: true, filterDropdownItems: ['blue', 'red', 'notbuilt', 'aborted'] },
            },
            {
              type: 'text',
              field: 'lastBuild.result',
            },
            {
              type: 'datetime',
              field: 'lastBuild.timestamp',
            },

            {
              type: 'url',
              field: 'url',
            } ],"rows":_vm.jenkinsJobs,"options":{
            actions: {
              create: false,
              edit: false,
              view: true,
              delete: false,
              search: true,
              dateFilter: false,
              filter: true,
            },
            pagination: true,
            noActions: false,
            filterInitialState: true,
          }},on:{"refresh":_vm.getJenkinsStats,"view":_vm.viewDeployment}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Jenkins Jobs")])])],2)],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }