<template>
  <div class="env-variables">
    <div v-for="(obj, index) in internalValue" class="env-row row" :key="index">
      <template v-if="obj">
        <div class="form-group col-5 pr-0">
          <input type="text" v-model="obj[0]" class="form-control" @blur="saveItem()" />
        </div>

        <div class="form-group col-6 pr-0">
          <input type="text" v-model="obj[1]" class="form-control" placeholder="value" @blur="saveItem()" />
        </div>
        <div class="form-group col-1">
          <button type="button" class="btn btn-danger btn-sm btn-delete" @click="removeItem(index)">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </template>
    </div>
    <button type="button" class="float-right btn btn-secondary btn-sm json-textarea-button" @click="addItem()">
      <i class="fa fa-plus"></i>
    </button>
  </div>
</template>
<script>
import VueFormGenerator from 'vue-form-generator';
// import moment from 'moment';

// You need a specific loader for CSS files

export default {
  mixins: [VueFormGenerator.abstractField],
  data() {
    return {
      warning: '',
      internalValue: [],
    };
  },
  computed: {},
  watch: {
    // eslint-disable-next-line
    value(change, old) {
      this.internalValue = Object.entries(this.value);
    },
  },
  mounted() {
    this.internalValue = Object.entries(this.value);
  },

  beforeDestroy() {},
  methods: {
    addItem() {
      this.saveItem();
      this.$set(this.value, `NewKey_${Date.now()}`, '');
      this.$set(this.internalValue, `NewKey_${Date.now()}`, '');
      this.$forceUpdate();
    },

    removeItem(key) {
      this.$delete(this.value, this.internalValue[key][0]);
      this.$delete(this.internalValue, key);
      // this.saveItem();
      this.$forceUpdate();
    },

    saveItem() {
      this.value = Object.fromEntries(this.internalValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.env-variables {
  width: 100%;
}
.env-row {
  position: relative;
}
.field-file-input {
  cursor: pointer;

  > * {
    cursor: pointer;
    width: 100%;
    height: auto;
  }
}

button.btn-delete {
  color: red !important;
  border: none;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
</style>
