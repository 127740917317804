<template>
  <div class="content devops-app-content">
    <div class="">
      <div v-if="isLoading" style="text-align: center">
        <i class="fa fa-circle-notch fa-spin fa-5x fa-fw text-primary" style="max-width: 50%; margin-top: 25%"></i>
      </div>
      <div v-if="!deployment && !isLoading" class="text-center">
        <h2>{{ $t('app.labels.notFound') }}</h2>
        <br />
        <button class="btn btn-primary" @click="createDeployment()">
          Créer un nouveau déploiement avec le nom <b>{{ $route.params.id }}</b>
        </button>
      </div>
      <div v-if="deployment">
        <div class="row">
          <div class="col-md-4 col-xl-3 col-sm-12">
            <h1 class="text-primary mt-0 text-truncate">
              {{ startCase(deployment.name || deployment.host) }}
            </h1>
          </div>
          <div class="col-md-8 col-xl-9 col-sm-12 text-right">
            <button class="btn btn-outline-primary mb-1 mr-1" @click="saveDeployment()">
              <i class="fa fa-save"></i>
              Save configuration
            </button>
            <a
              :href="'https://ci.dev.enyosolutions.com/job/' + deployment.name"
              target="_blank"
              class="btn btn-outline-info mb-1 mr-1"
            >
              <i class="fab fa-jenkins"></i> Jenkins
            </a>
            <button disabled class="btn btn-outline-secondary mb-1 mr-1 disabled">Redeploy VHOST (WIP)</button>
            <button
              class="btn btn-outline-primary mb-1 mr-1"
              v-if="deployment.type === 'node'"
              @click="redeployNodeApp()"
            >
              <i class="fa fa-code"></i>
              Restart nodejs app
            </button>
            <button class="btn btn-outline-primary mb-1 mr-1" @click="getData()">
              <i class="fa fa-sync"></i> Refresh
            </button>
            <button class="btn btn-outline-primary mb-1 mr-1" @click="deployApp()">
              <i class="fab fa-jenkins"></i> Deploy app
            </button>
          </div>
          <hr />
          <div class="col-12">
            <div class="row">
              <div class="filter-box col-md-2 col-sm-12">
                <div class="card card-primary card-statistics" @click="ping()">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1" v-html="pingFormatedStatus"></h3>
                    <h6 class="text-white">Ping status</h6>
                  </div>
                </div>
              </div>
              <!-- -->
              <div class="filter-box col-md-2 col-sm-12">
                <div class="card card-secondary card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">{{ deployment.port || '-' }}</h3>
                    <h6 class="text-white">PORT</h6>
                  </div>
                </div>
              </div>
              <!-- -->
              <div class="filter-box col-md-2 col-sm-12">
                <div class="card card-secondary card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">
                      {{ deploymentData && deploymentData.lastBuild ? deploymentData.lastBuild.number : '-' }}
                    </h3>
                    <h6 class="text-white">LAST BUILD</h6>
                  </div>
                </div>
              </div>
              <!-- -->
              <div class="filter-box col-md-2 col-sm-12">
                <div class="card card-warning card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1"><i class="fa fa-git"></i></h3>
                    <h6 class="text-white text-center">Git</h6>
                  </div>
                </div>
              </div>
              <!-- -->
              <div class="filter-box col-md-2 col-sm-12">
                <div class="card card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">
                      <a class="text-primary" :href="_host" :data-tooltip="_host" target="_blank"> Open </a>
                    </h3>
                    <h6 class=""><i class="fa fa-external-link-alt"></i> Url</h6>
                  </div>
                </div>
              </div>
              <!-- -->
              <div class="filter-box col-md-2 col-sm-12">
                <div
                  class="card card-primary card-statistics"
                  @click="getDeploymentData()"
                  :class="(isBuilding ? 'animated infinite flash ' : '') + deploymentData.color"
                >
                  <div class="box text-center">
                    <h3 v-if="deploymentData" class="font-light text-white mt-1" v-html="jenkinsFormatedStatus"></h3>
                    <h6 class="text-white text-truncate">
                      {{ isBuilding ? 'Deploying...' : 'Deployment status' }}
                    </h6>
                  </div>
                </div>
              </div>
              <!-- -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-sm-12">
            <Card className="shadow-sm sticky-top card-primary side-menu">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a
                    @click="setActiveTab('home')"
                    class="nav-link"
                    :class="{ active: activeTab === 'home' }"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    ><i class="fa fa-home"></i> Home</a
                  >
                </li>
                <li @click="setActiveTab('repository')" class="nav-item">
                  <a
                    class="nav-link"
                    id="repository-tab"
                    :class="{ active: activeTab === 'repository' }"
                    data-toggle="tab"
                    href="#repository"
                    role="tab"
                    aria-controls="profile"
                    style="display: inline"
                    aria-selected="false"
                    ><i class="fa fa-git-square"></i> Git</a
                  >
                  <a v-if="deployment.gitHttp" :href="deployment.gitHttp" target="_blank"
                    ><i class="fa fa-external-link-alt"></i
                  ></a>
                </li>
                <li class="nav-item">
                  <a
                    @click="setActiveTab('envvariables')"
                    :class="{ active: activeTab === 'envvariables' }"
                    class="nav-link"
                    id="envvariables-tab"
                    data-toggle="tab"
                    href="#envvariables"
                    role="tab"
                    aria-controls="messages"
                    aria-selected="false"
                    ><i class="fa fa-inbox"></i> Environment Variables</a
                  >
                </li>
                <li class="nav-item hide">
                  <a
                    @click="setActiveTab('database')"
                    :class="{ active: activeTab === 'database' }"
                    class="nav-link"
                    id="database-tab"
                    data-toggle="tab"
                    href="#database"
                    role="tab"
                    aria-controls="messages"
                    aria-selected="false"
                    ><i class="fa fa-database"></i> Database</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="setActiveTab('deployment-steps')"
                    :class="{ active: activeTab === 'deployment-steps' }"
                    class="nav-link"
                    id="messages-tab"
                    data-toggle="tab"
                    href="#deployment-steps"
                    role="tab"
                    aria-controls="messages"
                    aria-selected="false"
                    ><i class="fa fa-list-alt"></i> Deployment steps</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="setActiveTab('deployment-logs')"
                    :class="{ active: activeTab === 'deployment-logs' }"
                    class="nav-link"
                    id="deployment-logs-tab"
                    data-toggle="tab"
                    href="#deployment-logs"
                    role="tab"
                    aria-controls="deployment-logs"
                    aria-selected="false"
                    ><i class="fa fa-list-alt"></i> Deployment logs</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="setActiveTab('app-logs')"
                    :class="{ active: activeTab === 'app-logs' }"
                    class="nav-link"
                    id="app-logs-tab"
                    data-toggle="tab"
                    href="#app-logs"
                    role="tab"
                    aria-controls="app-logs"
                    aria-selected="false"
                    ><i class="fa fa-list-alt"></i> App logs</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="setActiveTab('settings')"
                    :class="{ active: activeTab === 'settings' }"
                    class="nav-link"
                    id="settings-tab"
                    data-toggle="tab"
                    href="#settings"
                    role="tab"
                    aria-controls="settings"
                    aria-selected="false"
                    ><i class="fa fa-cog"></i> Advanced Settings</a
                  >
                </li>
              </ul>
            </Card>
            <!-- SECOND BOX -->
            <!-- SECOND BOX -->
            <Card
              className="shadow-sm side-menu"
              v-if="activeTab && ['deployment-logs', 'repository'].indexOf(activeTab) > -1"
            >
              <div class="tab-content devops-app-content" style="">
                <div
                  class="tab-pane fade show active"
                  id="deployment-logs"
                  role="tabpanel"
                  aria-labelledby="deployment-tab"
                >
                  <ul v-if="deploymentData">
                    <li
                      v-for="build in deploymentData.builds"
                      :data-tooltip="build.result"
                      :title="build.result"
                      :key="build.id"
                    >
                      <button
                        class="btn btn-simple btn-primary"
                        :class="currentBuildId === build.number ? 'btn-primary' : 'btn-default'"
                        @click="getDeploymentLogs(build.number)"
                      >
                        Build N°{{ build.number }}
                        <i v-if="build.result && build.result === 'SUCCESS'" class="fa fa-check text-success"></i>
                        <i v-if="build.result && build.result !== 'SUCCESS'" class="fa fa-times text-danger"></i>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tab-pane fade" id="repository" role="tabpanel" aria-labelledby="profile-tab">
                  <h2>Repository (Git)</h2>
                </div>
              </div>
            </Card>
          </div>
          <div class="col-md-10 col-sm-12">
            <Card className="shadow-sm">
              <div class="tab-content devops-app-content" style="">
                <div
                  class="tab-pane fade show"
                  :class="{ 'active show': activeTab === 'home' }"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <h2>{{ deployment.name || deployment.host }}</h2>
                  <div class="row">
                    <div class="filter-box col-2">
                      <div class="card card-purple card-statistics">
                        <div class="box text-center">
                          <h3 class="font-light text-white mt-1">{{ '-' }}</h3>
                          <h6 class="text-white">SSL Enabled ?</h6>
                        </div>
                      </div>
                    </div>
                    <div class="filter-box col-2">
                      <div class="card card-info card-statistics">
                        <a :href="'https://ci.dev.enyosolutions.com/job/' + deployment.name" target="_blank">
                          <div class="box text-center">
                            <h3 class="font-light text-white mt-1"><i class="fab fa-jenkins"></i></h3>
                            <h6 class="text-white">Jenkins</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <label>Notes</label>
                  <live-edit v-model="deployment.notes" type="textarea" />
                  <hr />
                  <ul>
                    <li>Recap of the config, with target blank link to the app.</li>
                    <li>Ping of the app server.</li>
                    <li>Last deployment status.</li>
                  </ul>
                  <br />
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': activeTab === 'repository' }"
                  id="repository"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <h2>
                    Repository (Git)
                    <a
                      class="btn m-0 btn-primary float-right"
                      v-if="!deployment.git"
                      data-tooltip="Create a new gitlab repository"
                      data-tooltip-position="bottom"
                      href="https://intranet.enyosolutions.com/devops"
                      target="_blank"
                      ><i class="fa fa-plus"></i>
                    </a>
                  </h2>
                  - url of the repo (editable)
                  <div class="form-group">
                    <label>Git url</label>
                    <live-edit v-model="deployment.git"></live-edit>
                  </div>
                  <div class="form-group">
                    <label>Git ssh</label>
                    <live-edit v-model="deployment.gitSsh"></live-edit>
                  </div>
                  <div class="form-group">
                    <label>Branch</label>
                    <live-edit v-model="deployment.branch"></live-edit>
                  </div>
                  - commit log ?...
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': activeTab === 'envvariables' }"
                  id="envvariables"
                  role="tabpanel"
                  aria-labelledby="messages-tab"
                >
                  <h2>
                    Environmnent variables
                    <button
                      class="btn btn-primary mb-1 mr-1 float-right"
                      @click="saveDeployment()"
                      data-title="Save configuration"
                    >
                      <i v-if="!isTabLoading" class="fa fa-save"></i>
                      <i v-if="isTabLoading" class="fa fa-circle-notch fa-spin fa-fw text-primary"></i>
                    </button>
                  </h2>
                  <template v-if="formSchema && formSchema.fields">
                    <VueFormGenerator
                      ref="form"
                      :schema.sync="envVariablesSchema"
                      :model="deployment"
                      :options="{ validayeAsync: true, validateAfterLoad: false, validateAfterChanged: true }"
                    />
                  </template>
                  <label class="text-default text-italic text-right"
                    >For your changes to take effect you need to save your edits and and restart your nodejs app.</label
                  >
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': activeTab === 'deployment-steps' }"
                  id="deployment-steps"
                  role="tabpanel"
                  aria-labelledby="deployment-config-tab"
                >
                  <h2>
                    Deploy steps
                    <i v-if="isTabLoading" class="fa fa-circle-notch fa-spin fa-fw text-primary"></i>
                    <button
                      class="btn btn-primary mb-1 mr-1 float-right m-0"
                      @click="saveDeploymentBuildConfig()"
                      data-tooltip="Save configuration"
                    >
                      <i class="fa fa-save"></i>
                    </button>
                    <a
                      class="btn btn-simple m-0 btn-secondary float-right"
                      data-tooltip="Edit deployment config on jenkins"
                      v-if="deployment"
                      data-tooltip-position="bottom"
                      :href="'https://ci.dev.enyosolutions.com/job/' + deployment.name + '/configure'"
                      target="_blank"
                      ><i class="fa fa-edit"></i>
                    </a>
                  </h2>
                  <template v-if="deploymentBuildConfig && deploymentBuildConfig && deploymentBuildConfig.builders">
                    <div
                      class="mt-1"
                      v-for="(builder, key) in deploymentBuildConfig.builders[0]['hudson.tasks.Shell']"
                      :key="key"
                    >
                      <textarea
                        class="form-control bg-dark text-light"
                        style="min-height: 300px"
                        v-model="builder.command[0]"
                      ></textarea>
                    </div>
                    <a
                      class="btn m-0 btn-primary float-right"
                      data-tooltip="Add a new step"
                      data-tooltip-position="bottom"
                      @click="addShellStep()"
                      ><i class="fa fa-plus"></i> Add shell step</a
                    >
                    <hr />
                    <h3>SSH Steps</h3>
                    <div
                      class="mt-1"
                      v-for="(builder, key) in deploymentBuildConfig.builders[0][
                        'jenkins.plugins.publish__over__ssh.BapSshBuilderPlugin'
                      ]"
                      :key="key"
                    >
                      <h4>{{ builder.siteName }}</h4>
                      <div class="field-json-text-editor">
                        <JsonEditor
                          :value="
                            get(
                              deploymentBuildConfig.builders[0][
                                'jenkins.plugins.publish__over__ssh.BapSshBuilderPlugin'
                              ],
                              '[0][delegate][0][delegate][0][publishers][0]'
                            )
                          "
                          @input="
                            (value) =>
                              set(
                                deploymentBuildConfig.builders[0][
                                  'jenkins.plugins.publish__over__ssh.BapSshBuilderPlugin'
                                ],
                                '[0][delegate][0][delegate][0][publishers][0]',
                                value
                              )
                          "
                        />
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': activeTab === 'database' }"
                  id="dabatase"
                  role="tabpanel"
                  aria-labelledby="deployment-logs-tab"
                >
                  <h2>Database informations</h2>
                  <ul>
                    <li>Database infos</li>
                    <li>link to create db resources</li>
                  </ul>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': activeTab === 'deployment-logs' }"
                  id="deployment-logs"
                  role="tabpanel"
                  aria-labelledby="deployment-logs-tab"
                >
                  <h2>
                    Deployment logs (Build n°{{
                      deploymentLogs && deploymentLogs.buildId ? deploymentLogs.buildId : '-'
                    }})
                    <i v-if="isTabLoading" class="fa fa-circle-notch fa-spin fa-fw text-primary"></i>
                    <a
                      class="btn btn-simple m-0 btn-primary float-right"
                      data-tooltip="Edit deployment config"
                      v-if="deployment"
                      data-tooltip-position="bottom"
                      :href="'https://ci.dev.enyosolutions.com/job/' + deployment.name + '/configure'"
                      target="_blank"
                      ><i class="fa fa-edit"></i>
                    </a>
                    <a
                      class="btn btn-simple m-0 btn-secondary float-right mr-1"
                      data-tooltip="View deployment"
                      v-if="deployment"
                      data-tooltip-position="bottom"
                      :href="'https://ci.dev.enyosolutions.com/job/' + deployment.name"
                      target="_blank"
                      ><i class="fa fa-eye"></i>
                    </a>
                    <a
                      class="btn btn-simple m-0 btn-info float-right mr-1"
                      data-tooltip="Refresh logs"
                      v-if="deployment"
                      data-tooltip-position="bottom"
                      @click="getDeploymentLogs()"
                      target="_blank"
                      ><i class="fa fa-sync"></i>
                    </a>
                  </h2>
                  <div v-if="deploymentLogs && deploymentLogs.build">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Timestamp</th>
                          <th scope="col">Cause</th>
                          <th scope="col">Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{
                              deploymentLogs && deploymentLogs.build && formatTimestamp(deploymentLogs.build.timestamp)
                            }}
                          </td>
                          <td>
                            {{ buildCause }}
                            <div v-html="buildCommit"></div>
                          </td>
                          <td v-html="jenkinsFormatedBuildStatus"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <pre v-if="!isTabLoading" id="deployment-logs-text" v-html="jenkinsFormatedBuildLogs"></pre>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': activeTab === 'app-logs' }"
                  id="app-logs"
                  role="tabpanel"
                  aria-labelledby="app-logs-tab"
                >
                  <h2>
                    Application logs
                    <i v-if="isTabLoading" class="fa fa-circle-notch fa-spin fa-fw text-primary"></i>
                  </h2>
                  <pre v-if="!isTabLoading" id="deployment-logs-text">{{ appLogs }}</pre>
                </div>
                <div
                  class="tab-pane fade"
                  :class="{ 'active show': activeTab === 'settings' }"
                  id="settings"
                  role="tabpanel"
                  aria-labelledby="settings-tab"
                >
                  <h2>
                    Advanced settings
                    <button
                      class="btn btn-primary mt-0 mb-1 mr-1 ml-1 float-right"
                      @click="saveDeployment()"
                      data-tooltip="Save configuration"
                    >
                      <i v-if="!isTabLoading" class="fa fa-save"></i>
                      <i v-if="isTabLoading" class="fa fa-circle-notch fa-spin fa-fw text-primary"></i>
                    </button>
                    <button
                      @click="duplicate()"
                      class="mt-0 float-right btn btn-warning mb-1 mr-1 ml-1"
                      data-tooltip="Duplicate deployment"
                      data-tooltip-position="left"
                    >
                      <i v-if="isTabLoading" class="fa fa-circle-notch fa-spin fa-fw text-primary"></i>
                      <i v-if="!isTabLoading" class="fa fa-copy"></i>
                      Duplicate
                    </button>
                  </h2>
                  <div class="col-12 form-element">
                    <div class="form-group">
                      <label>Git url</label>
                      <input class="form-control" v-model="deployment.port" type="number" />
                    </div>
                    <div class="form-group">
                      <label>App Root folder</label>
                      <input class="form-control" v-model="deployment.appRootFolder" />
                    </div>
                  </div>
                  <template v-if="formSchema && formSchema.fields">
                    <VueFormGenerator
                      ref="form"
                      :schema.sync="formSchema"
                      :model="deployment"
                      :options="{ validayeAsync: true, validateAfterLoad: false, validateAfterChanged: true }"
                    />
                  </template>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EnyoComponents from 'vue-enyo-components';
import _ from 'lodash';
import apiErrors from '@/mixins/api-errors';
import rolesMixin from '@/mixins/rolesMixin';
import moment from 'moment';
import JsonEditor from './JsonEditor.vue';

const { LiveEdit, EnyoCard, CrudComponent } = EnyoComponents;

// import pm2Stats from "/tmp/stats.json";

export default {
  mixins: [rolesMixin, apiErrors],
  components: {
    Card: EnyoCard,
    LiveEdit,
    JsonEditor,
  },
  data() {
    return {
      activeTab: 'deployment-logs',
      tabs: [
        'home',
        'repository',
        'envvariables',
        'database',
        'deployment-steps',
        'deployment-logs',
        'app-logs',
        'settings',
      ],
      deployment: null,
      deploymentData: {},
      deploymentBuildConfig: null,
      deploymentLogs: null,
      appLogs: null,
      isLoading: true,
      isTabLoading: false,
      chart: null,
      pingStatus: '-',
      currentBuildId: null,
      innerModel: this.$store.state.data.models.find((model) => model.name === 'deployment'),
      jenkinsHandle: null,
      isTyping: false,
      jsonWarning: null,
      debounce: null,
    };
  },
  mounted() {
    this.getData();

    if (window.location.hash && this.tabs.includes(window.location.hash.replace('#', ''))) {
      this.setActiveTab(window.location.hash.replace('#', ''));
    }

    // this.statsHandle = setInterval(() => {
    //   this.getData();
    // }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.statsHandle);
    this.$store.state.io.socket.delete(
      `/api/sockets/room/app-devops-app-${this.deployment.name || this.deployment.host}`,
    );
  },
  computed: {
    _host() {
      if (!this.deployment || !this.deployment.host) {
        return '';
      }
      return this.deployment.host.indexOf('.com') > -1
        || this.deployment.host.indexOf('.pt') > -1
        || this.deployment.host.indexOf('.fr') > -1
        || this.deployment.host.indexOf('.cc') > -1
        ? this.deployment.host
        : `${this.deployment.ssl ? 'https' : 'http'}://${this.deployment.host}.enyosolutions.com`;
    },

    formSchema() {
      return this.innerModel ? CrudComponent.methods.parseSchema(this.innerModel.schema) : '';
    },

    buildCause() {
      const cause = this.deploymentLogs
        && this.deploymentLogs.build
        && this.deploymentLogs.build.actions
        && this.deploymentLogs.build.actions[0]
        && this.deploymentLogs.build.actions[0].causes
        && this.deploymentLogs.build.actions[0].causes[0]
        && this.deploymentLogs.build.actions[0].causes[0].shortDescription;

      return cause || '';
    },

    buildCommit() {
      const commitRef = _.get(this.deploymentLogs, 'build.actions[0].causes[0].data.after');
      const commitTitle = _.get(this.deploymentLogs, 'build.actions[0].causes[0].data.mergeRequestTitle');
      const site = _.get(this.deploymentLogs, 'build.actions[0].causes[0].data.targetProjectUrl');
      return commitRef && site
        ? `<a href="${site}/commit/${commitRef}" target="_blank">${commitRef} ${commitTitle}</a>`
        : '';
    },

    envVariablesSchema() {
      const schema = this.innerModel ? CrudComponent.methods.parseSchema(this.innerModel.schema) : '';
      schema.fields = schema.fields.filter((f) => f.model === 'envVariables');
      return schema;
    },

    jenkinsFormatedStatus() {
      const status = this.deploymentData && this.deploymentData.color;
      switch (status) {
        case 'blue':
          return '<i class="fa fa-circle text-success animated bounce"></i>';
        case 'blue_anime':
          return '<i class="fa fa-circle text-success"></i>';
        case 'red':
          return '<i class="fa fa-circle text-danger"></i>';
        case 'red_anime':
          return '<i class="fa fa-circle text-danger"></i>';
        case 'disabled':
        case 'grey':
          return '<i class="fa fa-circle text-default"></i>';
        case 'grey_anime':
        case 'disabled_anime':
          return '<i class="fa fa-circle text-default"></i>';
        case 'notbuilt':
          return '<i class="fa fa-question-circle text-warning"></i>';
        case 'notbuilt_anime':
          return '<i class="fa fa-question-circle text-warning"></i>';
        case undefined:
          return '<i class="fa fa-question-circle text-info"></i>';
        default:
          return `<i class="fa fa-question-circle text-info"></i> ${status}`;
      }
    },

    jenkinsFormatedBuildStatus() {
      const status = _.get(this.deploymentLogs, 'build.result');

      switch (status) {
        case 'SUCCESS':
          return `<span class="bg-success p-2 text-light ">${status}</span>`;
        case 'FAILURE':
          return `<span class="bg-danger p-2 text-light ">${status}</span>`;
        default:
          return `<span class="bg-default p-2 text-light ">${status}</span>`;
      }
    },

    jenkinsFormatedBuildLogs() {
      const logs = _.get(this.deploymentLogs, 'log');
      if (!logs) {
        return;
      }
      // eslint-disable-next-line
      const urlEexpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      return logs
        .replace(urlEexpression, '<a href="$1" target="_blank" class="text-info">$1</a>')
        .replace(/(errors?)/gi, '<span class="text-danger">$1</span>')
        .replace(/(warning)/gi, '<span class="text-warning">$1</span>')
        .replace(/(success)/gi, '<span class="text-success">$1</span>')
        .replace(/(error|failure)/gi, '<span class="text-danger">$1</span>');
    },

    pingFormatedStatus() {
      switch (this.pingStatus) {
        case 'OK':
          return '<i class="fa fa-circle fa-fw text-success animated flash"></i>';
        case 'KO':
          return '<i class="fa fa-circle fa-fw text-danger animated flash"></i>';
        default:
          return '<i class="fa fa-question-circle-o text-info"></i>';
      }
    },

    isBuilding() {
      const status = this.deploymentData && this.deploymentData.color;
      return status && status.indexOf('anime') > -1;
    },
  },
  methods: {
    round: _.round,
    get: _.get,
    set: _.set,
    startCase: _.startCase,
    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatTimestamp(date) {
      if (!date) {
        return;
      }
      return moment(date).format('DD/MM/YYYY à HH:mm');
    },

    formatSize(bytes, decimal) {
      const k = 1024;
      const decimals = typeof decimal === 'undefined' ? 2 : decimal;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      if (bytes === 0) return '0 bytes';
      return `${Math.round(bytes / Math.pow(k, i), decimals)} ${sizes[i]}`;
    },

    setActiveTab(tab) {
      if (!this.tabs.includes(tab)) {
        return;
      }
      this.activeTab = tab;
      // window.location.hash = `#${tab}`;
      switch (tab) {
        case 'deployment-steps':
          this.getDeploymentBuildConfig();
          break;
        case 'deployment-logs':
          this.getDeploymentLogs();
          break;
        case 'app-logs':
          this.getAppLogs();
          break;
        default:
          break;
      }
    },

    ping() {
      this.isTabLoading = true;
      this.$http
        .post(
          '/devops/ping',
          {
            // eslint-disable-next-line
            url: `${this._host}${this.deployment.pingUrl || ''}`,
          },
          {
            timeout: 10000,
          },
        )
        .then((res) => {
          this.pingStatus = 'OK';
        })
        .catch((err) => {
          console.warn(err);
          this.pingStatus = 'KO';
        })
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    getDeploymentData() {
      this.isTabLoading = true;
      return this.$http
        .get(`/devops/jenkins/${this.deployment.name || this.deployment.host}`)
        .then((res) => {
          this.deploymentData = res.data.body;
          if (this.deploymentData.color && this.deploymentData.color.indexOf('anime') > -1) {
            this.trackDeployment();
          }
          return this.deploymentData;
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    getDeploymentLogs(buildId, options = { silent: false }) {
      this.isTabLoading = !options.silent;
      if (buildId) {
        this.currentBuildId = buildId;
      }
      this.$http
        .get(`/devops/jenkins/${this.deployment.name || this.deployment.host}/log?buildId=${this.currentBuildId || ''}`)
        .then((res) => {
          this.deploymentLogs = res.data.body;
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    getDeploymentBuildConfig() {
      this.isTabLoading = true;
      this.$http
        .get(`/devops/jenkins/${this.deployment.name || this.deployment.host}/config`)
        .then((res) => {
          this.deploymentBuildConfig = res.data.body;
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    addShellStep() {
      if (!this.deploymentBuildConfig) {
        this.$notify({
          title: 'Jenkins Config is missing, saving won\'t do anything.',
          type: 'warning',
        });
        return;
      }
      if (!this.deploymentBuildConfig.builders || !this.deploymentBuildConfig.builders[0]) {
        this.deploymentBuildConfig.builders = [{}];
      }
      if (!this.deploymentBuildConfig.builders[0]['hudson.tasks.Shell']) {
        this.deploymentBuildConfig.builders[0]['hudson.tasks.Shell'] = [];
      }
      this.deploymentBuildConfig.builders[0]['hudson.tasks.Shell'].push({
        command: '',
      });
    },

    saveDeploymentBuildConfig() {
      if (!this.deploymentBuildConfig) {
        this.$notify({
          title: 'Jenkins Config is missing, saving won\'t do anything.',
          type: 'warning',
        });
        return;
      }
      this.isTabLoading = true;
      this.$http
        .put(`/devops/jenkins/${this.deployment.name || this.deployment.host}/config`, {
          builders: this.deploymentBuildConfig.builders,
        })
        .then((res) => {
          // this.deploymentBuildConfig = res.data.body;
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    getAppLogs() {
      this.isTabLoading = true;
      this.$http
        .get(`/deployment/${this.deployment._id}/log`)
        .then((res) => {
          this.appLogs = res.data.body.firstLines;
          this.$store.state.io.socket.on('new-app-log-line', (data) => {
            this.appLogs += `\n${data.tail.join('\n')}`;
          });
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    deployApp() {
      this.isTabLoading = true;
      this.$http
        .post(`/devops/jenkins/${this.deployment.name || this.deployment.host}/build`)
        .then((res) => {
          this.$notify({
            title: 'Build successfully started. Please wait a few seconds to see the logs',
            type: 'success',
          });
          setTimeout(() => {
            this.getDeploymentData();
            this.getDeploymentLogs();
          }, 10000);
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    redeployNodeApp() {
      this.isTabLoading = true;
      this.$http
        .put(`/devops/pm2/${this.deployment.name || this.deployment.host}`)
        .then((res) => {})
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isTabLoading = false;
        });
    },

    getData() {
      this.$http
        .get(`/deployment/${this.$route.params.id}`)
        .then((res) => {
          this.deployment = res.data.body;
          if (this.$route.params.id !== this.deployment._id) {
            return this.$router.push(`/app/deployment/${this.deployment._id}`);
          }
          this.$route.meta.title = `Deployment: ${this.deployment}`
            && _.startCase(this.deployment.name || this.deployment.appName || this.deployment.host);
          this.joinSocketRoom();
          this.ping();
          this.getDeploymentData();
          this.getDeploymentLogs();
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveDeployment() {
      this.$http
        .put(`/deployment/${this.$route.params.id}`, this.deployment)
        .then((res) => {
          this.deployment = res.data.body;
          this.$notify('Saved');
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isLoading = false;
        });
    },

    duplicate() {
      const newDeployment = _.cloneDeep(this.deployment);
      newDeployment.name = `${newDeployment.name} (copy)${Date.now()}`;
      this.$http
        .post('/deployment', newDeployment)
        .then((res) => {
          this.$notify('Duplicate completed');
          this.$router.push(`/app/deployment/${res.data.body._id}`);
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isLoading = false;
        });
    },

    trackDeployment() {
      if (this.jenkinsHandle) {
        return;
      }
      let numberOfCalls = 0;
      this.jenkinsHandle = setInterval(() => {
        numberOfCalls += 1;
        if (numberOfCalls > 300) {
          this.$notify({
            title: 'too much calls, aborting tracking',
            type: 'warning',
          });
          clearInterval(this.jenkinsHandle);
          this.jenkinsHandle = null;
        }
        if (!document.hasFocus()) {
          return;
        }
        this.$store.state.io.socket.get(
          `/api/devops/jenkins/${this.deployment.name || this.deployment.host}`,
          (data) => {
            if (!data || data.error) {
              console.warn(data);
              this.apiErrorCallback(data && data.body);
            }
            if (data && data.body) {
              this.deploymentData = data.body;
              if (!(this.deploymentData.color && this.deploymentData.color.indexOf('anime') === -1)) {
                clearInterval(this.jenkinsHandle);
                this.jenkinsHandle = null;
              }
            }
          },
        );

        this.getDeploymentLogs(this.buildId, { silent: true });
      }, 5000);
    },

    joinSocketRoom() {
      this.$store.state.io.socket.on('connection', (sock) => {
        this.$notify('connected');
      });
      this.$store.state.io.socket.post(
        `/api/sockets/room/app-devops-app-${this.deployment.name || this.deployment.host}`,
        {},
      );

      // eslint-disable-next-line
      this.$store.state.io.socket.on('jenkins-job-completed', console.log);
      this.$store.state.io.socket.on('ssl-installed-successfully', () => {
        this.$notify('ssl-installed-successfully');
      });
    },

    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },

    createDeployment() {
      this.$http
        .post('/deployment', {
          name: this.$route.params.id,
          appName: this.$route.params.id,
        })
        .then((res) => {
          this.deployment = res.data.body;
          this.$notify('Deployment created successfully');
          setTimeout(() => {
            this.$router.push(`/app/deployment/${this.$route.params.id}`);
          }, 2000);
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
a.nav-link.active {
  color: #fe4d18 !important;
  font-weight: bold;

  i {
    color: #fe4d18;
  }
}

.side-menu {
  a.nav-link,
  i {
    color: white;
  }

  .nav-link:hover {
    font-weight: bold;
  }
}

.devops-app-content {
  min-height: 100vh;

  h2 {
    margin-top: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }

  #deployment-logs-text {
    color: white;
    background: #212529;
    padding: 15px;
    border-radius: 5px;
  }
}
</style>
