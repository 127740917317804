const projectsStore = {
  state: {
    currentProject: [],
    projects: [],
  },

  getters: {
  },
  mutations: {
    currentProject(state, value) {
      state.currentProject = value;
    },
    projects(state, list) {
      state.projects = list;
    },

  },
  actions: {
    getProjects({
      commit,
    }, query) {
      const q2 = this._vm.$http.get('/project', { params: query });
      q2.then((res) => {
        commit('projects', res.data.body);
      }).catch((err) => {
        console.error(err);
      });

      return q2;
    },
    getProject({
      commit,
    }, id) {
      return this._vm.$http.get(`/project/${id}`).then((res) => {
        commit('currentProject', res.data.body);
      }).catch(err => console.error(err));
    },
  },
};

export default projectsStore;
