<template>
  <card :headerClass="'ajax-table-header ' + (opts.headerStyle ? 'colored-header bg-' + opts.headerStyle : '')">
    <template slot="header" v-if="!opts.noHeaders">
      <h4 class="card-title ajax-table-header mb-2 text-left">
        <slot name="table-title">{{ title || $t('app.labels.' + entity + 's') }}</slot>
        <div class="btn-group btn-group-sm float-right">
          <slot name="table-top-actions"> </slot>
          <div class="dropdown" v-if="canHideColumns">
            <button
              class="btn btn-secondary btn-simple dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Columns
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="max-height: 100vh; overflow: auto">
              <button
                type="button"
                v-for="col in formattedColumns"
                :key="col.field"
                class="dropdown-item"
                href="#"
                :class="{
                  'text-light bg-primary': columnsState[col.field],
                  'bg-info': col.field === 'ACTIONS',
                }"
                @click="toggleColumn(col.field)"
                :disabled="col.field === 'ACTIONS'"
              >
                {{ col.label }}
              </button>
            </div>
          </div>
          <button
            v-if="opts.actions.filter && !opts.noActions"
            type="button"
            class="btn btn-simple"
            @click="toggleFilter()"
            :class="{ 'btn-primary': filterable, 'btn-default': !filterable }"
          >
            <i class="fa fa-filter"></i>
            {{ $t('common.buttons.filters') }}
          </button>
          <div class="dropdown">
            <button
              v-if="opts.actions && opts.actions.refresh"
              type="button"
              class="btn btn-simple btn-secondary"
              @click="getItems()"
            >
              <i class="fa fa-refresh"></i>
              {{ $t('common.buttons.refresh') }}
            </button>
            <button
              class="btn btn-secondary btn-simple dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-if="opts.actions && (opts.actions.export || opts.actions.import) && !opts.noActions"
            >
              <i class="fa fa-plus"></i>
              {{ $t('table.more') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <slot name="table-top-more-actions"></slot>
              <upload-button
                v-if="opts.actions && opts.actions.import && !opts.noActions"
                name="import"
                :options="{
                  upload: true,
                  targetUrl: opts.uploadUrl || this.url + '/import',
                  method: 'POST',
                  headers: {},
                  base64: false,
                  maxSize: 1,
                  label: $t('common.buttons.import'),
                  class: 'btn btn-success btn-simple btn-block',
                  icon: 'fa fa-upload',
                }"
                @uploaded="importResponse"
              ></upload-button>
              <button
                class="btn btn-success btn-simple btn-block"
                type="button"
                v-if="opts.actions && opts.actions.export && !opts.noActions"
                @click="exportCallBack"
              >
                <i class="fa fa-file-excel"></i>
                {{ $t('common.buttons.excel') }}
              </button>
            </div>
          </div>
        </div>
      </h4>
      <p class="card-category">
        <slot name="table-subtitle"> </slot>
      </p>
    </template>
    <div class="table-responsive ajax-table-body">
      <vue-good-table
        :mode="mode"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalCount"
        styleClass="vgt-table table striped"
        :columns="displayedColumns"
        :rows="data"
        :filter-options="{
          enabled: opts && opts.actions && opts.actions.filter && !opts.noActions,
        }"
        :search-options="{
          enabled: opts && opts.actions && opts.actions.search && !opts.noActions,
          placeholder: this.$t('table.searchInput'),
        }"
        :pagination-options="{
          enabled: opts && opts.pagination,
          nextLabel: this.$t('table.next'),
          prevLabel: this.$t('table.prev'),
          rowsPerPageLabel: this.$t('table.rows_per_page'),
          ofLabel: this.$t('table.of'),
          pageLabel: this.$t('table.page'),
          allLabel: this.$t('table.all'),
          perPage: perPage,
        }"
      >
        <div slot="table-actions">
          <date-range-picker
            v-if="opts.actions && opts.actions.filter && opts.actions.dateFilter && filterable && !opts.noActions"
            class="form-group vgt-date-range"
            :placeholder="$t('common.field.start')"
            :startDate="defaultStartDate"
            :endDate="defaultEndDate"
            @update="onDateFilter"
            :locale-data="datePicker.locale"
            :opens="'left'"
          >
          </date-range-picker>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'ACTIONS'" class="text-right">
            <slot name="table-row-actions" :item="props.row">
              <template v-if="opts && opts.customActions && !opts.noActions">
                <button
                  v-for="(action, index) in opts.customActions"
                  :key="index"
                  @click="$emit('customAction', { action, item: props.row })"
                  class="btn btn-xs btn-simple"
                  :class="action.class"
                  type="button"
                  :data-title="action.title || action.label"
                >
                  {{ action.label ? $t(action.label) : '' }}
                  <i :class="action.icon" v-if="action.icon"></i>
                </button>
              </template>
            </slot>
            <button
              v-if="opts && opts.actions && opts.actions.view && !opts.noActions"
              @click="$emit('view', props.row)"
              type="button"
              class="btn-secondary btn btn-xs btn-simple btn-icon"
            >
              <i class="fa fa-eye"></i>
            </button>
            <button
              v-if="opts && opts.actions && opts.actions.edit && !opts.noActions"
              @click="$emit('edit', props.row)"
              type="button"
              class="btn-info btn btn-xs btn-simple btn-icon"
            >
              <i class="fa fa-pen"></i>
            </button>
            <button
              v-if="opts && opts.actions && opts.actions.delete && !opts.noActions"
              @click="deleteItem(props.row)"
              class="btn-danger btn btn-xs btn-simple btn-icon"
              type="button"
            >
              <i class="fa fa-times"></i>
            </button>
          </span>
          <span v-else-if="props.column.type === 'image'" @click="clickOnLine(props.row)" class="pointer">
            <img :src="props.formattedRow[props.column.field]" alt="image" class="ajax-table-img" />
          </span>
          <div class="text-avoid-overflow" v-else-if="props.column.type === 'url'">
            <a :href="props.formattedRow[props.column.field]" target="_blank" class="ajax-table-href">{{
              props.formattedRow[props.column.field]
            }}</a>
          </div>
          <div class="text-avoid-overflow" v-else-if="props.column.type === 'relation'">
            <router-link
              v-if="props.formattedRow[props.column.field]"
              :to="'/app' + props.column.relation + '/' + props.formattedRow[props.column.field]"
              class="ajax-table-href"
            >
              <span class="badge badge-info">
                {{
                  props.column.listName
                    ? getLovValue(props.formattedRow[props.column.field], props.column.listName)
                    : props.formattedRow[props.column.field]
                }}&nbsp;<i class="fa fa-external-link"></i
              ></span>
            </router-link>
          </div>
          <span
            v-else-if="props.column.type === 'list-of-value' || props.column.type === 'lov'"
            @click="clickOnLine(props.row)"
            class="pointer"
          >
            {{ getLovValue(props.formattedRow[props.column.field], props.column.listName) }}
          </span>
          <span
            v-else-if="props.column.type === 'html'"
            @click="clickOnLine(props.row)"
            class="pointer"
            v-html="props.formattedRow[props.column.field]"
          >
          </span>
          <span v-else-if="props.column.type === 'list-of-data'" @click="clickOnLine(props.row)" class="pointer">
            {{ getDataValue(props.formattedRow[props.column.field], props.column.listName) }}
          </span>
          <div v-else-if="props.column.type === 'object'" class="pointer text-avoid-overflow">
            <div class="ajax-table-collapse">
              <p
                class="collapse"
                :id="props.column.field.replace(/\./g, '_') + props.index"
                aria-expanded="false"
                @click="clickOnLine(props.row)"
              >
                <template class="label label-info" v-for="(value, key) of props.formattedRow[props.column.field]">
                  <label :key="key">{{ key }}:</label>
                  <label class="text-primary" :key="key"> {{ value }} </label> |
                </template>
              </p>
              <a
                role="button"
                class="collapsed"
                data-toggle="collapse"
                :href="'#' + props.column.field.replace(/\./g, '_') + props.index"
                aria-expanded="false"
                :aria-controls="props.column.field"
              >
              </a>
            </div>
          </div>
          <div v-else-if="props.column.field === 'color'" class="pointer text-avoid-overflow">
            {{ props.formattedRow[props.column.field].label }}
          </div>
          <div v-else @click="clickOnLine(props.row)" class="pointer text-avoid-overflow">
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
        <div slot="emptystate">{{ $t('table.empty') }}</div>
      </vue-good-table>
    </div>
  </card>
</template>
<script>
import EventBus from '@/stores/event-bus';
import Card from '@/components/UIComponents/Cards/Card.vue';
import DateRangePicker from 'vue2-daterange-picker';
import qs from 'qs';
import apiErrors from '@/mixins/api-errors';
import moment from 'moment';

import _ from 'lodash';

export default {
  name: 'ajax-table',
  token: `
  <AjaxTable  :title="title" :columns="tableColumns"
  :rows="dataSource"
  :tableNeedsRefresh="needsRefresh" :options="tableOptions">
    <template slot="table-actions"></template>
    <template slot="table-top-actions"></template>
    <template slot="table-top-more-actions"></template>
    <template slot="table-subtitle"></template>
    <template slot="table-row-actions"></template>
  </AjaxTable>
  `,
  components: {
    Card,
    DateRangePicker,
  },
  mixins: [apiErrors],
  props: {
    columns: Array,
    rows: Array,
    url: [String, Function],
    params: Object,
    entity: String,
    title: String,
    refresh: Function,
    delete: Function,
    create: Function,
    tableNeedsRefresh: Boolean,
    columnsDisplayed: {
      type: Number,
      default: 8,
    },
    perPage: {
      type: [String, Number],
      default: 20,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    defaultOptions: {
      type: Object,
      default: () => ({
        pagination: true,
        noHeaders: false,
        noActions: false,
        customActions: [], // {key, label, action: function(item, context{}}
        actions: {
          search: true,
          filter: true,
          create: true,
          view: true,
          edit: false,
          delete: false,
          export: false,
          import: false,
          dateFilter: true,
          refresh: true,
        },
      }),
    },
    mode: {
      default: 'local',
      type: String,
    },
    store: Array,
  },
  data() {
    return {
      totalCount: 0,
      filterable: false,
      columnsState: {},
      defaultStartDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      defaultEndDate: moment().format('YYYY-MM-DD'),
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        filters: {},
        range: {},
        sort: {},

        page: 0, // what page I want to show
        perPage: this.mode === 'remote' ? this.perPage : 1000, // how many items I'm showing per page
      },
      data: [],
      datePicker: {
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
          monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
          firstDay: 1, // ISO first day of week - see moment documenations for details
        },
      },
    };
  },
  created() {
    EventBus.$on(`${this.entity}:created`, this.getItems.bind(this));
    EventBus.$on(`${this.entity}:updated`, this.getItems.bind(this));
  },
  computed: {
    opts() {
      return _.merge(this.defaultOptions, this.options);
    },
    formattedColumns() {
      if (!this.columns) {
        console.error('AJAXTABLE MISSING COLUMNS');
        return [];
      }
      const newcolumns = this.columns.map((col) => {
        if (_.isString(col)) {
          const newCol = {};
          newCol.field = col;
          newCol.label = _.startCase(col);
          newCol.filterOptions = { enabled: this.filterable };
          newCol.sortable = true;
          return newCol;
        }
        if (!col.label) {
          col.label = _.startCase(col.field);
        }

        if (col.type && col.type === 'datetime') {
          col.formatFn = (value) => {
            if (!value) {
              return value;
            }
            return moment(value).format('lll');
          };
        }

        if (col.type && col.type === 'date') {
          col.formatFn = (value) => {
            if (!value) {
              return value;
            }
            return moment(value).format('DD-MM-YYYY');
          };
        }

        if (col.type && col.type === 'object') {
          col.sortFn = (x, y, c, rowX, rowY) => {
            // x - row1 value for column
            // y - row2 value for column
            // c - column being sorted
            // rowX - row object for row1
            // rowY - row object for row2
            const x1 = JSON.stringify(x);
            const y1 = JSON.stringify(y);
            // eslint-disable-next-line
            return x1 < y1 ? -1 : x1 > y1 ? 1 : 0;
          };
        }

        if (col.type && col.type === 'relation') {
          col.sortFn = (x, y, c, rowX, rowY) =>
            // x - row1 value for column
            // y - row2 value for column
            // col - column being sorted
            // rowX - row object for row1
            // rowY - row object for row2
            // eslint-disable-next-line
            (x < y ? -1 : x > y ? 1 : 0);
        }

        let filterDropdownItems = col.filterOptions && col.filterOptions.filterDropdownItems;
        if (col.type && (col.type === 'list-of-value' || col.type === 'lov')) {
          filterDropdownItems = this.$store.state.listOfValues[col.listName];
          if (filterDropdownItems) {
            filterDropdownItems = filterDropdownItems.map((e) => ({
              value: e.code,
              text: e.label || e.code || e,
            }));
          }
        }

        if (col.type && col.type === 'list-of-data') {
          filterDropdownItems = this.$store.state.data[col.listName];
          filterDropdownItems = filterDropdownItems.map((e) => ({
            value: e.code,
            text: e.label || e.code || e,
          }));
        }

        if (col.enum) {
          filterDropdownItems = col.enum;
          filterDropdownItems = filterDropdownItems.map((e) => ({
            value: e,
            text: _.startCase(e),
          }));
        }

        col.filterOptions = { enabled: this.filterable, filterDropdownItems };
        return col;
      });
      const isInitialLoad = Object.keys(this.columnsState).length < 1;
      if (isInitialLoad && newcolumns.length > this.columnsDisplayed) {
        newcolumns.forEach((col, idx) => {
          this.$set(this.columnsState, col.field, idx < this.columnsDisplayed);
        });
      }
      if (!newcolumns.find((col) => col.field === 'ACTIONS') && !this.opts.noActions) {
        newcolumns.push({
          field: 'ACTIONS',
          label: 'Actions',
          filterOptions: { enabled: false },
        });
        // eslint-disable-next-line
        this.columnsState.ACTIONS = true;
      }
      return newcolumns;
    },

    canHideColumns() {
      return this.formattedColumns.length > this.columnsDisplayed;
    },
    displayedColumns() {
      if (this.canHideColumns) {
        const cols = this.formattedColumns.filter((col) => this.columnsState[col.field]);

        if (!this.columnsState.ACTIONS) {
          const actions = this.formattedColumns.find((col) => col.field === 'ACTIONS');
          if (actions) {
            cols.push(actions);
          }
        }
        return cols;
      }
      return this.formattedColumns;
    },
  },
  mounted() {
    if (this.refresh && this.store) {
      return;
    }
    this.refreshTableData();
  },
  beforeDestroy() {
    EventBus.$off(`${this.entity}:created`, this.getItems.bind(this));
    EventBus.$off(`${this.entity}:updated`, this.getItems.bind(this));
  },
  watch: {
    tableNeedsRefresh: 'refreshTableData',
    params() {
      this.refreshTableData();
    },
    entity: 'entityChanged',
    store: (changed) => {},
    rows: 'refreshTableData',
  },
  methods: {
    refreshTableData(changed) {
      if (changed === false) {
        return;
      }
      if (this.url) {
        this.data = [];

        let urlParams = {};
        if (this.url.indexOf('?') > -1) {
          urlParams = qs.parse(this.url.split('?').pop());
        }

        this.serverParams = _.merge({}, this.serverParams, this.params, urlParams);
        this.getItems();
      } else {
        this.data = this.rows;
        this.$forceUpdate();
      }
      this.tableRefreshCompleted();
    },

    entityChanged() {
      this.data = this.url ? [] : this.rows;
      this.serverParams = {};
      this.refreshTableData();
    },

    tableRefreshCompleted() {
      setTimeout(() => {
        this.$emit('update:tableNeedsRefresh', false);
        this.$emit('afterRefresh');
      }, 50);
    },

    /** GET ENTITY ITEMS */
    getItems() {
      // if i got a refresh function
      this.$emit('refresh');
      if (this.refresh) {
        this.refresh();
        return;
      }

      if (!this.url) {
        return;
      }

      let url = typeof this.url === 'function' ? this.url() : this.url;

      if (url.indexOf('?') > -1) {
        url = url.split('?').shift();
      }

      // ?${qs.stringify(this.serverParams, {})}
      this.$http
        .get(`${url}?${qs.stringify(this.serverParams, {})}`, {})
        .then((res) => {
          this.data = res.data.body;
          this.totalCount = res.data.totalCount;
        })
        .catch((err) => console.error(err));
    },

    deleteItem(item) {
      this.$emit('delete', item);
    },

    toggleFilter() {
      this.filterable = !this.filterable;

      if (!this.filterable) {
        this.serverParams.range = {};
        this.serverParams.filters = {};
        this.getItems();
      }
      this.columns = this.columns.map((col) => {
        if (col.filterOptions) {
          col.filterOptions.enabled = this.filterable;
        }
        return col;
      });
    },

    editItem(item) {},

    clickOnLine(item) {
      if (this.opts && this.opts.actions && this.opts.actions.view) {
        this.$emit('view', item);
      }
    },

    getLovValue(item, listName, params) {
      if (!item || !this.$store.state.listOfValues[listName]) {
        return item;
      }
      const value = this.$store.state.listOfValues[listName].find((elm) => elm._id === item || elm.code === item);
      if (!value) {
        return item;
      }

      return value.label || value.code || value;
    },

    getDataValue(item, listName, params) {
      if (!item || !this.$store.state.data[listName]) {
        return item;
      }
      const value = this.$store.state.data[listName].find((elm) => elm._id === item || elm.code === item);
      if (!value) {
        return item;
      }

      return value.label || value.title || value.name || value.code || value;
    },

    toggleColumn(colName) {
      this.$set(this.columnsState, colName, !this.columnsState[colName]);
    },
    updateParams(newProps) {
      this.serverParams = {
        ...this.params,
        ...this.serverParams,
        ...newProps,
      };
    },

    // sort functions for unkown types
    sortFn(x, y, col, rowX, rowY) {
      // x - row1 value for column
      // y - row2 value for column
      // col - column being sorted
      // rowX - row object for row1
      // rowY - row object for row2
      // eslint-disable-next-line
      return x < y ? -1 : x > y ? 1 : 0;
    },

    onPageChange(params) {
      if (this.mode !== 'remote') {
        return;
      }
      this.updateParams({ page: params.currentPage - 1 });
      this.getItems();
    },

    onPerPageChange(params) {
      if (this.mode !== 'remote') {
        return;
      }
      this.updateParams({ perPage: params.currentPerPage });
      this.getItems();
    },

    onSortChange(params) {
      if (this.mode !== 'remote') {
        return;
      }
      const sort = {};
      sort[this.columns[params.columnIndex]] = params.sortType;
      this.updateParams({ sort });
      this.getItems();
    },

    onColumnFilter(params) {
      if (this.mode !== 'remote') {
        return;
      }
      this.updateParams({ filters: _.cloneDeep(params.columnFilters) });
      this.getItems();
    },

    onDateFilter(value) {
      if (!value) {
        return;
      }
      this.serverParams.range.startDate = value.startDate.toISOString().slice(0, 10);
      this.serverParams.range.endDate = value.endDate.toISOString().slice(0, 10);
      this.getItems();
    },

    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    exportCallBack() {
      this.$http
        .get(this.exportUrl || `/crud/${this.entity}/export`, {})
        .then((res) => {
          if (res.data.url) {
            const link = document.createElement('a');
            link.download = `${this.entity}_export`;
            link.href = res.data.url;
            link.click();
            link.remove();
          }
        })
        .catch(this.apiErrorCallback);
    },
  },
};
</script>
<style lang='scss'>
.ajax-table-img {
  max-height: 50px;
}

.text-avoid-overflow {
  max-width: 30vw;
}

.vgt-date-range {
  height: 32px;

  .form-control {
    padding: 6px 12px;
    height: 32px;
    font-size: 12px;
  }
}

.daterangepicker.dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.ajax-table-header.card-header.colored-header {
  color: white;

  * {
    color: white;
  }
}

.ajax-table-collapse {
  line-height: 1;
}
.ajax-table-collapse p {
  margin-bottom: 5px;
}

.ajax-table-collapse p.collapse:not(.show) {
  height: 26px !important;
  overflow: hidden;

  display: block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ajax-table-collapse p.show {
  height: auto;
}

.ajax-table-collapse a.collapsed:after {
  content: '...+';
}

.ajax-table-collapse a:not(.collapsed):after {
  content: '--';
}
</style>
