<template>
  <component :is="tag" @click="hideSidebar" v-bind="$attrs" tag="li">
    <a class="nav-link" :href="href">
      <slot>
        <i v-if="link.icon" :class="link.icon"></i>
        <p>{{ link.name }}</p>
      </slot>
    </a>
  </component>
</template>
<script>
export default {
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
  },
  props: {
    href: [String],
    link: {
      type: [String, Object],
      default: () => ({
        name: '',
        path: '',
        icon: '',
      }),
    },
    tag: {
      type: String,
      default: 'router-link',
    },
  },
  methods: {
    hideSidebar() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style></style>
