<template>
  <div class="content">
    <div class="container-fluid">
      <div v-if="!($store.state.projects && $store.state.projects.currentProject)">{{ $t('app.labels.notFound') }}</div>
      <div v-if="$store.state.projects && $store.state.projects.currentProject">
        <h2 class="text-primary">
          ({{ $store.state.projects.currentProject.code }}) - {{ $store.state.projects.currentProject.name }}
        </h2>
        <div class="block row">
          <div class="col-12">
            <div class="row">
              <div class="filter-box col-md-4">
                <div class="card card-primary card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">{{ projectEndDate }}</h3>
                    <h6 class="text-white">{{ $t('app.projects.deliveryDate') }}</h6>
                  </div>
                </div>
              </div>
              <div class="filter-box col-md-6">
                <div class="card card-warning card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">{{ projectStartDate }} - {{ projectEndDate }}</h3>
                    <h6 class="text-white">{{ $t('app.projects.duration') }}</h6>
                  </div>
                </div>
              </div>
              <div class="filter-box col-md-2">
                <div
                  class="card card-statistics"
                  :class="{
                    'card-success': $store.state.projects.currentProject.completed,
                    'card-danger': !$store.state.projects.currentProject.completed,
                  }"
                >
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">
                      {{
                        $store.state.projects.currentProject.completed
                          ? $t('app.projects.completed')
                          : $t('app.projects.ongoing')
                      }}
                    </h3>
                    <h6 class="text-white">{{ $t('app.projects.status') }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="filter-box col-md-3 cl-xs-6">
                <div class="card card-info card-statistics">
                  <a :href="$store.state.projects.currentProject.trelloBoard" target="_blank">
                    <div class="box text-center">
                      <h3 class="font-light text-white mt-1 text-nowrap text-uppercase">
                        {{ $t('common.labels.trello') }} {{ $t('common.labels.board') }}
                      </h3>
                      <h6 class="text-white">
                        {{
                          !$store.state.projects.currentProject.trelloBoard
                            ? $t('common.labels.board') + $t('common.labels.missing')
                            : 'xx ' + $t('app.projects.cards')
                        }}
                        <i class="fa fa-external-link"></i>
                      </h6>
                    </div>
                  </a>
                </div>
              </div>
              <div class="filter-box col-md-3 cl-xs-6" v-if="userHasRole('INTERNAL')">
                <div class="card card-purple card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">{{ projectBudget || '-' }}</h3>
                    <h6 class="text-white">{{ $t('app.projects.value') }}</h6>
                  </div>
                </div>
              </div>
              <div class="filter-box col-md-3 cl-xs-6" v-if="userHasRole('INTERNAL')">
                <div class="card card-cost card-statistics">
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">{{ projectCost || '-' }}</h3>
                    <h6 class="text-white">{{ $t('app.projects.cost') }}</h6>
                  </div>
                </div>
              </div>
              <div class="filter-box col-md-3 cl-xs-6" v-if="userHasRole('INTERNAL')">
                <div
                  class="card card-success card-statistics"
                  :class="{ 'card-success': projectProfit > 0, 'card-danger': projectProfit < 1 }"
                >
                  <div class="box text-center">
                    <h3 class="font-light text-white mt-1">{{ projectProfit }}</h3>
                    <h6 class="text-white">
                      {{ projectProfit > 0 ? $t('app.projects.gain') : $t('app.projects.loss') }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <AjaxTable
              :columns="['title', 'date', 'completed']"
              entity="milestone"
              url="/crud/milestone"
              @view="
                (item) => {
                  goToView('milestone-view', item._id);
                }
              "
              :options="{ actions: { delete: true, search: false, filter: false }, pagination: false }"
              :params="{
                filters: { projectId: $store.state.projects.currentProject._id },
                sort: { date: 'DESC' },
                perPage: 10,
              }"
            />
          </div>
          <div class="col-md-6">
            <AjaxTable
              :columns="['title', 'date']"
              entity="report"
              url="/crud/report"
              @view="
                (item) => {
                  goToView('report-view', item._id);
                }
              "
              :options="{ actions: { delete: true, search: false, filter: false }, pagination: false }"
              :params="{
                filters: { projectId: $store.state.projects.currentProject._id },
                sort: { date: 'DESC' },
                perPage: 10,
              }"
            />
          </div>
        </div>
        <!-- Graph -->
        <div class="row">
          <div class="col-md-6" style="max-height: 500px">
            <Card style="height: 365px">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="mt-2">{{ $t('app.projects.time_spent') }}</h4>
                </div>
                <div class="col-md-6 pt-2">
                  <chartist ratio="ct-minor-second" type="Pie" :data="chart.data" :options="chart.options"></chartist>
                </div>
                <div class="col-md-6 pt-3">
                  <div v-for="x in chart.captions" class="chart-legend-row" :key="x.label">
                    <p class="d-inline-block">{{ x.label }}</p>
                    <div :style="'background-color:' + x.color" class="chart-legend-row-color d-inline-block"></div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <!-- Graph -->
        <!-- Trello -->
        <h4 class="text-primary">{{ $t('Trello') }}</h4>
        <div class="lists">
          <div class="row">
            <div class="col-md-3" v-for="list of trelloLists" v-bind:key="list.id">
              <div class="trello-list">
                <header>
                  {{ list.name }}
                  <span v-if="list.cards" class="badge badge-danger float-right mt-2">{{ list.cards.length }}</span>
                </header>
                <ul>
                  <li v-for="card of list.cards" v-bind:key="card.id">
                    <a :href="card.shortUrl" _target="blank">
                      <span class="text-dark">{{ card.name }}</span>
                      <div
                        class="list-extras clearfix"
                        v-if="(card.idMembers && card.idMembers.length > 0) || card.due"
                      >
                        <div class="badge" v-if="card.due" style="padding: 0">
                          <span>{{ card.due | formatDate }}</span>
                        </div>
                        <div class="list-card-members">
                          <div
                            class="member"
                            v-for="member of card.idMembers"
                            v-bind:key="member.id"
                            :title="
                              getMemberDetails(member, 'fullName') + ' (' + getMemberDetails(member, 'username') + ')'
                            "
                          >
                            <span class="member-initials">{{ getMemberDetails(member, 'initial') }}</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
                <footer></footer>
              </div>
            </div>
          </div>
        </div>
        <!-- Trello -->
      </div>
    </div>
  </div>
</template>
<script>
import AjaxTable from '@/components/UIComponents/AjaxTable.vue';
import Card from '@/components/UIComponents/Cards/Card.vue';
import apiErrors from '@/mixins/api-errors';
import { mapGetters } from 'vuex';
import EventBus from '@/stores/event-bus';
import rolesMixin from '@/mixins/rolesMixin';
import swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';

window.EventBus = EventBus;
export default {
  name: 'ProjectDetails',
  components: {
    Card,
    AjaxTable,
  },
  mixins: [apiErrors, rolesMixin],
  data() {
    return {
      gantt: false,
      $createTaskModal: false,
      newTask: {},
      projectsList: [],
      boardMembers: [],
      clickedTask: false,
      isLoadingProject: false,
      trelloLists: [],
      graphNeedsRefresh: false,
      chart: {
        data: {
          users: [],
          labels: [],
          // series: []
          series: [],
        },
        options: {
          // low: 0,
          // high: 500,
          // showArea: false,
          height: '230px',
          donut: true,
          // axisX: {
          //   showGrid: false
          // },
          // lineSmooth: true,
          // showLine: true,
          // showPoint: true,
          // fullWidth: true,
          // chartPadding: {
          //   right: 50
          // }
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
        captions: [],
      },
      emptyTask: {
        id: '',
        name: '',
        start: '',
        end: '',
        progress: 100,
        dependencies: [],
      },
      filters: {
        resourceId: undefined,
        projectId: undefined,
      },
      project: null,
      resources: [],
      taskTemplate: [
        '_id',
        'name',
        'start',
        'end',
        'color',
        'confirmed',
        'projectId',
        'resourceId',
        'progress',
        'dependencies',
        'budget',
        'spent',
        'trelloBoard',
        'airtableLink',
        'metadata',
      ],
      tasks: [
        {
          id: '1',
          name: 'NO TASKS',
          start: '2018-10-28',
          end: '2018-10-28',
          progress: 0,
          dependencies: [],
        },
      ],
      colors: {
        'bg-primary': 'Navy blue',
        'bg-success': 'Green',
        'bg-warning': 'Orange',
        'bg-danger': 'Red',
        'bg-default': 'Violet',
        'bg-info': 'Light Blue',
        'bg-secondary': 'Secondary',
        'bg-black': 'Black',
        'bg-white': 'White',
      },
    };
  },
  created() {
    this.getProject();
    this.getResources();
    this.getItems();
    this.initChart();
  },
  mounted() {
    this.$createTaskModal = $('#createTaskModal');
  },

  beforeDestroy() {},

  computed: {
    ...mapGetters(['formattedTasks', 'getTasks']),
    projectEndDate() {
      return (
        (this.$store.state.projects.currentProject
          && moment(this.$store.state.projects.currentProject.endDate).format('DD MMMM YYYY'))
        || ''
      );
    },
    projectStartDate() {
      return (
        (this.$store.state.projects.currentProject
          && moment(this.$store.state.projects.currentProject.startDate).format('DD MMMM YYYY'))
        || ''
      );
    },
    projectDuration() {
      return moment(this.$store.state.projects.currentProject.endDate).diff(
        moment(this.$store.state.projects.currentProject.startDate),
        'days',
      );
    },
    projectProfit() {
      const cost = this.projectCost;
      const budget = this.projectBudget;
      const profit = budget - cost;
      return profit.toFixed(2);
    },
    projectCost() {
      return this.$store.state.projects.currentProject.cost
        ? this.$store.state.projects.currentProject.cost.toFixed(2)
        : 0;
    },
    projectBudget() {
      if (this.$store.state.projects.currentProject.contractValue) {
        return this.$store.state.projects.currentProject.contractValue.toFixed(2);
      }
      if (this.$store.state.projects.currentProject.budget) {
        return this.$store.state.projects.currentProject.budget.toFixed(2);
      }
      return 0;
    },
  },
  watch: {
    '$store.state.data.tasks': function (changed) {
      if (!this.gantt) {
        // this.startGantt();
        return;
      }
      if (changed.length) {
        this.gantt.refresh(this.formatTasks(changed));
      } else {
        this.gantt.refresh(this.formatTasks(this.tasks));
      }
    },
  },
  methods: {
    getProject() {
      return this.$store.dispatch('getProject', this.$route.params.id).then(() => {
        this.getCards();
        this.getBoardMembers();
      });
    },

    getResources() {
      this.$http
        .get('/crud/project_resources')
        .then((res) => {
          this.$store.commit('resources', res.data.body);
        })
        .catch((err) => console.error(err));
    },

    getItems(query) {
      if (!query) {
        this.clearFilter();
        query = {
          perPage: 100,
        };
      } else {
        query.perPage = 100;
      }

      this.$store
        .dispatch('getTasks', query)
        .then((res) => {
          if (this.gantt) {
            if (this.$store.state.data.tasks.length) {
              this.gantt.refresh(this.formatTasks(this.$store.state.data.tasks));
            } else {
              this.gantt.refresh(this.formatTasks(this.tasks));
            }
          }
        })
        .catch((err) => console.error(err));
    },

    formatTasks(tasks) {
      if (!tasks || !tasks.length) {
        return [];
      }
      return tasks.map((t) => {
        const task = _.cloneDeep(t);
        task.id = task._id;
        // eslint-disable-next-line
        task._start = moment(task.start);
        // eslint-disable-next-line
        task._end = moment(task.end);
        const resource = _.find(this.$store.state.data.resources, {
          _id: task.resourceId,
        });
        const project = _.find(this.$store.state.data.projects, {
          _id: task.projectId,
        });
        if (resource) {
          task.name += ` - ${resource.firstname} ${resource.lastname}`;
          task.resource = resource;
          t.resource = resource;
        }

        task.custom_class = t.confirmed ? 'bg-primary' : 'bg-secondary';
        if (project) {
          task.name += ` - ${project.code || ''}`;
          task.project = project;
          t.project = project;
          task.custom_class = t.confirmed ? project.color : task.custom_class;
        }
        return task;
      });
    },

    setColor($event) {
      const project = this.$store.state.data.projects.find((p) => this.newTask.projectId === p._id);
      if (project) {
        this.newTask.color = project.color || 'bg-primary';
      }
    },

    createOrSaveTask() {
      if (!this.newTask.name || !this.newTask.start || !this.newTask.end) {
        return;
      }
      if (this.newTask._id) {
        this.updateTask();
      } else {
        this.createTask();
      }
    },

    createTask() {
      if (!this.newTask.name || !this.newTask.start || !this.newTask.end) {
        return;
      }

      this.newTask.progress = 100;

      if (Array.isArray(this.newTask.dependencies)) {
        this.newTask.dependencies = this.newTask.dependencies.map((task) => task._id || task);
      }
      const task = _.pick(this.newTask, this.taskTemplate);
      this.$http
        .post('/crud/project_tasks', task)
        .then((res) => {
          EventBus.$emit('project_tasks:created', res.data);
          swal({
            title: `Task ${res.data.body.name} created`,
            type: 'success',
          });
          this.getItems();
          this.closeModal();
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            message: JSON.stringify(err),
          });
        });
      return false;
    },

    updateTask() {
      if (!this.newTask.name || !this.newTask.start || !this.newTask.end || !this.newTask._id) {
        return;
      }
      if (Array.isArray(this.newTask.dependencies)) {
        this.newTask.dependencies = this.newTask.dependencies.map((task) => task._id || task);
      }
      delete this.newTask.project_name;
      const task = _.pick(this.newTask, this.taskTemplate);
      this.$http
        .put(`/crud/project_tasks/${this.newTask._id}`, task)
        .then((res) => {
          EventBus.$emit('project_tasks:created', res.data);
          //  EventBus.$emit('project_tasks:updated', res.data)
          this.getItems();
          this.closeModal();
          this.$notify({
            message: 'Task updated',
            type: 'success',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            message: JSON.stringify(err),
          });
        });
      return false;
    },

    createProject() {
      swal
        .mixin({
          reverseButtons: true,
          input: 'text',
          confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          progressSteps: ['1', '2', '3', '4'],
        })
        .queue([
          {
            title: 'Project Name',
          },

          {
            title: 'Budget (in days)',
            input: 'number',
          },
          {
            title: 'Used budget (in days)',
            input: 'number',
          },
          {
            title: 'Color',
            input: 'select',
            inputOptions: this.colors,
          },
        ])
        .then((result) => {
          if (result.value) {
            this.$http
              .post('/project', {
                code: result.value[0],
                name: result.value[0],
                color: result.value[3],
                progress: 100,
                budget: result.value[1],
                spent: result.value[2],
              })
              .then((res) => {
                EventBus.$emit('project_projects:created', res.data);
                swal({
                  title: `Project ${result.value[0]} created`,
                  type: 'success',
                });
              })
              .catch((err) => {
                console.error(err);
                this.$notify({
                  message: JSON.stringify(err),
                });
              });
          }
        });
    },

    getCards() {
      if (!this.$store.state.projects.currentProject.trelloBoardId) {
        return;
      }

      this.$http
        .get(`/trello/board/${this.$store.state.projects.currentProject.trelloBoardId}/list`)
        .then((result) => {
          if (result.data && result.data.body) {
            this.trelloLists = result.data.body.filter(
              (l) => ['TO TEST', 'TODO', 'DOING', 'WAITING'].indexOf(l.name.toUpperCase()) > -1,
            );
          }
        })
        .catch(this.apiErrors);
    },

    getBoardMembers() {
      if (!this.$store.state.projects.currentProject.trelloBoardId) {
        return;
      }

      this.$http
        .get(`/trello/board/${this.$store.state.projects.currentProject.trelloBoardId}/member`)
        .then((result) => {
          if (result.data && result.data.body) {
            this.boardMembers = result.data.body;
          }
        })
        .catch(this.apiErrors);
    },

    getMemberDetails(memberId, field) {
      let value = memberId;
      this.boardMembers.forEach((member) => {
        if (member.id === memberId) {
          if (field === 'initial') {
            if (member[field]) {
              value = member[field];
            } else if (member.fullName) {
              value = member.fullName.match(/\b(\w)/g).join('');
            }
          } else {
            value = member[field];
          }
        }
      });
      return value;
    },

    goToView(name, id) {
      return this.$router.push({
        name,
        params: {
          id,
        },
      });
    },

    closeModal() {
      this.newTask = _.cloneDeep(this.emptyTask);
      this.$createTaskModal.modal('hide');
    },

    filter() {
      if (!this.filters.projectId) {
        this.filters.projectId = undefined;
      }

      if (!this.filters.resourceId) {
        this.filters.resourceId = undefined;
      }

      this.getItems({
        query: this.filters,
      });
    },

    clearFilter() {
      this.filters.projectId = undefined;
      this.filters.resourceId = undefined;
    },

    initChart() {
      const that = this;
      this.$http
        .get(`/project/${this.$route.params.id}/time_spent_by_user`)
        .then((res) => {
          const formatted = [];
          res.data.body.forEach((x) => {
            let user;
            x.tasks.forEach((task) => {
              user = formatted.find((o) => x.user === o.user);
              if (user) {
                user.time = parseInt(task.time || 0) + user.time;
              } else {
                formatted.push({
                  user: x.user,
                  time: parseInt(task.time || 0),
                });
              }
            });
          });
          formatted.forEach((x, key) => {
            if (x.time > 0) {
              that.chart.data.labels.push(`${x.time}h`);
              that.chart.data.series.push(x.time);
              that.chart.data.users.push(x.user);
            }
            if (key === formatted.length - 1) {
              setTimeout(() => {
                that.refreshChartCaptions();
              }, 300);
            }
          });
        })
        .catch((err) => console.error(err));
    },
    refreshChartCaptions() {
      const slices = document.getElementsByClassName('ct-slice-donut');
      const legends = [];

      [].forEach.call(slices, (x, key) => {
        legends.push({
          label: this.chart.data.users[key],
          color: window.getComputedStyle(slices[key], null).stroke,
        });
      });

      if (this.chart.data.users.length > 1) {
        const labels = document.getElementsByClassName('ct-label');
        [].forEach.call(labels, (x, key) => {
          x.style.fill = 'white';
        });
      }
      this.chart.captions = legends;
    },
  },
};
</script>
<style lang="scss">
.gantt-details-container {
  width: 300px;
  padding: 10px;
  color: #333;
  text-align: left;
}

.bg-primary .bar-progress {
  fill: #3472f7 !important;
}

.bg-info .bar-progress {
  fill: #1dc7ea !important;
}

.bg-success .bar-progress {
  fill: #87cb16 !important;
}

.ct-label {
  fill: black !important;
}

.chart-legend-row {
  p {
    text-transform: uppercase;
    font-size: 15px;
  }
  &-color {
    height: 20px;
    width: 20px;
    float: right;
  }
}
.card-cost {
  background-color: #fbd130 !important;
}
</style>
