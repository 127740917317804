<template>
  <div class="content trello-content">
    <div class="container-fluid">
      <div class="card" v-if="report">
        <div class="card-body" style="min-height: calc(100vh - 100px)">
          <div class="card-title p">
            <h2>
              {{ report.title }}
            </h2>
          </div>
          <div class="row">
            <div class="form-group valid required col-md-12 field-EnyoSelect" v-if="report">
              <label for>
                <span>{{ $t('app.labels.project') }}</span>
              </label>
              <EnyoSelect
                style="width: 100%"
                v-model="report.projectId"
                url="/project"
                :options="[]"
                label="label"
                track-by="_id"
                :multiple="false"
                :searchable="true"
                :disabled="true"
              ></EnyoSelect>
            </div>
            <div class="form-group col-md-12">
              <hr />
              <div v-html="report.content"></div>
              <hr />
            </div>

            <div class="form-group col-md-6">
              <div>
                Date <b>{{ formatDate(report.date) }}</b>
              </div>
            </div>
            <div class="form-group col-md-6">
              <div>
                Created by : <b>{{ projectCreator }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiErrors from '@/mixins/api-errors';
import moment from 'moment';

export default {
  name: 'ReportViewComponent',
  props: {
    name: String,
    modelName: String,
    model: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    options: {
      default: () => ({
        mode: 'local',
        actions: {
          create: true,
          edit: true,
          view: true,
          delete: true,
          import: false,
          export: false,
        },
        modalMode: 'fullpage',
        columns: [],
      }),
    },
  },
  components: {},
  computed: {
    projectCreator() {
      const user = this.report
        && this.$store.state.listOfValues.users
        && this.$store.state.listOfValues.users.find((u) => u._id === this.report.createdBy);
      return user ? user.label : this.report._id;
    },
  },
  data() {
    return {
      crudNeedsRefresh: false,
      absence: {},
      report: null,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
      },
    };
  },
  mixins: [apiErrors],
  created() {},
  mounted() {
    this.getItem();
  },
  methods: {
    async getItem() {
      const { data } = await this.$http.get(`/report/${this.$route.params.id}`);
      this.report = data.body;
    },
    formatDate(date) {
      return moment(date).format('LLL');
    },
  },
};
</script>
<style></style>
