import _ from 'lodash';
import qs from 'qs';
import Vuex from 'vuex';
import Vue from 'vue';
import VuexPersistence from 'vuex-persist';
import config from '@/config';
import localeFr from '@/config/locales/fr.json';
import localeEn from '@/config/locales/en.json';
import listOfValues from './listOfValues';
import projectsStore from './projects';

Vue.use(Vuex);

const token = localStorage.getItem(`${config.appName}_token`);
let models;
let user;
let roles;
try {
  user = localStorage.getItem(`${config.appName}_user`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_user`)) : '';

  models = localStorage.getItem(`${config.appName}_models`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_models`)) : [];

  roles = localStorage.getItem(`${config.appName}_roles`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_roles`)) : [];
} catch (err) {
  console.error(err);
}

function getExtendedRoles(role, state) {
  let myRoles = [];
  if (state.roles && state.roles[role]
    && state.roles[role].inherits
    && Array.isArray(state.roles[role].inherits)) {
    myRoles = myRoles.concat(state.roles[role].inherits);
    state.roles[role].inherits.forEach((r) => {
      myRoles = myRoles.concat(getExtendedRoles(r, state));
    });
  }
  return _.uniq(myRoles);
}

const vuexLocal = new VuexPersistence({
  key: `vuex_${config.appName}`,
  storage: window.localStorage,
});

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    listOfValues,
    projects: projectsStore,
  },
  state: {
    user,
    token,
    io: null,
    translations: {
      fr: localeFr,
      en: localeEn,
    },
    locale: user.locale || localStorage.getItem(`${config.appName}_locale`) || config.defaultLocale,
    roles,
    userExtendedRoles: [],
    data: {
      resources: [],
      tasks: [],
      models,
    },
    trelloCardCount: localStorage.getItem(`${config.appName}_trelloCardCount`) || '',
    trelloOrgMembers: JSON.parse(localStorage.getItem(`${config.appName}_trelloOrgMembers`)) || [],
    trelloMemberBoards: JSON.parse(localStorage.getItem(`${config.appName}_trelloMemberBoards`)) || [],
    trelloMemberCards: JSON.parse(localStorage.getItem(`${config.appName}_trelloMemberCards`)) || [],
    filteredDailyStandups: [],
    bookmarks: [],
  },
  mutations: {
    projects(state, projects) {
      state.data.projects = projects;
    },

    resources(state, resources) {
      state.data.resources = resources;
    },

    tasks(state, tasks) {
      state.data.tasks = tasks;
    },

    roles(state, newRoles) {
      state.roles = newRoles;
      localStorage.setItem(`${config.appName}_roles`, JSON.stringify(newRoles));
    },

    extendedRoles(state) {
      let newRoles = state.user.roles || [];
      newRoles.forEach((role) => {
        newRoles = newRoles.concat(getExtendedRoles(role, state));
      });
      state.userExtendedRoles = newRoles;
    },

    io(state, io) {
      const { onevent } = io.socket;

      const t = localStorage.getItem(`${config.appName}_token`);
      io.sails.headers = {
        authorization: `Bearer ${t}`,
      };
      // eslint-disable-next-line
      io.socket.onevent = function (packet) {
        const args = packet.data || [];
        onevent.call(this, packet); // original call
        packet.data = ['*'].concat(args);
        onevent.call(this, packet); // additional call to catch-all
      };
      state.io = io;
      io.socket.on('*', (event, data) => {
        //   console.log('SOCKET EVENT', event);
        //   console.log('SOCKET EVENT DATA', data);
      });
    },

    models(state, m) {
      state.data.models = m;
      localStorage.setItem(`${config.appName}_models`, JSON.stringify(m));
    },

    user(state, newUser) {
      state.user = newUser;
      state.locale = newUser.locale;
      localStorage.setItem(`${config.appName}_user`, JSON.stringify(user));
    },

    currentLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem(`${config.appName}_locale`, locale);
    },

    token(state, newToken) {
      this._vm.$http.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      this._vm.$http.defaults.headers.Authorization = `Bearer ${newToken}`;
      state.token = newToken;
      localStorage.setItem(`${config.appName}_token`, newToken);
      if (this.io) {
        this.io.sails.headers = {
          authorization: `Bearer ${newToken}`,
        };
      }
    },

    commitData(state, key, value) {
      state.data[key] = value;
    },

    trelloCardCount(state, value) {
      state.trelloCardCount = value;
      localStorage.setItem(`${config.appName}_trelloCardCount`, value);
    },

    trelloOrgMembers(state, value) {
      state.trelloOrgMembers = value;
      localStorage.setItem(`${config.appName}_trelloOrgMembers`, JSON.stringify(value));
    },

    trelloMemberBoards(state, value) {
      state.trelloMemberBoards = value;
      localStorage.setItem(`${config.appName}_trelloMemberBoards`, JSON.stringify(value));
    },

    trelloMemberCards(state, value) {
      state.trelloMemberCards = value;
      localStorage.setItem(`${config.appName}_trelloMemberCards`, JSON.stringify(value));
    },
    filteredDailyStandups(state, value) {
      state.filteredDailyStandups = value;
    },
    bookmarks(state, value) {
      state.bookmarks = value;
    },
  },
  getters: {},
  actions: {
    logout({
      commit,
    }) {
      delete App.user;
      commit('user', '');
      commit('token', '');
      commit('models', []);
      return true;
    },

    user({
      commit,
    }, u) {
      commit('user', u);
      commit('extendedRoles');
      return true;
    },

    refreshUser({
      commit,
    }) {
      const q = this._vm.$http.get('/auth/user');
      q.then((res) => {
        App.user = res.data.user;
        localStorage.setItem(`${config.appName}_user`, JSON.stringify(res.data.user));
        commit('user', res.data.user);
        commit('extendedRoles');
      }).catch((err) => {
        console.warn(err);
        this._vm.$notify({
          title: err.response ? err.response.data : err,
          type: 'warning',
        });
      });
      return q;
    },

    getModels({
      commit,
    }) {
      const promise = this._vm.$http.get('/models');
      promise
        .then((res) => {
          const m = res.data.body.map((model) => {
            model.title = _.startCase(model.name);
            return model;
          });
          commit('models', m);
        })
        .catch((err) => {
          console.error(err);
        });
      return promise;
    },

    getTasks({
      commit,
      state,
    }, query) {
      query = query || {};
      this._vm.$http
        .get('/crud/project_tasks', {
          params: query,
          paramsSerializer(params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          },
        })
        .then((res) => {
          const tasks = res.data.body.map((task) => {
            const project = state.data.projects && state.data.projects.find(p => task.projectId === p._id);
            task.project_name = project ? project.code || project.name : 'NOT FOUND';
            return task;
          });

          commit('tasks', tasks);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getTranslations(context) {
      const p = this._vm.$http
        .get(`/api/translations/${context.state.locale}`);

      p.then((res) => {
        context.commit('translations', res.data);
      })
        .catch((err) => {
          console.warn(err);
        });

      return p;
    },

    changeLocale(context, locale) {
      context.commit('currentLocale', locale);
      // context.dispatch('getTranslations');
    },

    init(context) {
      //  return context.dispatch('getTranslations');
    },
  },
});

export default store;
