import FieldIntranetSelect from '@/components/UIComponents/FieldIntranetSelect.vue';
import fieldKeyValueObject from '@/components/UIComponents/FieldKeyValueObject.vue';
import EnyoSelect from '@/components/UIComponents/EnyoSelect.vue';
import { Datetime } from 'vue-datetime';
import fgInput from './components/UIComponents/Inputs/formGroupInput.vue';
import UploadButton from './components/UIComponents/Inputs/UploadButton.vue';
import DropDown from './components/UIComponents/Dropdown.vue';
import AjaxTable from './components/UIComponents/AjaxTable.vue';
import Stats from './components/UIComponents/Stats.vue';
import fieldDateTime from './components/UIComponents/fieldDateTime.vue';
import fieldTinyMce from './components/UIComponents/FieldTinyMce.vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('fg-input', fgInput);
    Vue.component('upload-button', UploadButton);
    Vue.component('drop-down', DropDown);
    Vue.component('AjaxTable', AjaxTable);
    Vue.component('stats', Stats);
    Vue.component('fieldDateTime', fieldDateTime);
    Vue.component('datetime', Datetime);
    Vue.component('fieldIntranetSelect', FieldIntranetSelect);
    Vue.component('fieldKeyValueObject', fieldKeyValueObject);
    Vue.component('fieldTinyMce', fieldTinyMce);
    Vue.component('EnyoSelect', EnyoSelect);
  },
};

export default GlobalComponents;
