<template>
  <crud-component v-bind="$props" primaryKey="_id" :crudNeedsRefresh="crudNeedsRefresh">
    <template slot="create-form" slot-scope="{ selectedItem }">
      <VueFormGenerator
        ref="form"
        :schema.sync="formSchema"
        :model="selectedItem"
        :options="formOptions"
      ></VueFormGenerator>
    </template>
    <template slot="edit-form" slot-scope="{ selectedItem }">
      <div class="form-group valid required col-md-12 field-EnyoSelect">
        <label for="applabelsremotedayreason"><span>Resource</span></label>
        <EnyoSelect
          v-model="selectedItem.resourceId"
          url="/user"
          :options="[]"
          label="label"
          track-by="_id"
          :multiple="false"
        ></EnyoSelect>
      </div>
      <VueFormGenerator
        ref="form"
        :schema.sync="formSchema"
        :model="selectedItem"
        :options="formOptions"
      ></VueFormGenerator>
    </template>
  </crud-component>
</template>
<script>
import apiErrors from '@/mixins/api-errors';

export default {
  props: {
    name: String,
    modelName: String,
    model: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    options: {
      default: () => ({
        mode: 'local',
        actions: {
          create: true,
          edit: true,
          view: true,
          delete: true,
          import: false,
          export: false,
        },
        columns: [],
      }),
    },
  },
  data() {
    return {
      modelDate: new Date().toISOString(),
      crudNeedsRefresh: false,
      absence: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
      },
      formSchema: {
        fields: [
          {
            type: 'vueMultiSelect',
            inputType: 'text',
            label: 'Status',
            fieldOptions: {
              multiple: false,
              enum: ['requested', 'validated', 'refused'],
            },
            values: ['requested', 'validated', 'refused'],
            model: 'status',
            multi: false,
            styleClasses: 'col-md-12',
          },
          {
            type: 'dateTime',
            inputType: 'date',
            label: 'Date',
            fieldOptions: {
              allowInputToggle: true,
              type: 'date',
              icons: {
                time: 'fa fa-clock',
                date: 'fa fa-calendar',
                up: 'fa fa-arrow-up',
                down: 'fa fa-arrow-down',
              },
            },
            required: true,
            model: 'date',
            validator: ['string'],
            multi: false,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Created On',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            model: 'createdOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Updated On',
            fieldOptions: {
              trackBy: 'code',
              label: 'label',
            },
            model: 'lastModifiedOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
        ],
      },
    };
  },
  mixins: [apiErrors],
  created() {},
  mounted() {
    this.$modal = $('#goodieModal');
  },
  methods: {},
};
</script>
<style></style>
