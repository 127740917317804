<template>
  <table class="table">
    <thead>
      <tr>
        <slot name="columns">
          <th v-for="(column, index) in columns" v-bind:key="index">{{ headerValue(column) }}</th>
        </slot>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" v-bind:key="index">
        <slot :row="item">
          <td
            v-for="(column, index) in columns"
            v-bind:key="index"
            v-if="hasValue(item, column)"
          >{{ itemValue(item, column) }}</td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'l-table',
  props: {
    columns: Array,
    data: Array,
  },
  methods: {
    hasValue(item, column) {
      if (typeof column === 'object') {
        if (column.field) {
          return item[column.field];
        }
      }
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      if (typeof column === 'object') {
        if (column.field) {
          return item[column.field];
        }
      }
      return item[column.toLowerCase()];
    },
    headerValue(column) {
      if (typeof column === 'object') {
        if (column.label) {
          return column.label;
        }
      }
      return column;
    },
  },
};
</script>
<style>
</style>
