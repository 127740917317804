<template>
  <div class="content gantt-main-container">
    <div class="container-fluid">
      <div class="block row">
        <div class="form-group col-md-3">
          <select class="form-control" id="exampleFormControlSelect1" @change="filter()" v-model="filters.projectId">
            <option :value="undefined" disabled>{{ $t('app.labels.projects') }}</option>
            <option value="">-</option>
            <option v-for="p in $store.state.data.projects" v-bind:value="p._id" :key="p._id">
              {{ p.code }} - {{ p.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <select class="form-control" id="exampleFormControlSelect1" @change="filter()" v-model="filters.resourceId">
            <option :value="undefined" disabled>{{ $t('app.labels.project_resources') }}</option>
            <option value="">-</option>
            <option :key="p._id" v-for="p in $store.state.data.resources" v-bind:value="p._id">
              {{ p.firstname }} {{ p.lastname }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2 col-xs-6">
          <button class="btn btn-simple btn-info form-control" @click="getItems()">
            <i class="fa fa-trash"></i>{{ $t('common.buttons.filter') }}
          </button>
        </div>
        <div class="form-group col-md-2 col-xs-6">
          <button class="btn btn-info form-control" @click="filter()">
            <i class="fa fa-filter"></i>{{ $t('common.buttons.clear') }}
          </button>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12 mb-5">
          <Card class="mb-3">
            <dx-scheduler
              :data-source="scheduler.tasks"
              :current-date="scheduler.currentDate"
              :views="scheduler.views"
              :height="580"
              :cell-duration="30"
              :start-day-hour="0"
              :end-day-hour="24"
              :groups="scheduler.groups"
              :first-day-of-week="0"
              current-view="timelineMonth"
              @appointmentDeleting="deleteTask"
              @appointmentFormOpening="editTask"
            >
              <dx-resource
                :allow-multiple="true"
                :data-source="scheduler.projects"
                :use-color-as-default="true"
                field-expr="project"
                label="Projects"
              />
              <dx-resource
                :allow-multiple="true"
                :data-source="scheduler.ressources"
                field-expr="ressources"
                label="Ressources"
              />
            </dx-scheduler>
          </Card>
          <button class="btn btn-simple pt-0" @click="openModal()">
            <i class="fa fa-plus"></i>{{ $t('common.labels.createNew') }} {{ $t('app.labels.project_task') }}
          </button>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="text-right">
            <button class="btn btn-secondary btn-simple" @click="createResource()">
              <i class="fa fa-plus"></i>{{ $t('common.labels.createNew') }} {{ $t('app.labels.project_resource') }}
            </button>
          </div>
          <Card>
            <AjaxTable
              :columns="['firstname', 'lastname']"
              @delete="deleteResource"
              entity="project_resources"
              url="/crud/project_resources"
              :title="$t('app.labels.project_resources')"
              @edit="editRessource"
              :options="{ actions: { delete: true, edit: true, view: false } }"
              :tableNeedsRefresh.sync="refreshTable.resources"
            />
          </Card>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="text-right">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle btn-simple"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-plus"></i>{{ $t('common.labels.createNew') }} ...
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button class="btn btn-info btn-simple hide hidden" @click="createProject()">
                  <i class="fa fa-plus"></i>{{ $t('common.labels.createNew') }} {{ $t('app.labels.project') }}
                </button>
                <button class="btn btn-success btn-simple" @click="importProject()">
                  <i class="fa fa-arrow-up"></i>{{ $t('common.buttons.import') }} {{ $t('app.labels.project') }}
                </button>
                <button class="btn btn-default btn-simple" @click="createAirtableProject()">
                  <i class="fa fa-plus"></i>{{ $t('app.projects.create_airtable') }}
                </button>
              </div>
            </div>
          </div>
          <Card>
            <AjaxTable
              :columns="['code', 'name', 'color', 'budget', 'spent']"
              entity="project"
              url="/project"
              :options="{ actions: { delete: true, edit: true, view: false } }"
              @delete="deleteProject"
              @edit="editProject"
              :tableNeedsRefresh.sync="refreshTable.projects"
            />
          </Card>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" id="createTaskModal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <form @submit.prevent="createOrSaveTask()">
            <div class="modal-header">
              <h3 class="modal-title">Create task</h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="projectTask">Project</label>
                <select class="form-control" id="projectTask" v-model="newTask.projectId" @change="setColor()">
                  <option v-bind:key="p._id" v-for="p in taskProjectsList" v-bind:value="p._id">{{ p.code }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="nameTask">Task name</label>
                <input type="text" class="form-control" id="nameTask" v-model="newTask.name" />
              </div>
              <div class="form-group">
                <label for="starDateTask">Start date</label>
                <input type="date" class="form-control" id="starDateTask" v-model="newTask.start" />
              </div>
              <div class="form-group">
                <label for="endDateTask">End date</label>
                <input type="date" class="form-control" id="endDateTask" v-model="newTask.end" />
              </div>
              <div class="form-group">
                <label for="ressourceTask">Resource</label>
                <select class="form-control" id="ressourceTask" v-model="newTask.resourceId">
                  <option v-bind:key="r._id" v-for="r in $store.state.data.resources" v-bind:value="r._id">
                    {{ r.firstname }} {{ r.lastname }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label>Dependencies</label>
                <multiselect
                  v-model="newTask.dependencies"
                  :options="$store.state.data.tasks"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :show-labels="false"
                  label="name"
                  track-by="_id"
                  placeholder="Select one or more tasks"
                ></multiselect>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <EditModal
      :selectedItem="editModal.item"
      v-show="editModal.show"
      @close="onCloseEditModal($event)"
      :name="editModal.name"
      :innerOptions="editModal.innerOptions"
    >
    </EditModal>
  </div>
</template>
<script>
import swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';
import { DxScheduler, DxResource } from 'devextreme-vue/scheduler';
import AjaxTable from '@/components/UIComponents/AjaxTable.vue';
import Card from '@/components/UIComponents/Cards/Card.vue';
import ProgressNotification from '@/components/UIComponents/ProgressNotification.vue';
import EditModal from '@/components/UIComponents/Modals/Edit.vue';
import EventBus from '@/stores/event-bus';

export default {
  name: 'ProjectsGantt',
  components: {
    Card,
    AjaxTable,
    DxScheduler,
    DxResource,
    EditModal,
  },
  data() {
    return {
      gantt: false,
      $createTaskModal: false,
      boards: [],
      lists: [],
      newTask: {},
      projectsList: [],
      clickedTask: false,
      emptyTask: {
        id: '',
        name: '',
        start: '',
        end: '',
        progress: 100,
        dependencies: [],
        board: '',
        list: '',
      },
      scheduler: {
        views: [
          {
            type: 'timelineMonth',
            groups: ['ressources'],
            maxAppointmentsPerCell: 'unlimited',
            appointmentTemplate(data) {
              return `<div class="text-center">${data.projectName} - ${data.text}</div>`;
            },
            dateCellTemplate(cellData, index, container) {
              const dayOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
              container.append(cellData.date.getDate());
              if (
                dayOfWeekNames[cellData.date.getDay()] === 'Sun'
                || dayOfWeekNames[cellData.date.getDay()] === 'Sat'
              ) {
                container.className += ' dx-scheduler-week-end';
              }
            },
            dataCellTemplate(cellData, index, container) {
              const dayOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
              if (
                dayOfWeekNames[cellData.startDate.getDay()] === 'Sun'
                || dayOfWeekNames[cellData.startDate.getDay()] === 'Sat'
              ) {
                container.className += ' dx-scheduler-week-end';
              }
            },
            appointmentTooltipTemplate(data, container) {
              const scheduler = this;
              const content = '<div id="task-tooltip" style="width: 150px; height: 35px;"> '
                + '<button class="btn btn-danger btn-sm mr-2 task-delete-btn" >Supprimer</button>'
                + '<button class="btn btn-dark btn-sm task-edit-btn">Editer</button>'
                + '</div>';
              const template = document.createElement('div');
              template.innerHTML = content.trim();
              template.querySelectorAll('.task-edit-btn')[0].addEventListener('click', () => {
                scheduler.showAppointmentPopup(data, false);
                scheduler.hideAppointmentTooltip();
              });
              template.querySelectorAll('.task-delete-btn')[0].addEventListener('click', () => {
                scheduler.deleteAppointment(data, false);
                scheduler.hideAppointmentTooltip();
              });
              return template;
            },
          },
        ],
        groups: ['ressources'],
        currentDate: new Date(),
        tasks: [],
        projects: [],
        ressources: [],
      },
      refreshTable: {
        projects: false,
        resources: false,
      },
      filters: {
        resourceId: undefined,
        projectId: undefined,
      },
      projects: [],
      resources: [],
      taskTemplate: [
        '_id',
        'name',
        'start',
        'end',
        'color',
        'confirmed',
        'projectId',
        'resourceId',
        'progress',
        'dependencies',
        'budget',
        'spent',
        'airtableLink',
        'metadata',
      ],
      tasks: [
        {
          id: '1',
          name: 'NO TASKS',
          start: moment(new Date()).format('YYYY-MM-DD'),
          progress: 0,
          dependencies: [],
        },
      ],
      colors: {
        'bg-primary': 'Navy blue',
        'bg-success': 'Green',
        'bg-warning': 'Orange',
        'bg-danger': 'Red',
        'bg-default': 'Violet',
        'bg-info': 'Light Blue',
        'bg-secondary': 'Grey',
        'bg-black': 'Black',
        'bg-brown': 'Brown',
        'bg-yellow': 'Yellow',
        'bg-pink': 'Pink',
      },
      parentPath: {},
      editModal: {
        item: this.$store.state.data.projects[0],
        show: false,
        name: 'project',
        innerOptions: { url: '/project' },
      },
    };
  },
  created() {
    this.getProjects();
    this.getResources();
    this.getItems();
  },
  mounted() {
    this.$createTaskModal = $('#createTaskModal');

    EventBus.$on('project_projects:created', this.getProjects.bind(this));
    EventBus.$on('project_projects:updated', this.getProjects.bind(this));

    EventBus.$on('project_resources:created', this.getResources.bind(this));
    EventBus.$on('project_resources:updated', this.getResources.bind(this));

    setTimeout(() => {
      this.refreshScheduler();
      document.getElementsByClassName('dx-scheduler')[0].style.display = 'block';
    }, 1000);
  },

  beforeDestroy() {
    EventBus.$off('project_projects:created', this.getProjects.bind(this));
    EventBus.$off('project_projects:updated', this.getProjects.bind(this));

    EventBus.$off('project_resources:created', this.getResources.bind(this));
    EventBus.$off('project_resources:updated', this.getResources.bind(this));
  },

  computed: {
    taskProjectsList() {
      const p = [];
      this.$store.state.data.projects.forEach((element) => {
        if (!element.completed) {
          element.code = `${element.code}`;
          p.push(element);
        }
      });
      return _.sortBy(p, 'code');
    },
  },
  watch: {
    '$store.state.data.tasks': function (changed) {
      if (changed.length) {
        this.formatTasks(changed);
        this.refreshScheduler();
      } else {
        this.formatTasks(this.tasks);
        this.refreshScheduler();
      }
    },
  },
  methods: {
    refreshScheduler() {
      const vm = this;
      vm.scheduler.ressources = [];
      vm.scheduler.projects = [];
      vm.scheduler.tasks = [];

      if (this.filters.resourceId) {
        this.$store.state.data.resources.forEach((resource) => {
          if (this.filters.resourceId === resource._id) {
            vm.scheduler.ressources.push({
              text: `${resource.firstname} ${resource.lastname}`,
              id: resource._id,
            });
          }
        });
      } else {
        this.$store.state.data.resources.forEach((resource) => {
          vm.scheduler.ressources.push({
            text: `${resource.firstname} ${resource.lastname}`,
            id: resource._id,
          });
        });
      }
      if (this.$store.state.data.projects) {
        this.$store.state.data.projects.forEach((project) => {
          vm.scheduler.projects.push({
            text: project.name,
            id: project._id,
            color: vm.handleColor(project.color),
          });
        });
        vm.scheduler.projects.push({
          text: 'Absence',
          id: 'absence',
          color: vm.handleColor({ id: 'bg-absence' }),
        });
      }
      if (this.$store.state.data.tasks) {
        this.$store.state.data.tasks.forEach((task) => {
          vm.scheduler.tasks.push({
            id: task._id,
            text: task.name,
            project: [task.projectId],
            projectName: task.project_name,
            startDate: new Date(task.start),
            endDate: new Date(task.end),
            ressources: task.resourceId,
          });
        });

        this.$http
          .get('/absence?planning=1')
          .then((res) => {
            res.data.body.forEach((item) => {
              const resource = vm.scheduler.ressources.find(
                (x) => x.text.toLowerCase().replace(/\s/g, '')
                  === `${item.User.firstname} ${item.User.lastname}`.toLowerCase().replace(/\s/g, ''),
              );
              if (!vm.scheduler.tasks.find((x) => x.id === item._id)) {
                vm.scheduler.tasks.push({
                  id: item._id,
                  text: item.reason,
                  project: ['absence'],
                  projectName: 'Absence',
                  startDate: new Date(item.fromDate),
                  endDate: new Date(item.toDate),
                  ressources: resource ? resource.id : item.resourceId,
                });
              }
            });
          })
          .catch((err) => console.error(err));
      }
    },
    handleColor(color) {
      if (color !== undefined) {
        switch (color.id) {
          case 'bg-info':
            return '#1DC7EA';
          case 'bg-success':
            return '#87CB16';
          case 'bg-primary':
            return '#007bff';
          case 'bg-warning':
            return '#FF9500';
          case 'bg-danger':
            return '#ed0000';
          case 'bg-secondary':
            return '#868e96';
          case 'bg-absence':
            return '#494949';
          case 'bg-black':
            return '#000000';
          case 'bg-yellow':
            return '#ffe21f';
          case 'bg-brown':
            return '#593716';
          case 'bg-pink':
            return '#ff7cd8';
          default:
            return '#4d4b80';
        }
      } else {
        return '#4d4b80';
      }
    },
    refreshTasks() {
      this.$store.dispatch('getTasks', { query: this.filters });
    },
    getProjects() {
      this.$http
        .get('/project')
        .then((res) => {
          this.$store.commit('projects', res.data.body);
        })
        .catch((err) => console.error(err));
    },
    getResources() {
      this.$http
        .get('/crud/project_resources')
        .then((res) => {
          this.$store.commit('resources', res.data.body);
        })
        .catch((err) => console.error(err));
    },
    getItems(query) {
      if (!query) {
        this.clearFilter();
        query = { perPage: 100 };
      } else {
        query.perPage = 100;
      }

      this.$store
        .dispatch('getTasks', query)
        .then((res) => {
          if (this.$store.state.data.tasks.length) {
            this.formatTasks(this.$store.state.data.tasks);
          } else {
            this.formatTasks(this.tasks);
          }
        })
        .catch((err) => console.error(err));
    },

    formatTasks(tasks) {
      if (!tasks || !tasks.length) {
        return [];
      }
      return tasks.map((t) => {
        const task = _.cloneDeep(t);
        task.id = task._id;
        // eslint-disable-next-line
        task._start = moment(task.start);
        // eslint-disable-next-line
        task._end = moment(task.end);
        const resource = _.find(this.$store.state.data.resources, {
          _id: task.resourceId,
        });
        const project = _.find(this.$store.state.data.projects, {
          _id: task.projectId,
        });
        if (resource) {
          task.name += ` - ${resource.firstname} ${resource.lastname}`;
          task.resource = resource;
          t.resource = resource;
        }

        task.custom_class = t.confirmed ? 'bg-primary' : 'bg-secondary';
        if (project) {
          task.name += ` - ${project.code || ''}`;
          task.project = project;
          t.project = project;
          task.custom_class = t.confirmed ? project.color : task.custom_class;
        }
        return task;
      });
    },
    setColor($event) {
      const project = this.$store.state.data.projects.find((p) => this.newTask.projectId === p._id);
      if (project) {
        this.newTask.color = project.color || 'bg-primary';
      }
    },
    loadBoards() {
      this.$http
        .get('/trello/board')
        .then((res) => {
          if (res && res.data && res.data.body) {
            this.boards = res.data.body;
          }
        })
        .catch(this.apiErrorCallback);
    },
    createOrSaveTask() {
      if (!this.newTask.start || !this.newTask.end || !this.newTask.projectId) {
        return;
      }
      if (this.newTask._id) {
        this.updateTask();
      } else {
        this.createTask();
      }
    },
    createTask() {
      if (!this.newTask.name || !this.newTask.start || !this.newTask.end || !this.newTask.projectId) {
        return;
      }
      this.newTask.progress = 100;
      if (Array.isArray(this.newTask.dependencies)) {
        this.newTask.dependencies = this.newTask.dependencies.map((task) => task._id || task);
      }
      const task = _.pick(this.newTask, this.taskTemplate);
      task.list = this.newTask.list;
      // this.$http.post('/crud/project_tasks', task).then(res => {

      const taskList = this.splitTaskIfNecessary(task);

      if (!taskList.length) {
        return false;
      }

      Promise.all(
        taskList.map((t) => this.$http.post(`/project/${this.newTask.projectId}/task`, t).then((res) => {
          EventBus.$emit('project_tasks:created', res.data);
        })),
      )
        .then(() => {
          swal({
            title: `Task ${task.name} created`,
            type: 'success',
          });
          this.getItems();
          this.closeModal();
        })
        .catch((err) => {
          console.error(err);
          this.$notify({ message: JSON.stringify(err) });
        });

      return false;
    },
    updateTask() {
      if (!this.newTask.name || !this.newTask.start || !this.newTask.end || !this.newTask._id) {
        return;
      }
      if (Array.isArray(this.newTask.dependencies)) {
        this.newTask.dependencies = this.newTask.dependencies.map((task) => task._id || task);
      }
      delete this.newTask.project_name;
      const task = _.pick(this.newTask, this.taskTemplate);
      this.$http
        .put(`/crud/project_tasks/${this.newTask._id}`, task)
        .then((res) => {
          EventBus.$emit('project_tasks:created', res.data);
          //  EventBus.$emit('project_tasks:updated', res.data)
          this.getItems();
          this.closeModal();
          this.$notify({
            message: 'Task updated',
            type: 'success',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$notify({ message: JSON.stringify(err) });
        });
      return false;
    },
    createAirtableProject() {
      this.$router.push('/projects/new');
    },
    createProject() {
      swal
        .mixin({
          reverseButtons: true,
          input: 'text',
          confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          progressSteps: ['1', '2', '3', '4'],
        })
        .queue([
          {
            title: 'Project Name',
          },

          { title: 'Budget (in days)', input: 'number' },
          { title: 'Used budget (in days)', input: 'number' },
          {
            title: 'Color',
            input: 'select',
            inputOptions: this.colors,
          },
        ])
        .then((result) => {
          if (result.value) {
            this.$http
              .post('/crud/project', {
                code: result.value[0],
                name: result.value[0],
                color: {
                  id: result.value[3],
                  label: this.colors[result.value[3]],
                },
                progress: 100,
                budget: result.value[1],
                spent: result.value[2],
              })
              .then((res) => {
                EventBus.$emit('project_projects:created', res.data);
                this.refreshTable.projects = true;
                swal({
                  title: `Project ${result.value[0]} created`,
                  type: 'success',
                });
              })
              .catch((err) => {
                console.error(err);
                this.$notify({ message: JSON.stringify(err) });
              });
          }
        });
    },
    importProject() {
      this.$notify({
        title: 'Importing projects from AirTable',
        timeout: 0,
        verticalAlign: 'top',
        horizontalAlign: 'center',
        component: ProgressNotification,
      });
      this.$http
        .get('/airtable/crm/projects')
        .then((res) => {
          this.$notifications.clear();
          this.projectsList = res.data.body;
          const projectsList = {};
          this.projectsList.forEach((p) => {
            projectsList[p.Code] = p.Name ? `${p.Code} - ${p.Name}` : p.Code;
          });

          swal
            .mixin({
              reverseButtons: true,
              confirmButtonText: 'Next &rarr;',
              showCancelButton: true,
              progressSteps: ['1', '2', '3', '4'],
            })
            .queue([
              {
                input: 'select',
                title: 'Choose your project',
                inputOptions: projectsList,
                inputClass: 'form-control',
              },
              {
                title: 'Color',
                input: 'select',
                inputClass: 'form-control',
                inputOptions: this.colors,
              },
            ])
            .then((result) => {
              let myProject = this.projectsList.find((p) => p.Code === result.value[0]);
              myProject = myProject || {};
              if (result.value) {
                this.$http
                  .post('/project', {
                    code: result.value[0],
                    name: projectsList[result.value[0]],
                    color: {
                      id: result.value[1],
                      label: this.colors[result.value[1]],
                    },
                    trelloBoard: myProject['Trello Board'] || '',
                    airtableId: myProject.airtableId || '',
                    airtableLink: myProject.airtableId
                      ? `https://airtable.com/tbl5xezAVxcr2NY97/viwqguVEP1cYoFmO2/${myProject.airtableId}`
                      : '',
                  })
                  .then((rs) => {
                    EventBus.$emit('project_projects:created', rs.data);
                    swal({
                      title: `Project ${result.value[0]} created`,
                      type: 'success',
                    });
                  });
              }
            });
        })
        .catch((err) => {
          console.error(err.response);
          this.$notify({ message: JSON.stringify(err) });
        });
    },
    createResource() {
      swal
        .mixin({
          reverseButtons: true,
          input: 'text',
          confirmButtonText: 'Next &rarr;',
          showCancelButton: true,
          progressSteps: ['1', '2', '3'],
        })
        .queue([
          {
            title: 'Email',
            text: 'Email address',
            input: 'email',
          },
          'Firstname',
          'Lastname',
        ])
        .then((result) => {
          if (result.value) {
            this.$http
              .post('/crud/project_resources', {
                email: result.value[0],
                firstname: result.value[1],
                lastname: result.value[2],
              })
              .then((resources) => {
                EventBus.$emit('project_resources:created', resources.data);
                this.refreshTable.resources = true;
                swal({
                  title: `Project ${result.value[0]} created`,
                  type: 'success',
                });
              });
          }
        });
    },
    openModal() {
      this.newTask = _.cloneDeep(this.emptyTask);
      this.$createTaskModal.modal();
    },
    openEditModal(item) {
      this.newTask = _.cloneDeep(item);

      this.$createTaskModal.modal();
    },
    closeModal() {
      this.newTask = _.cloneDeep(this.emptyTask);
      this.$createTaskModal.modal('hide');
    },
    filter() {
      if (!this.filters.projectId) {
        this.filters.projectId = undefined;
      }

      if (!this.filters.resourceId) {
        this.filters.resourceId = undefined;
      }

      this.getItems({ query: this.filters });
    },
    clearFilter() {
      this.filters.projectId = undefined;
      this.filters.resourceId = undefined;
    },
    deleteProject(item) {
      swal({
        title: this.$t('common.messages.are_you_sure'),
        text: this.$t('common.messages.wont_be_able_recover'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('common.buttons.yes_delete_it'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(`project/${item._id}`)
            .then((res) => {
              this.refreshTable.projects = true;
              swal({ title: 'Project deleted', type: 'success' });
            })
            .catch((err) => console.error(err));
        }
      });
    },
    deleteResource(item) {
      swal({
        title: this.$t('common.messages.are_you_sure'),
        text: this.$t('common.messages.wont_be_able_recover'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('common.buttons.yes_delete_it'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(`/crud/project_resources/${item._id}`)
            .then((res) => {
              this.refreshTable.resources = true;
              swal({ title: 'Ressource deleted', type: 'success' });
            })
            .catch((err) => console.error(err));
        }
      });
    },
    deleteTask(item) {
      item.cancel = true;
      swal({
        title: this.$t('common.messages.are_you_sure'),
        text: this.$t('common.messages.wont_be_able_recover'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('common.buttons.yes_delete_it'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (item.appointmentData.projectName === 'Absence') {
            this.$http
              .delete(`/absence/${item.appointmentData.id}`)
              .then((res) => {
                this.filter();
                swal({ title: 'Absence supprimée', type: 'success' });
              })
              .catch((err) => console.error(err));
          } else {
            this.$http
              .delete(`/crud/project_tasks/${item.appointmentData.id}`)
              .then((res) => {
                this.filter();
                swal({ title: 'Tâche supprimée', type: 'success' });
              })
              .catch((err) => console.error(err));
          }
        }
      });
    },
    editProject(item) {
      this.selectedItem = item;
      this.$http
        .get(`project/${item._id}`)
        .then((res) => {
          this.editModal.item = res.data.body;
          this.editModal.name = 'project';
          this.editModal.show = true;
          this.editModal.innerOptions.url = '/project';
        })
        .catch(this.apiErrorCallback);
    },
    editRessource(item) {
      this.selectedItem = item;
      this.$http
        .get(`/crud/project_resources/${item._id}`)
        .then((res) => {
          this.editModal.item = res.data.body;
          this.editModal.name = 'project_resources';
          this.editModal.show = true;
          this.editModal.innerOptions.url = '/crud/project_resources';
        })
        .catch(this.apiErrorCallback);
    },
    editTask(e) {
      this.selectedItem = e.appointmentData;
      if (this.selectedItem.projectName === 'Absence') {
        this.$http
          .get(`/absence/${this.selectedItem.id}`)
          .then((res) => {
            this.editModal.item = res.data.body;
            this.editModal.name = 'absence';
            this.editModal.show = true;
            this.editModal.innerOptions.url = '/absence';
          })
          .catch(this.apiErrorCallback);
      } else {
        this.$http
          .get(`/crud/project_tasks/${this.selectedItem.id}`)
          .then((res) => {
            this.editModal.item = res.data.body;
            this.editModal.name = 'project_tasks';
            this.editModal.show = true;
            this.editModal.innerOptions.url = '/crud/project_tasks';
          })
          .catch(this.apiErrorCallback);
      }
    },
    onCloseEditModal(itemEdited) {
      this.editModal.show = false;
      if (itemEdited) {
        this.getProjects();
        this.getResources();
        this.getItems();
        this.filter();
      }
    },
    splitTaskIfNecessary(task) {
      const currentDate = moment(task.start, 'YYYY-MM-DD');
      const endDate = moment(task.end, 'YYYY-MM-DD');

      if (
        !task
        || !task.start
        || !task.end
        || moment(task.start, 'YYYY-MM-DD').isAfter(moment(task.end, 'YYYY-MM-DD'))
      ) {
        return task;
      }

      const taskList = [];

      while (!currentDate.isAfter(endDate)) {
        const startWeekDay = currentDate.isoWeekday();
        if (startWeekDay > 5) {
          currentDate.add(1, 'weeks').startOf('isoWeek');
        } else {
          let weekEndDate = currentDate.clone().isoWeekday(5);

          if (endDate.isBefore(weekEndDate)) {
            weekEndDate = endDate;
          }

          taskList.push({
            ...task,
            start: currentDate.format('YYYY-MM-DD'),
            end: weekEndDate.format('YYYY-MM-DD'),
          });

          currentDate.add(1, 'weeks').startOf('isoWeek');
        }
      }

      return taskList;
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/css/devextreme.css';

.gantt-details-container {
  width: 300px;
  padding: 10px;
  color: #333;
  text-align: left;
}

.bg-primary .bar-progress {
  fill: #3472f7 !important;
}

.bg-info .bar-progress {
  fill: #1dc7ea !important;
}

.bg-success .bar-progress {
  fill: #87cb16 !important;
}

#gantt-top-scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 20px;
}

#gantt-top-scroll {
  width: 3000px;
  height: 20px;
}

.dx-scheduler {
  height: inherit !important;
}

.dx-scheduler-group-row,
.dx-scheduler-date-table-row {
  height: 60px;
}

.dx-scheduler-group-header {
  height: 60px !important;
}

.dx-scheduler-timeline .dx-scheduler-header-panel-cell:not(.dx-scheduler-header-panel-week-cell),
.dx-scheduler-timeline .dx-scheduler-date-table-cell {
  width: 40px !important;
}

.dx-scheduler-timeline .dx-scheduler-header-panel-cell:not(.dx-scheduler-header-panel-week-cell) {
  height: 60px !important;
}
.dx-scheduler-week-end {
  background: #e6e6e691;
}
.dx-scheduler-week-end:hover {
  background: #e6e6e691 !important;
}

.dx-scheduler-header-panel {
  margin: 0 !important;
}

.dx-scheduler-focused-cell {
  background: white !important;
  box-shadow: none !important;
}

.dx-scheduler-timeline .dx-scheduler-date-table,
.dx-scheduler-timeline .dx-scheduler-date-table-scrollable .dx-scrollable-content,
.dx-scheduler-timeline .dx-scheduler-sidebar-scrollable .dx-scrollable-content,
.dx-scheduler-timeline .dx-scheduler-group-table,
.dx-scheduler-timeline .dx-scheduler-date-table tbody {
  min-height: 0 !important;
}

.field-EnyoSelect div,
.field-EnyoSelect {
  width: 100% !important;
}

.dx-scheduler-appointment-popup {
  display: none !important;
}

.dx-button-text {
  text-transform: capitalize;
}

.dx-scheduler {
  display: none;
}
</style>
