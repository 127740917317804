const listOfValues = {
  state: {
    users: [],
    projects: [],
  },

  getters: {
  },
  mutations: {
    lov_users(state, list) {
      state.users = list;
    },
    lov_projects(state, list) {
      state.projects = list;
    },

  },
  actions: {
    refreshListOfValues({
      commit,
    }) {
      const q1 = this._vm.$http.get('/values');
      q1.then((res) => {
        commit('lov_users', res.data.users);
        commit('roles', res.data.roles);
        commit('extendedRoles');
      }).catch((err) => {
        console.error(err);
      });

      const q2 = this._vm.$http.get('/project?listOfValues=true');
      q2.then((res) => {
        commit('lov_projects', res.data.body);
      }).catch((err) => {
        console.error(err);
      });

      return Promise.all([q1, q2]);
    },
  },
};

export default listOfValues;
