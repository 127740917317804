<template>
  <div class="content" id="overviewPage">
    <div class="container-fluid">
      <h3 v-if="pm2Stats && pm2Stats.system_info">{{ pm2Stats.system_info.hostname }}</h3>
      <div class="row">
        <div
          class="col-xl-6 col-md-6 counter-widget pointer"
          v-if="pm2Stats && pm2Stats.monit && pm2Stats.monit.total_mem"
        >
          <stats-card>
            <div slot="header" class="icon-info">
              <i class="fa fa-disk text-primary"></i>
            </div>
            <div slot="content">
              <p class="card-category">Memory usage</p>
              <h4 class="card-title">
                {{ round((pm2Stats.monit.total_mem - pm2Stats.monit.free_mem) / 1024 / 1024) }}Mo /
                {{ round(pm2Stats.monit.total_mem / 1024 / 1024) }}Mo
              </h4>
            </div>
            <div slot="footer"></div>
          </stats-card>
        </div>
      </div>
      <div class="row" v-if="userHasRole('INTERNAL')">
        <div class="col-md-9" v-if="false">
          <chart-card
            :chart-data="{ series: [pm2Stats.monit.loadavg], labels: [1, 2, 3] }"
            chart-type="Line"
            :chart-options="lineChart.options"
            :graphNeedsRefresh="graphNeedsRefresh"
            :responsive-options="lineChart.responsiveOptions"
          >
            <template slot="header">
              <h4 class="card-title">Average load</h4>
            </template>
          </chart-card>
        </div>
        <div class="col-md-12">
          <AjaxTable
            class="table-hover table-striped"
            title="Deployment Jobs"
            :columns="[
              {
                type: 'string',
                field: 'name',
              },
              {
                type: 'html',
                field: 'lastBuild.description',
              },
              {
                type: 'html',
                field: 'color',
                formatFn: this.jenkinsFormatFunction,
                filterOptions: { enabled: true, filterDropdownItems: ['blue', 'red', 'notbuilt', 'aborted'] },
              },
              {
                type: 'text',
                field: 'lastBuild.result',
              },
              {
                type: 'datetime',
                field: 'lastBuild.timestamp',
              },

              {
                type: 'url',
                field: 'url',
              },
            ]"
            :rows="jenkinsJobs"
            :options="{
              actions: {
                create: false,
                edit: false,
                view: true,
                delete: false,
                search: true,
                dateFilter: false,
                filter: true,
              },
              pagination: true,
              noActions: false,
              filterInitialState: true,
            }"
            @refresh="getJenkinsStats"
            @view="viewDeployment"
          >
            <template slot="header">
              <h4 class="card-title">Jenkins Jobs</h4>
            </template>
          </AjaxTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rolesMixin from '@/mixins/rolesMixin';
import _ from 'lodash';
import moment from 'moment';

// import pm2Stats from "/tmp/stats.json";

export default {
  mixins: [rolesMixin],
  components: {},
  data() {
    return {
      generalStats: [],
      statsHandle: null,
      jenkinssHandle: null,
      // pm2Stats: pm2Stats || {},
      pm2Stats: {},
      jenkinsJobs: [],
      missingDailyStandupMembers: {
        date: null,
        columns: ['firstname', 'lastname', 'username'],
        data: [],
      },
      graphNeedsRefresh: false,
      // editTooltip: 'Edit Task',
      // deleteTooltip: 'Remove',
      // pieChart: {
      //   data: {
      //     labels: ['40%', '20%', '40%'],
      //     series: [40, 20, 40]
      //   }
      // },
      lineChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          low: 0,
          high: 1,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },

      statsTable: {
        // columns: ['item', 'count', 'value'],
        columns: [
          {
            label: this.$t('app.projects.months'),
            field: 'item',
          },
          {
            label: this.$t('app.projects.contracts'),
            field: 'count',
          },
          {
            label: this.$t('app.projects.value'),
            field: 'value',
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    this.getJenkinsStats();
  },
  beforeDestroy() {
    clearInterval(this.statsHandle);
  },
  methods: {
    round: _.round,
    jenkinsFormatFunction(status) {
      switch (status) {
        case 'blue':
          return '<i class="fa fa-circle text-success animated pulse"></i>';
        case 'blue_anime':
          return '<i class="fa fa-circle text-success animated animated infinite pulse"></i>';
        case 'red':
          return '<i class="fa fa-circle text-danger"></i>';
        case 'red_anime':
          return '<i class="fa fa-circle text-danger animated infinite pulse"></i>';
        case 'disabled':
        case 'grey':
          return '<i class="fa fa-circle text-default"></i>';
        case 'grey_anime':
        case 'disabled_anime':
          return '<i class="fa fa-circle text-default animated infinite pulse"></i>';
        case 'notbuilt':
          return '<i class="fa fa-question-circle text-warning"></i>';
        case undefined:
          return '<i class="fa fa-question-circle text-info"></i>';
        default:
          return `<i class="fa fa-question-circle text-info"></i> ${status}`;
      }
    },
    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatSize(bytes, decimal) {
      const k = 1024;
      const decimals = typeof decimal === 'undefined' ? 2 : decimal;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      if (bytes === 0) return '0 bytes';
      return `${Math.round(bytes / Math.pow(k, i), decimals)} ${sizes[i]}`;
    },

    getPm2Stats() {
      this.$http
        .get('/pm2/stats')
        .then((res) => {
          this.pm2Stats = res.data.body;
          this.pm2Stats.processes = this.pm2Stats.processes.map((p) => {
            p.monit.memory = this.formatSize(p.monit.memory);
            return p;
          });
          this.graphNeedsRefresh = true;
        })
        .catch((err) => console.warn(err));
    },

    getJenkinsStats() {
      this.$http
        .get('/devops/jenkins')
        .then((res) => {
          this.jenkinsJobs = res.data.body;
        })
        .catch((err) => console.warn(err));
    },

    viewDeployment(item) {
      this.$router.push(`/app/deployment/${item.name}#deployment-logs`);
    },

    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },
  },
};
</script>
<style>
</style>
