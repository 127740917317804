<template>
  <div class="main-wrapper">
    <side-bar color="blue">
      <mobile-menu slot="content"></mobile-menu>
      <div v-if="$store.state.bookmarks && $store.state.bookmarks.length">
        <h4 class="ml-3 pointer" data-toggle="collapse" data-target="#project-menu">
          <i class="fa fa-caret-right"></i>
          {{ $t('app.labels.bookmarks') }}
        </h4>
        <div id="project-menu" class="collapse show">
          <sidebar-link v-for="bookmark in $store.state.bookmarks" :key="bookmark.url" :to="bookmark.url">
            <i class="fa fa-star-o"></i>
            <div class="text-truncate">{{ bookmark.name }}</div>
          </sidebar-link>
        </div>
      </div>
      <hr />
      <sidebar-link to="/app/overview">
        <i class="nc-icon nc-chart-bar-32"></i>
        <div>{{ $t('common.labels.dashboard') }}</div>
      </sidebar-link>
      <sidebar-link to="/app/business-report">
        <i class="nc-icon nc-chart-bar-32"></i>
        <div>{{ $t('common.labels.businessReport') }}</div>
      </sidebar-link>
      <sidebar-link to="/app/profile">
        <i class="fa fa-edit"></i>
        <div>{{ $t('common.labels.profile') }}</div>
      </sidebar-link>
      <hr />
      <div>
        <h4 class="ml-3 pointer" data-toggle="collapse" data-target="#team-menu">
          <i class="fa fa-caret-right"></i>
          {{ $t('app.labels.team') }}
        </h4>
        <div id="team-menu" class="collapse show">
          <sidebar-link to="/app/daily_standup/write">
            <i class="nc-icon nc-paper-2"></i>
            <div>{{ $t('app.labels.write_daily_standup') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/daily_standup/list">
            <i class="nc-icon nc-paper-2"></i>
            <div>{{ $t('app.labels.daily_standup') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/absence" v-access-control="'MANAGER'">
            <i class="fa fa-sun"></i>
            <div>{{ $t('app.labels.absences') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/remote-day">
            <i class="fa fa-sun"></i>
            <div>{{ $t('app.labels.remoteDays') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/address-book">
            <i class="fa fa-address-book"></i>
            <div>{{ $t('app.labels.addressBook') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/admin/user" v-access-control="'MANAGER'">
            <i class="fa fa-users"></i>
            <div>{{ $t('app.labels.users') }}</div>
          </sidebar-link>
        </div>
      </div>
      <hr />
      <div v-access-control="'PROJECTMANAGER'">
        <h4 class="ml-3 pointer" data-toggle="collapse" data-target="#project-menu">
          <i class="fa fa-caret-right"></i>
          {{ $t('app.labels.projectManagement') }}
        </h4>
        <div
          id="project-menu"
          class="collapse"
          :class="{ show: userIs('PROJECTMANAGER'), hide: !userIs('PROJECTMANAGER') }"
        >
          <sidebar-link to="/app/project/project">
            <i class="nc-icon nc-notes"></i>
            <div>{{ $t('app.labels.projects') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/project/report">
            <i class="nc-icon nc-notes"></i>
            <div>{{ $t('app.labels.reports') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/project/milestone">
            <i class="nc-icon nc-email-85"></i>
            <div>{{ $t('app.labels.milestones') }}</div>
          </sidebar-link>
          <sidebar-link to="/app/project/gantt">
            <i class="nc-icon nc-album-2"></i>
            <div>Gantt</div>
          </sidebar-link>
          <sidebar-link to="/app/helpdesk/gmail-alert">
            <i class="fa fa-bell"></i>
            <div>Gmail alerts</div>
            <!-- TODO -->
          </sidebar-link>
        </div>
      </div>
      <h4 class="ml-3 pointer" data-toggle="collapse" data-target="#tools-menu">
        <i class="fa fa-caret-right"></i>
        {{ $t('app.labels.tools') }}
      </h4>
      <div id="tools-menu" class="collapse" :class="{ show: userIs('DEVELOPER'), hide: !userIs('DEVELOPER') }">
        <sidebar-link to="/app/devops/overview">
          <i class="fa fa-server"></i>
          <div>Devops Dashboard overview</div>
        </sidebar-link>
        <sidebar-link to="/app/devops/init">
          <i class="nc-icon nc-album-2"></i>
          <div>Deployment tool</div>
        </sidebar-link>
        <sidebar-link to="/app/deployment">
          <i class="nc-icon nc-bullet-list-67"></i>
          <div>{{ $t('app.labels.deployments') }}</div>
        </sidebar-link>
        <sidebar-link href="https://documentation.enyosolutions.com/" tag="li">
          <i class="nc-icon nc-album-2"></i>
          <div>Documentation</div>
        </sidebar-link>
        <sidebar-link href="https://ci.dev.enyosolutions.com/" tag="li">
          <i class="nc-icon nc-album-2"></i>
          <div>Jenkins CI/CD</div>
        </sidebar-link>
        <sidebar-link class="d-none" to href="http://mysql.dev.enyosolutions.com/" tag="li">
          <i class="fa fa-database"></i>
          <div>Mysql admin</div>
        </sidebar-link>
        <sidebar-link to="/admin/webhooks" v-access-control="'ADMIN'">
          <i class="nc-icon nc-bullet-list-67"></i>
          <div>Webhooks</div>
        </sidebar-link>
        <sidebar-link to="/app/api_log" v-access-control="'ADMIN'">
          <i class="nc-icon nc-bullet-list-67"></i>
          <div>{{ $t('app.labels.api_logs') }}</div>
        </sidebar-link>
        <sidebar-link to="/app/permanent_token" v-access-control="'ADMIN'">
          <i class="nc-icon nc-bullet-list-67"></i>
          <div>{{ $t('app.labels.permanent_token') }}</div>
        </sidebar-link>
      </div>
      <!-- <hr> -->
      <!-- <h4 class="ml-3">{{ $t('common.labels.dynamicPages') }}</h4>
        <sidebar-link
        :to="'/app/crud/' + model.name"
        v-for="model of $store.state.data.models"
        :key="model.name"
        >
        <i class="nc-icon nc-circle-09"></i>
        <div>{{ model.title }}</div>
      </sidebar-link>-->
      <!-- <hr> -->
      <div v-access-control="'SUPERADMIN'">
        <h4 class="ml-3">{{ $t('common.labels.staticPages') }}</h4>
        <sidebar-link class="d-none" to="/app/notifications">
          <i class="nc-icon nc-bell-55"></i>
          <div>Notifications</div>
        </sidebar-link>
      </div>
      <hr />
      <li class="" v-access-control="['SUPERADMIN', 'TEAMLEAD']">
        <a class="nav-link" @click="loadDynamicPages()">
          <div>{{ $t('common.menus.loadDynamicPages') }}</div>
        </a>
      </li>
      <div style="position: relative; bottom: 1px; font-size: 10px; padding: 4px 15px; background: rgba(0, 0, 0, 0.9)">
        Version: {{ config.version }}
        <br />
        Built: {{ config.buildDate }}
      </div>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>

      <div class="bottom-fab-actions">
        <button class="btn bg-primary btn-simple btn-fab mb-1" id="btn-fab-up" @click="scrollUp()">
          <i class="text-light fa fa-angle-up fa-2x"></i>
        </button>
        <button class="btn bg-secondary btn-simple btn-fab" id="btn-fab-down" @click="scrollDown()">
          <i class="text-light fa fa-angle-down fa-2x"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.bottom-fab-actions {
  display: block;
  position: fixed !important;
  bottom: 45px !important;
  right: 45px !important;
  opacity: 0.9 !important;
}
.btn-fab {
  display: block !important;
  transition: all ease-out 200ms !important;
}

.btn-fab:hover {
  box-shadow: 4px 6px 8px #999 !important;
}

.bottom-fab-actions:hover {
  opacity: 1 !important;
}

.app-in-upper-section .bottom-fab-actions #btn-fab-up {
  opacity: 0;
  visibility: hidden;
}

.app-in-lower-section .bottom-fab-actions #btn-fab-down {
  opacity: 0;
  visibility: hidden;
}
</style>
<script>
import rolesMixin from '@/mixins/rolesMixin';
import config from '@/config';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './MobileMenu.vue';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  mixins: [rolesMixin],
  data() {
    return {
      config,
      scrollZone: null,
    };
  },

  mounted() {
    this.loadDynamicPages();

    this.scrollZone = document.querySelector('.main-panel');
    this.scrollZone.onscroll = () => {
      this.onScroll();
    };
  },
  methods: {
    loadDynamicPages() {
      this.$store.dispatch('getModels').then(() => {
        this.$notify('Models successfully updated');
      });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    scrollUp() {
      $('.main-panel').animate({ scrollTop: 0 }, 'slow');
    },
    scrollDown() {
      $('.main-panel').animate({ scrollTop: this.scrollZone.scrollHeight }, 'slow');
    },

    onScroll() {
      if (this.scrollZone.scrollTop < 350) {
        document.body.classList.add('app-in-upper-section');
      } else {
        document.body.classList.remove('app-in-upper-section');
      }

      if (this.scrollZone.scrollHeight - this.scrollZone.scrollTop > this.scrollZone.clientHeight) {
        document.body.classList.remove('app-in-lower-section');
      } else {
        document.body.classList.add('app-in-lower-section');
      }
    },
  },
};
</script>
