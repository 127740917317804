<template>
  <card>
    <h4 slot="header" class="card-title">{{ $t('app.labels.editProfile') }}</h4>
    <form @submit="updateProfile">
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.firstname')"
            :placeholder="$t('common.field.firstname')"
            v-model="user.firstname"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.lastname')"
            :placeholder="$t('common.field.lastname')"
            v-model="user.lastname"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            :disabled="true"
            type="email"
            :label="$t('common.field.email')"
            :placeholder="$t('common.field.email')"
            v-model="user.email"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            :disabled="true"
            type="email"
            :label="$t('common.field.username')"
            :placeholder="$t('common.field.username')"
            v-model="user.email"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <fg-input
            type="text"
            :label="$t('common.field.address')"
            :placeholder="$t('common.field.address')"
            v-model="user.address"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.city')"
            :placeholder="$t('common.field.city')"
            v-model="user.city"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="number"
            :label="$t('common.field.postalcode')"
            :placeholder="$t('common.field.postalcode')"
            v-model="user.postalcode"
          ></fg-input>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.society')"
            :placeholder="$t('common.field.society')"
            v-model="user.society"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <label class="control-label">{{ $t('common.field.language') }}</label>
          <multiselect
            :placeholder="$t('common.field.language')"
            v-model="user.locale"
            :multiple="false"
            :options="['fr', 'en']"
          ></multiselect>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.phonenumber')"
            :placeholder="$t('common.field.phonenumber')"
            v-model="user.phonenumber"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.phonenumber2')"
            :placeholder="$t('common.field.phonenumber2')"
            v-model="user.phonenumber2"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.skype')"
            :placeholder="$t('common.field.skype')"
            v-model="user.skypeId"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.slack')"
            :placeholder="$t('common.field.slack')"
            v-model="user.slackId"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            v-if="isTrelloAuthorized()"
            type="text"
            :label="$t('common.field.trello')"
            :placeholder="$t('common.field.trello')"
            v-model="user.trelloId"
            :addonRightButton="isTrelloAuthorized()"
            addonRightButtonIcon="fa fa-sync"
            addonRightButtonClass="btn btn-fill btn-info"
            :addonRightButtonTitle="$t('common.tooltip.trello_authorize')"
            @rightClicked="authorizeTrello"
          ></fg-input>
          <fg-input
            v-if="!isTrelloAuthorized()"
            type="text"
            :label="$t('common.field.trello')"
            :placeholder="$t('common.field.trello')"
            v-model="user.trelloId"
            :addonRightButton="!isTrelloAuthorized()"
            addonRightButtonIcon="fa fa-times"
            addonRightButtonClass="btn btn-fill btn-danger"
            :addonRightButtonTitle="'Remove'"
            @rightClicked="removeTrelloToken"
          ></fg-input>
          <!-- <div class="clearfix">
            <button class="btn btn-info">Authorize</button>
          </div>-->
          <!-- <div class="input-group mb-3">
            <input
            type="text"
            class="form-control"
            placeholder="Recipient's username"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            >
            <div class="input-group-append">
              <button class="btn btn-info" type="button">Button</button>
            </div>
          </div>-->
        </div>
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.gitlab')"
            :placeholder="$t('common.field.gitlab')"
            v-model="user.gitlabId"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <fg-input
              type="text"
              :label="$t('common.field.gitlabApiKey')"
              :placeholder="$t('common.field.gitlabApiKey')"
              v-model="user.gitlabApiKey"
              :addonRightButton="!user.gitlabApiKey"
              addonRightButtonIcon="fa fa-sync"
              addonRightButtonClass="btn btn-fill btn-info"
              :addonRightButtonTitle="$t('common.tooltip.gitlab_authorize')"
              @rightClicked="gotoGitlab"
            ></fg-input>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">{{ $t('common.field.roles') }}</label>
            <multiselect
              :placeholder="$t('common.field.roles')"
              v-model="user.roles"
              :multiple="true"
              :options="roles"
              :disabled="intersection($store.state.user.roles, ['TEAMLEAD', 'ADMIN', 'SUPERADMIN']).length < 1"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-info btn-fill float-right">{{ $t('common.buttons.save') }}</button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>
</template>
<script>
import Card from '@/components/UIComponents/Cards/Card.vue';
import apiErrors from '@/mixins/api-errors';
import Multiselect from 'vue-multiselect';
import config from '@/config';
import _ from 'lodash';

export default {
  components: {
    Card,
    Multiselect,
  },
  props: {
    user: Object,
  },
  data() {
    return {};
  },
  computed: {
    roles() {
      return this.$store.state.data.models.find((m) => m.name === 'user').schema.properties.roles.items.enum;
    },
  },

  mixins: [apiErrors],
  created() {},

  methods: {
    intersection: _.intersection,
    startCase: _.startCase,

    dispatchAction(e) {},

    updateProfile(e) {
      e.preventDefault();
      this.$emit('update', this.user);
    },

    isTrelloAuthorized() {
      if (!this.user.trelloToken || !this.user.trelloTokenSecret) {
        return true;
      }
      return false;
    },

    authorizeTrello(e) {
      window.location = `${config.apiUrl.replace(/\/api\/?/, '/')}trello/authorize?token=${this.$store.state.token}`;
    },

    removeTrelloToken(e) {
      this.$http
        .get('trello/removeToken')
        .then((res) => {
          if (res && res.data) {
            this.user = res.data.body;
            this.$store.commit('user', this.user);
            this.$store.commit('currentLocale', this.user.locale);
          }
        })
        .catch(this.apiErrorCallback);
    },

    gotoGitlab() {
      const url = 'https://gitlab.com/profile/personal_access_tokens';
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
};
</script>
<style></style>
