import { directive as vClickOutside } from 'vue-clickaway';
import accessControl from '@/directives/access-control';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', vClickOutside);
    Vue.directive('access-control', accessControl);
    Vue.directive('tooltip', (el, binding) => {
      $(el).tooltip({
        title: binding.value,
        placement: binding.arg,
        trigger: 'hover',
      });
    });
  },
};

export default GlobalDirectives;
