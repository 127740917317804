<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <fg-input
            type="text"
            :placeholder="$t('common.field.search')"
            v-model="search"
            :addonRightButton="true"
            addonRightButtonIcon="fa fa-search"
            addonRightButtonClass="btn btn-fill btn-primary"
            @rightClicked="loadData"
          ></fg-input>
        </div>
        <div class="col-xs-12 col-md-4" v-for="user of users" :key="user._id">
          <user-card :user="user" style="height: 550px"></user-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiErrors from '@/mixins/api-errors';
import UserCard from '@/pages/user/user-profile/UserCard.vue';

export default {
  components: {
    UserCard,
  },
  data() {
    return {
      users: [],
      search: '',
    };
  },
  watch: {
    search: 'loadData',
  },
  mixins: [apiErrors],
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http
        .get('/user', { params: { search: this.search } })
        .then((res) => {
          if (res && res.data && res.data.body) {
            this.users = res.data.body;
          }
        })
        .catch(this.apiErrorCallback);
    },
  },
};
</script>
<style>
</style>
