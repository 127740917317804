<template>
  <div class="modal" tabindex="-1" role="dialog" id="formModal">
    <div class="modal-full-height" role="document">
      <div class="modal-content">
        <form @submit.prevent="editItem()">
          <div class="modal-header text-white">
            <h3 class="modal-title mt-0">{{ $t('common.buttons.edit') }}</h3>
            <button type="button" class="close" aria-label="Close" @click="closeModal">
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="edit-form" :selectedItem="selectedItem">
              <template v-if="formSchema && formSchema.fields">
                <VueFormGenerator
                  :schema.sync="formSchema"
                  :model="selectedItem"
                  :options="formOptions"
                ></VueFormGenerator>
              </template>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="edit-modal-footer">
              <button type="button" class="btn btn-default btn-simple mr-auto" @click="closeModal">
                {{ $t('common.buttons.cancel') }}
              </button>
              <button type="submit" class="btn btn-primary ml-auto">{{ $t('common.buttons.save') }}</button>
            </slot>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
// eslint-disable-next-line
import swal from 'sweetalert2/dist/sweetalert2.js';
import EventBus from '@/stores/event-bus';

export default {
  name: 'Edit',
  props: {
    selectedItem: {},
    name: String,
    innerOptions: {},
  },
  data() {
    return {
      mySelect: 3,
      opts: [],
      mySelect2: [1, 3, 5],
      $modal: null,
      parentPath: '',
      viewMode: false,
      tableNeedsRefresh: false,
      statsNeedsRefresh: false,
      innerSchema: {},
      innerModel: {},
      formOptions: {
        validayeAsync: true,
        validateAfterLoad: false,
        validateAfterChanged: true,
      },
    };
  },
  watch: {
    // call again the method if the route changes
    name: 'loadModel',
    model: 'loadModel',
    options: 'mergeOptions',
    crudNeedsRefresh: 'refreshComponent',
  },
  created() {
    this.loadModel();
  },
  computed: {
    formSchema() {
      if (!this.innerSchema) {
        return [];
      }
      const parsedFormSchema = this.parseSchema(this.innerSchema);
      return parsedFormSchema;
    },
  },
  mounted() {
    this.$modal = $('#formModal');
    this.loadModel();
  },
  methods: {
    loadModel() {
      if (!this.options) {
        this.options = {};
      }
      this.mergeOptions();
      if (!this.model) {
        this.innerModel = this.$store.state.data.models.find((model) => model.name === this.name);
      } else {
        this.innerModel = this.model;
      }
      if (!this.innerModel) {
        console.warn('CRUD COMPONENT ERROR', `model ${this.name} not found`);
        return;
      }
      this.innerSchema = this.schema || this.innerModel.schema;
      this.innerOptions.columns = this.formatColumns(this.innerSchema.properties);

      this.innerOptions.url = this.innerOptions.url || this.options.url || this.innerModel.url || `/crud/${this.name}`;

      if (this.$route.params && this.$route.params.id) {
        this.$http
          .get(`${this.innerOptions.url}/${this.$route.params.id}`)
          .then((res) => {
            const matched = this.$route.matched[this.$route.matched.length - 1];
            if (matched.path.indexOf('/edit') !== -1) {
              this.editFunction(res.data.body);
            } else {
              this.viewFunction(res.data.body);
            }
            this.$forceUpdate();
          })
          .catch(this.apiErrorCallback);
      }
    },
    mergeOptions() {
      if (this.options.deletePermitted && this.options.actions.delete) {
        if (!this.options.deletePermitted.some((v) => this.$store.state.user.roles.indexOf(v.toUpperCase()) >= 0)) {
          this.options.actions.delete = false;
        }
      }
      this.innerOptions = _.merge(this.innerOptions, this.options);
    },
    parseSchema(schema, prefix = '') {
      if (!schema.properties) {
        return [];
      }
      if (prefix && schema.$schema) {
        console.warn('possible recursive parseSchema call', schema);
        return;
      }
      const formSchema = [];
      const size = Object.keys(schema.properties).length;
      Object.keys(schema.properties).forEach((key) => {
        if (['_id', 'metadata'].indexOf(key) === -1) {
          const prop = schema.properties[key];
          if (prop.field && prop.field.hidden) {
            return;
          }
          if (prop.type === 'object' && !(prop.field && prop.field.type)) {
            const subSchema = this.parseSchema(prop, `${key}.`);
            subSchema.legend = prop.title || _.startCase(key);
            subSchema.type = 'group';
            subSchema.styleClasses = `subgroup  ${(prop.field && prop.field.styleClasses) || 'card'}`;
            formSchema.push(subSchema);
          } else {
            if (prop.field && prop.relation && prop.field.fieldOptions) {
              prop.field.fieldOptions.url = prop.relation;
              prop.field.fieldOptions.trackBy = prop.foreignKey;
            }
            const field = {
              type: (prop.field && prop.field.type) || this.getFormtype(prop),
              label: prop.title || prop.description || _.startCase(key),
              fieldOptions: (prop.field && prop.field.fieldOptions) || {
                url: prop.relation,
                trackBy: prop.foreignKey || 'code',
                label: 'label',
                step: prop.field && prop.field.step,
              },
              values:
                prop.enum
                || (prop.items && prop.items.enum)
                || (prop.field && prop.field.fieldOptions && this.getSelectEnum(prop.field.fieldOptions.enum)),
              required: prop.field && prop.field.required,
              hint: prop.description,
              model: prefix + key,
              validator: prop.field && prop.field.validator,
              min: prop.min,
              max: prop.max,
              multi: prop.type === 'array',
              readonly: this.viewMode === 'view' || (prop.field && prop.field.readonly),
              disabled: this.viewMode === 'view' || (prop.field && prop.field.readonly),
              styleClasses: (prop.field && prop.field.styleClasses) || (size < 8 ? 'col-md-12' : 'col-md-6'),
              relation: prop.relation,
              foreignKey: prop.foreignKey,
            };
            field.fieldOptions.inputType = (prop.field && prop.field.inputType) || this.getFormInputType(prop) || 'text';
            if (field.type === 'dateTime') {
              field.fieldOptions.icons = {
                time: 'fa fa-clock',
                date: 'fa fa-calendar',
                up: 'fa fa-arrow-up',
                down: 'fa fa-arrow-down',
              };
            }
            formSchema.push(field);
          }
        }
      });
      return { fields: formSchema };
    },
    getSelectEnum(val) {
      const options = _.isString(val) && val.indexOf('$store') === 0 ? _.get(this.$store.state, val.replace('$store.', '')) : val;
      return options;
    },
    getFormInputType(property) {
      let { type } = property;
      if (Array.isArray(type)) {
        const possibleTypes = ['string', 'number', 'boolean'];
        for (let i = 0; i < possibleTypes.length; i++) {
          if (property.type.indexOf(possibleTypes[i]) > -1) {
            type = possibleTypes[i];
          }
        }
      }

      switch (type) {
        case 'string':
          switch (property.format) {
            case 'email':
              return 'email';
            case 'date-time':
              return 'datetime';
            default:
              return 'text';
          }
        case 'number':
          return 'number';
        case 'boolean':
        case 'array':
        case 'object':
          return 'string';
        default:
          console.error('type not known ', type, property);
          return type;
      }
    },
    formatColumns(properties) {
      const newcolumns = [];
      Object.keys(properties).forEach((key) => {
        const newCol = {};
        const prop = properties[key];
        if (!prop.hidden && !(prop.column && prop.column.hidden)) {
          newCol.field = key;
          newCol.type = this.getColumnType(prop);
          newCol.label = _.startCase((prop.column && prop.column.title) || prop.title || key);
          newCol.listName = prop.column && prop.column.listName;
          newCol.filterOptions = { enabled: false };
          newCol.enum = prop.enum || (prop.column && prop.column.enum);
          newCol.sortable = true;
          newcolumns.push(newCol);
          if (prop.relation) {
            newCol.relation = prop.relation;
          }
        }
      });

      if (!newcolumns.find((col) => col.field === 'ACTIONS')) {
        newcolumns.push({
          field: 'ACTIONS',
          label: 'Actions',
          filterable: false,
          enabled: false,
        });
      }
      return newcolumns;
    },
    getColumnType(property) {
      if (property.column && property.column.type) {
        return property.column && property.column.type;
      }
      if (property.columnType) {
        return property.columnType;
      }
      let { type } = property;
      if (Array.isArray(type)) {
        const possibleTypes = ['string', 'number', 'boolean'];
        for (let i = 0; i < possibleTypes.length; i++) {
          if (property.type.indexOf(possibleTypes[i]) > -1) {
            type = possibleTypes[i];
          }
        }
      }

      switch (type) {
        case 'string':
          switch (property.format) {
            case 'date-time':
              return 'text';
            default:
              return 'text';
          }
        case 'number':
          return 'number';
        case 'boolean':
          return 'boolean';
        case 'array':
        case 'object':
          return 'object';
        default:
          return 'text';
      }
    },
    getFormtype(property) {
      let { type } = property;
      if (Array.isArray(type)) {
        const possibleTypes = ['string', 'number', 'boolean'];
        for (let i = 0; i < possibleTypes.length; i++) {
          if (property.type.indexOf(possibleTypes[i]) > -1) {
            type = possibleTypes[i];
          }
        }
      }
      switch (type) {
        default:
        case 'string':
          return 'input';
        case 'number':
          return 'input';
        case 'boolean':
          return 'checkbox';
      }
    },
    editItem() {
      if (!this.innerOptions.url) {
        console.warn('CRUDCOMPONENT ERROR:: No url for submitting');
        return false;
      }

      if (this.$refs.form) {
        const errors = this.$refs.form.validate();
        if (errors.length > 0) {
          console.error('CRUDCOMPONENT ERROR:: validation errors', errors);
          return;
        }
      } else {
        console.warn('Unable to find the reference to the schema form on ', this.$route.path);
      }

      this.validateFormat(this.selectedItem);

      this.$http
        .put(`${this.innerOptions.url}/${this.selectedItem._id}`, this.selectedItem)
        .then((res) => {
          EventBus.$emit(`${this.name}:created`, res.data);
          swal({
            title: this.$t('common.messages.successfullyModified', {
              title: this.type,
            }),
            type: 'success',
          });
          this.$forceUpdate();
          this.closeModal(true);
        })
        .catch(this.apiErrorCallback);
      return false;
    },
    closeModal(itemEdited = false) {
      this.$emit('close', itemEdited);
    },
    validateFormat() {
      if (this.selectedItem.startDate === 'Invalid date') {
        delete this.selectedItem.startDate;
      }
      if (this.selectedItem.endDate === 'Invalid date') {
        delete this.selectedItem.endDate;
      }
    },
  },
};
</script>

<style scoped>
.modal-content {
  max-width: 500px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal {
  display: inline-block;
  background: rgba(0, 0, 0, 0.58);
  overflow-y: scroll !important;
}

.modal-header {
  background: #828282c7;
}
</style>
