var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",attrs:{"id":"overviewPage"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-md-9 col-xs-12 col-md-3 text-right"},[_c('date-range-picker',{staticClass:"form-group vgt-date-range",attrs:{"placeholder":_vm.$t('common.field.start'),"locale-data":_vm.datePicker.locale,"opens":'left',"auto-apply":true},on:{"update":function($event){return _vm.getClosedCrmProjects()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 widget"},[_c('AjaxTable',{attrs:{"columns":[
            'title',
            {
              type: 'list-of-value',
              listName: 'projects',
              label: 'Project',
              field: 'projectId',
            },
            {
              type: 'date',
              label: 'Date',
              field: 'date',
            },
            'completed' ],"entity":"milestone","url":"/crud/milestone","options":{ actions: { delete: false, search: false, filter: false }, pagination: false },"params":{ filters: {}, sort: { date: 'DESC' }, perPage: 10 }},on:{"view":function (item) {
              _vm.$router.push({ name: 'milestone-view', params: { id: item._id } });
            }}})],1),_c('div',{directives:[{name:"access-control",rawName:"v-access-control",value:('MANAGER'),expression:"'MANAGER'"}],staticClass:"col-md-6 widget"},[_c('card',[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('common.labels.missingDailystandup'))+" ("+_vm._s(_vm.formatDate(this.missingDailyStandupMembers.date))+") ")])]),_c('l-table',{staticClass:"table-hover table-striped",attrs:{"columns":_vm.missingDailyStandupMembers.columns,"data":_vm.missingDailyStandupMembers.data}}),_c('template',{slot:"footer"},[_c('div',{staticClass:"stats pointer",on:{"click":function($event){return _vm.getMissingDailyStandupMembers()}}},[_c('i',{staticClass:"fa fa-refresh"}),_vm._v(" "+_vm._s(_vm.$t('common.buttons.refresh'))+" ")])])],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }