export default {
  // Quand l'élément lié est inséré dans le DOM...
  bind(el, binding, vnode, aa) {
    el.dataset.roles = binding.value;
  },
  inserted(el, binding, vnode) {
    const hideAccess = (element) => {
      element.remove();
      return false;
    };

    const _vm = vnode.context.$root;
    let accessRoles = binding.value;
    if (!Array.isArray(accessRoles)) {
      accessRoles = [accessRoles];
    }

    if (!_vm.$store.state.user || !_vm.$store.state.user._id || !_vm.$store.state.user.roles) {
      return hideAccess(el);
    }

    let canAccess = false;
    for (let i = 0; i < accessRoles.length; i++) {
      const role = accessRoles[i];
      if (_vm.$store.state.userExtendedRoles.indexOf(role) > -1) {
        canAccess = true;
        break;
      }
    }
    if (!canAccess) {
      return hideAccess(el);
    }
    return true;
  },
};
