<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <edit-profile-form :user="user" @update="updateProfile"></edit-profile-form>
        </div>
        <div class="col-md-4">
          <user-card :user="user"></user-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiErrors from '@/mixins/api-errors';
import { intersection } from 'lodash';
import config from '@/config';
import EditProfileForm from './user-profile/ProfileForm.vue';
import UserCard from './user-profile/UserCard.vue';

export default {
  components: {
    EditProfileForm,
    UserCard,
  },
  data() {
    return {
      user: {},
      roles: ['ADMIN', 'DEVELOPER', 'USER'],
    };
  },
  mixins: [apiErrors],
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http
        .get('auth/user')
        .then((res) => {
          if (res && res.data && res.data.user) {
            this.user = res.data.user;
            this.$store.commit('user', res.data.user);
            this.$store.commit('currentLocale', res.data.user.locale || config.defaultLocale);
            if (res.data.token) {
              this.$store.commit('token', res.data.token);
            }
          }
        })
        .catch(this.apiErrorCallback);
    },
    updateProfile(user) {
      this.$http
        .put(`user/${this.user._id}`, user)
        .then((res) => {
          if (res && res.data && res.data.user) {
            this.$notify({
              title: this.$t('common.messages.successfullyUpdated'),
              type: 'success',
            });
            if (this.user._id === this.$store.state.user._id) {
              if (
                intersection(res.data.user.roles, this.$store.state.user.roles).length
                !== this.$store.state.user.roles.length
              ) {
                this.$notify(this.$t('app.messages.user_roles_have_changed'));
              }

              this.$i18n.locale = this.user.locale;
              this.$store.commit('user', this.user);
              this.$store.commit('currentLocale', this.user.locale);
            }
            this.user = res.data.user;
          }
        })
        .catch(this.apiErrorCallback);
    },
  },
};
</script>
<style>
</style>
