<template>
  <crud-component v-bind="$props" primaryKey="_id" :crudNeedsRefresh="crudNeedsRefresh">
    <template slot="create-form" slot-scope="{ selectedItem }">
      <div class="form-group valid required col-md-12 field-EnyoSelect" v-if="selectedItem">
        <label for="applabelsabsencereason"
          ><span>{{ $t('app.labels.project') }}</span></label
        >
        <EnyoSelect
          v-model="selectedItem.projectId"
          url="/project"
          :options="[]"
          label="label"
          track-by="_id"
          :multiple="false"
        ></EnyoSelect>
      </div>
      <VueFormGenerator
        ref="form"
        :schema.sync="formSchema"
        :model="selectedItem"
        :options="formOptions"
      ></VueFormGenerator>
    </template>
    <template slot="edit-form" slot-scope="{ selectedItem }">
      <div class="form-group valid required col-md-12 field-EnyoSelect" v-if="selectedItem">
        <label for="applabelsabsencereason"
          ><span>{{ $t('app.labels.project') }}</span></label
        >
        <EnyoSelect
          v-model="selectedItem.projectId"
          url="/project"
          :options="[]"
          label="label"
          track-by="_id"
          :multiple="false"
        ></EnyoSelect>
      </div>
      <VueFormGenerator
        ref="form"
        :schema.sync="formSchema"
        :model="selectedItem"
        :options="formOptions"
      ></VueFormGenerator>
    </template>
  </crud-component>
</template>
<script>
import apiErrors from '@/mixins/api-errors';

export default {
  props: {
    name: String,
    modelName: String,
    model: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    options: {
      default: () => ({
        mode: 'local',
        actions: {
          create: true,
          edit: true,
          view: true,
          delete: true,
          import: false,
          export: false,
        },
        columns: [],
      }),
    },
  },
  components: {},
  data() {
    return {
      crudNeedsRefresh: false,
      absence: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
      },
      formSchema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Title',
            hint: '',
            model: 'title',
            styleClasses: 'col-md-12',
          },
          {
            type: 'textArea',
            inputType: 'text',
            label: 'Description',
            model: 'description',
            multi: false,
            styleClasses: 'col-md-12',
          },
          {
            type: 'dateTime',
            inputType: 'datetime',
            label: 'Date',
            fieldOptions: {
              allowInputToggle: true,
              type: 'date',
              icons: {
                time: 'fa fa-clock',
                date: 'fa fa-calendar',
                up: 'fa fa-arrow-up',
                down: 'fa fa-arrow-down',
              },
            },
            required: true,
            model: 'date',
            multi: false,
            styleClasses: 'col-md-12',
          },
          {
            type: 'checkbox',
            inputType: 'checkbox',
            label: 'Completed',
            model: 'completed',
            default: false,
            styleClasses: 'col-md-12',
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Created by',
            hint: '',
            model: 'createdBy',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'datetime',
            label: 'Created On',
            model: 'createdOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },

          {
            type: 'input',
            inputType: 'text',
            label: 'Last modified by',
            hint: '',
            model: 'lastModifiedBy',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
          {
            type: 'input',
            inputType: 'datetime',
            label: 'Last Modified On',

            model: 'lastModifiedOn',
            multi: false,
            readonly: true,
            disabled: true,
            styleClasses: 'col-md-6',
          },
        ],
      },
    };
  },
  mixins: [apiErrors],
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style></style>
