<template>
  <iframe
    class="airtable-embed"
    :src="url"
    frameborder="0"
    onmousewheel=""
    width="100%"
    height="100%"
    style="background: transparent; border: 1px solid #ccc; height: 100%"
  ></iframe>
</template>
<script>
export default {
  props: ['url'],
  components: {},
  created() {},
  mounted() {},
};
</script>
<style>
</style>
