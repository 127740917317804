import $ from 'jquery';

// vue and vue components
import Vue from 'vue';
import Vuex from 'vuex';
import VeeValidate from 'vee-validate';
import Multiselect from 'vue-multiselect';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
// import VueLoading from 'vue-loading-overlay';
import Notify from 'vue-notifyjs';
import VueFormGenerator from 'vue-form-generator/src/index';
import VueGoodTablePlugin from 'vue-good-table';

import EnyoVueComponents from 'vue-enyo-components/src/plugin';

// External libraries
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import _ from 'lodash';
import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';

import 'chartist-plugin-pointlabels';

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// / import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min';

// router setup and local elements setup
import config from './config';
import store from './stores';
import routes from './routes/routes';
import * as filters from './filters/filters';
import App from './App.vue';

// plugin setup
Vue.use(VeeValidate, {
  inject: false,
});
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(Notify, {
  type: 'primary',
  timeout: 1000,
});
// Vue.use(VueLoading);
Vue.use(VueI18n);

Vue.use(VueFormGenerator, {
  fields: _.values(VueFormGenerator.fieldsLoader),
});
Vue.use(VueGoodTablePlugin);

Vue.use(require('vue-chartist'));

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// Vue.use(Notifications)

Vue.component('multiselect', Multiselect);
Vue.component('vue-form-generator', VueFormGenerator, []);

// Vue.component('v-select', vSelect);
//
// Vue.use(Notify, {type: 'primary', timeout: 2000})

// global variables
window.App = {
  locale: config.defaultLocale || 'fr',
};
window.$ = $;
window.jQuery = $;
window._ = _;
window.moment = moment;

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;

Vue.prototype.$http = axios.create({
  baseURL: config.apiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(`${config.appName}_token`)}`,
  },
});

Vue.prototype.$http.interceptors.response.use(response => response, (error) => {
  if (error && error.response && error.response.status === 401) {
    /*
        swal({
          title: "Session Expired",
          text: "Your session has expired. Would you like to be redirected to the login page?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          closeOnConfirm: false
        }, function(){
          window.location = '/login';
        });
        */

    if (
      error.response
      && error.response.config
      && error.response.config.url
      && (error.response.config.url.indexOf('/login') === -1
        || error.response.config.url.indexOf('/register') === -1)) {
      store.dispatch('logout');
      // eslint-disable-next-line
      v.$router.push('/login');
      // v.$notify("Your session has expired");
    }
  }
  return Promise.reject(error);
});

Vue.use(EnyoVueComponents, {
  CrudComponent: { props: { primaryKey: { type: String, default: '_id' } } },
  EnyoAjaxTable: { props: { primaryKey: { type: String, default: '_id' } } },
});

// configure router
const router = new VueRouter({
  base: '/',
  mode: window.cordova ? 'hash' : 'history',
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
});

router.beforeEach((to, from, next) => {
  document.title = `${config.defaultTitle} - ${to.meta.title || to.name}`;
  next();
});

const i18n = new VueI18n({
  locale: store.state.locale, // set locale
  messages: store.state.translations || {}, // set locale messages
});

/* eslint-disable no-new */
let v;
store.commit('extendedRoles');
// initVue();

const io = sailsIOClient(socketIOClient);

io.sails.url = config.apiUrl.replace('/api', '');
io.sails.transports = ['websocket',
  'htmlfile',
  'xhr-polling',
  'jsonp-polling',
  'polling',
];

io.sails.headers = {
  authorization: `Bearer ${store.state.token}`,
};

io.socket.get('/api/users', {
  token: store.state.token,
  access_token: store.state.token,
}, (body, JWR) => {
  // body === JWR.body
  // console.log('Sails:: responded with: ', body);
  // console.log('Sails:: with headers: ', JWR.headers);
  // console.log('Sails:: and with status code: ', JWR.statusCode);
});

store.commit('io', io);

function initVue() {
  v = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store,
    i18n,
  });
  window.App.vue = v;

  // REFRESH DATA
  if (localStorage.getItem(`${config.appName}_token`)) {
    v.$store
      .dispatch('refreshListOfValues');
    v.$store
      .dispatch('refreshUser')
      .then((res) => {
        v.$store.commit('currentLocale', res.data.user.locale || config.defaultLocale);
        if (v.$route.name === 'Login' && v.$store.state.user && v.$store.state.user._id) {
          setTimeout(() => {
            v.$router.push('/');
          }, 300);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

store
  .dispatch('getModels')
  .then(initVue)
  .catch(initVue);
