module.exports = {
  appName: 'Enyo intranet',
  appKey: 'enyo_intranet',
  env: 'development', // production / test
  defaultLocale: 'fr',
  /* eslint-disable */
  apiUrl: '/api',
  buildDate: new Date(),
  version: '',
  isCordova: false,
  defaultTitle: 'Enyosolutions admin dashboard'
}
