<template>
  <div class="sidebar" :style="sidebarStyle" :data-color="backgroundColor" :data-image="backgroundImage">
    <div class="sidebar-wrapper">
      <div class="logo">
        <router-link :to="{ path: '/' }" class="simple-text">
          <div class="logo-img">
            <img src="/static/img/enyo-logo.png" alt="" />
          </div>
          {{ title }}
        </router-link>
      </div>
      <slot name="content"></slot>
      <ul class="nav">
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            @click="closeNavbar"
            :link="link"
          >
            <i :class="link.icon"></i>
            <p>{{ link.name }}</p>
          </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import config from '@/config';
import SidebarLink from './SidebarLink.vue';

export default {
  components: {
    SidebarLink,
  },
  props: {
    title: {
      type: String,
      default: config.appName,
    },
    backgroundColor: {
      type: String,
      default: 'orange',
      validator: (value) => {
        const acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: '/static/img/sidebar-4.jpg',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = ['primary', 'info', 'success', 'warning', 'danger'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
};
</script>
<style>
</style>
