<template>
  <div class="form-group">
    <slot name="label">
      <label v-if="label" class="control-label">{{ label }}</label>
    </slot>
    <div :class="{ 'input-group': hasAddon }">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-addon">
          <i :class="addonLeftIcon"></i>
        </span>
        <div class="input-group-append" v-if="hasAddonButton('left')">
          <button
            :class="addonLeftButtonClass || 'btn btn-info'"
            type="button"
            @click="$emit('leftClicked')"
            v-tooltip:top="addonLeftButtonTitle || ''"
          >
            <i :class="addonLeftButtonIcon" v-if="addonLeftButtonIcon"></i>
            {{ addonLeftButtonLabel }}
          </button>
        </div>
      </slot>
      <input
        :value="value"
        @input="$emit('input', $event.target.value)"
        v-bind="$attrs"
        class="form-control"
        aria-describedby="addon-right addon-left"
      />
      <slot name="addonRight">
        <div class="input-group-append" v-if="hasAddonButton('right')">
          <button
            :class="addonRightButtonClass || 'btn btn-info'"
            type="button"
            @click="$emit('rightClicked')"
            v-tooltip:top="addonRightButtonTitle || ''"
          >
            <i :class="addonRightButtonIcon" v-if="addonRightButtonIcon"></i>
            {{ addonRightButtonLabel }}
          </button>
        </div>
        <span v-if="addonRightIcon" class="input-group-addon">
          <i :class="addonRightIcon"></i>
        </span>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'fg-input',
  props: {
    label: String,
    value: [String, Number],
    addonRightIcon: String,
    addonLeftIcon: String,
    addonRightButton: Boolean,
    addonRightButtonLabel: String,
    addonRightButtonClass: String,
    addonRightButtonIcon: String,
    addonRightButtonTitle: String,
    addonLeftButton: Boolean,
    addonLeftButtonLabel: String,
    addonLeftButtonClass: String,
    addonLeftButtonIcon: String,
    addonLeftButtonTitle: String,
  },
  computed: {
    hasAddon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined
        || addonLeft !== undefined
        || this.addonRightIcon !== undefined
        || this.addonLeftIcon !== undefined
        || this.hasAddonButton('right')
        || this.hasAddonButton('left')
      );
    },
  },
  methods: {
    hasAddonButton(loc) {
      let exist = false;
      switch (loc) {
        case 'right':
          if (this.addonRightButton === true || this.addonRightButton === 'true') {
            exist = true;
          }
          break;

        case 'left':
          if (this.addonLeftButton === true || this.addonLeftButton === 'true') {
            exist = true;
          }
          break;

        default:
          break;
      }
      return exist;
    },
  },
};
</script>
<style>
</style>
